import { canNavigate } from '@/plugins/acl/routeProtection'
// eslint-disable-next-line import/no-cycle
import { i18n, loadLanguageAsync } from '@/plugins/i18n'
import Vue from 'vue'
import VueRouter from 'vue-router'
import apps from './apps'
import dashboard from './dashboard'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    redirect: '/manage-events/:lang?',
    meta: {
      redirectIfNotLoggedIn: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login/:lang?',
    name: 'auth-login',
    component: () => import('@/views/UserViews/Authentication/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/ott',
    name: 'ott',
    component: () => import('@/views/UserViews/Ott.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      hideWhatsappWidget: true,
    },
  },
  {
    path: '/register/:lang?',
    name: 'auth-register',
    component: () => import('@/views/UserViews/Authentication/Register.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/create-event/:step?',
    name: 'new-event',
    component: () => import('@/views/UserViews/CreateNewEvent.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfNotLoggedIn: true,
    },
  },
  {
    path: '/invite/:id',
    name: 'guest-invite',
    component: () => import('@/views/GuestViews/GuestInvitation.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      hideWhatsappWidget: true,
    },
  },
  {
    path: '/navigate/:guestID',
    name: 'guest-navigation',
    component: () => import('@/views/GuestViews/Navigation.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      hideWhatsappWidget: true,
    },
  },
  {
    path: '/send-verification-email',
    name: 'send-verification-email',
    component: () => import('@/views/UserViews/Authentication/SendEmailVerification.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/verify-email/:token',
    name: 'verify-email',
    component: () => import('@/views/UserViews/Authentication/EmailVerification.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: false,
      hideWhatsappWidget: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/UserViews/Authentication/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: () => import('@/views/UserViews/Authentication/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      hideWhatsappWidget: true,
    },
  },
  ...dashboard,
  ...apps,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from) {
    if (from && to && from.path === to.path && (to.hash || from.hash)) {
      return {
        x: window.scrollX,
        y: window.scrollY,
      }
    }

    return { x: 0, y: 0 }
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  const language = to.params.lang || i18n.locale
  loadLanguageAsync(language)
  next()

  const userData = localStorage.getItem('userData')
  const tourGuideActive = localStorage.getItem('tourGuideShowValue') === 'true'

  if (tourGuideActive) {
    next(false)

    return
  }

  const isLoggedIn = userData && localStorage.getItem('accessToken') && localStorage.getItem('userAbility')

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login', params: { lang: i18n.locale } })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  if (to.meta.redirectIfNotLoggedIn && !isLoggedIn) {
    next({ name: 'auth-register', params: { lang: i18n.locale } })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next({ name: 'manage-events', params: { lang: i18n.locale } })
  }

  return next()
})

export default router
