const apps = [
  {
    path: '/manage-events/:lang?',
    name: 'manage-events',
    component: () => import('@/views/UserViews/ManageEvents.vue'),
    meta: {
      layout: 'manage-events',
    },
  },
  {
    path: '/userSettings',
    name: 'user-settings',
    component: () => import('@/views/UserViews/UserSettings.vue'),
    meta: {
      layout: 'manage-events',
    },
  },

  {
    path: '/FAQS-contact-us',
    name: 'faq-and-contact-us',
    component: () => import('@/views/EventViews/FAQAndContact.vue'),
    meta: {
      layout: 'manage-events',
    },
  },
  {
    path: '/pricing/:id',
    name: 'pricing-page',
    component: () => import('@/views/EventViews/PricingPage.vue'),
    meta: {
      layout: 'blank',
    },
  },
]

export default apps
