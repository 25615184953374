<template>
  <layout-content-vertical-nav :nav-menu-items="[]">
    <slot></slot>

    <!-- Slot: Navbar -->
    <template #navbar>
      <div
        class="navbar-content-container"
      >
        <div
          class="d-flex align-center"
        >
          <router-link
            dir="ltr"
            :to="{name: 'manage-events'}"
            class="d-flex"
          >
            <v-img
              :src="appLogo"
              alt="logo"
              contain
              eager
              :style="$vuetify.breakpoint.mdAndDown ? 'max-height:30px; max-width:30px':'max-height:35px; max-width:35px'"
            ></v-img>
            <v-img
              :src="appName"
              alt="name"
              contain
              eager
              class="mt-2"
              :style="$vuetify.breakpoint.mdAndDown ? 'max-height:30px; max-width:100px':'max-height:40px; max-width:120px;'"
            ></v-img>
          </router-link>
        </div>
        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center right-row">
          <div :class="$vuetify.breakpoint.smAndUp ? 'mx-2': 'mx-0'">
            <app-bar-i18n></app-bar-i18n>
          </div>
          <user-notifications></user-notifications>
          <app-bar-user></app-bar-user>
        </div>
      </div>
    </template>
  </layout-content-vertical-nav>
</template>

<script>
import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'

// App Bar Components
import AppBarUser from '@/views/UserViews/AppBarUser.vue'
import UserNotifications from '@/views/UserViews/UserNotifications.vue'
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import { mdiHeartOutline, mdiMenu } from '@mdi/js'
import themeConfig from '@themeConfig'

// Search Data

export default {
  components: {
    LayoutContentVerticalNav,

    // App Bar Components
    AppBarI18n,
    AppBarUser,
    UserNotifications,
  },
  setup() {
    return {
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      icons: {
        mdiMenu,
        mdiHeartOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}
</style>
