<template>
  <div>
    <div
      v-if="!shouldHideWidget"
      :id="containerId"
      class="whatsapp-widget-container"
    ></div>
  </div>
</template>

<script>
import { useRouter } from '@core/utils'
import { computed, onMounted, watch } from '@vue/composition-api'

export default {
  name: 'WhatsAppWidgetWrapper',
  props: {
    locale: {
      type: String,
      default: 'en',
      validator: value => ['en', 'he', 'ar', 'ru'].includes(value),
    },
  },
  setup(props) {
    const containerId = 'whatsapp-widget-container'
    const { route } = useRouter()

    const shouldHideWidget = computed(() => route.value.meta.hideWhatsappWidget === true)

    const loadScript = () =>
      new Promise((resolve, reject) => {
        if (document.getElementById('whatsapp-widget-script')) {
          resolve()

          return
        }
        const script = document.createElement('script')
        script.id = 'whatsapp-widget-script'
        script.src = 'https://www.anita-lee.com/whatsapp-widget.js'
        script.async = true
        script.onload = () => resolve()
        script.onerror = () => reject(new Error('Script load error'))
        document.head.appendChild(script)
      })

    const renderWidget = locale => {
      if (window.WhatsAppWidget && typeof window.WhatsAppWidget.default === 'function') {
        window.WhatsAppWidget.default({
          locale,
          containerId,
        })
      } else {
        console.error('WhatsAppWidget is not available on the window object.')
      }
    }

    const removeWidget = () => {
      const container = document.getElementById(containerId)
      if (container) container.innerHTML = '' // Clear widget content
    }

    onMounted(() => {
      if (!shouldHideWidget.value) {
        loadScript()
          .then(() => renderWidget(props.locale))
          .catch(error => console.error('Failed to load WhatsApp widget script:', error))
      }
    })

    watch(
      () => props.locale,
      newLocale => {
        if (!shouldHideWidget.value) {
          renderWidget(newLocale)
        }
      },
    )

    watch(
      () => route.value.meta.hideWhatsappWidget,
      newValue => {
        if (newValue) {
          removeWidget()
        } else {
          renderWidget(props.locale)
        }
      },
    )

    return {
      containerId,
      shouldHideWidget,
    }
  },
}
</script>

<style>
.whatsapp-widget-container ._2qp0Z {
  z-index: 6 !important;
}
.whatsapp-widget-container ._2qp0Z {
  bottom: 15px;
  right: 15px;
}
</style>
