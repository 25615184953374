export default {
  terms_title: 'Privacy Policy',
  introduction: [
    {
      content: `
            <p>            
              Welcome to the Anita-Lee website <strong>("the Website")</strong>, which offers services <strong>("the Services")</strong> for events management. These services include a system for managing guests, handling RSVP's, and managing event expenses. Any use of the Website constitutes an agreement to these Terms and Conditions and to this Privacy Policy, under the conditions and limitations contained herein. The use of the Website is subject to the most recent version of this Privacy Policy, and indicates your acceptance of the Privacy Policy, as well as any changes to it
              </p>
            `,
    },
    {
      content: `
            <p>
            The terminology in this Privacy Policy is written in masculine language for convenience only, and is intended to refer to all genders equally.
            </p>`,
    },
  ],
  sections: [
    {
      title: `
            <p>
                <strong>
                    1.<u>General</u>
                </strong>
            </p>`,

      content: `
            <p class="content-item">
                <span class="number">
                    1.1. 
                </span>
                <span>
                The Website management respects the privacy of all users.
                </span>
            </p>
            <p class="content-item">
                <span class="number">
                      1.2. 
                </span>
                <span>
                    The purpose of this Privacy Policy is to provide details about the information collected about users due to the use of the Services, and also to outline how the Website management uses the information provided to and collected by it as a result of the use of its Services.
                </span>
            </p>
            <p class="content-item">
                <span class="number">
                      1.3. 
                </span>
                <span>
                    The conditions of this Privacy Policy as detailed herein are an inseparable part of the Website's Terms and Conditions. The Website management has the right to amend this Privacy Policy and the Terms and Conditions, either together or separately, at any time. Therefore, users should stay updated with this document in its most recent version on the Website.
                </span>
            </p>
            <p class="content-item">
                <span class="number">
                      1.4. 
                </span>
                <span>
                    The Website Services are intended for users who are 18 years old and above.
                </span>
            `,
    },
    {
      title: `
            <p>
                <strong>
                    2.<u>Information Collection</u>
                </strong>
            </p>`,
      content: `
              <p class="content-item">
                  <span class="number">
                          2.1. 
                  </span>
                  <span>
                    The Services offered by the Website require registration, which involves the provision of personal information, such as full name and email address. The information provision at the time of registration may also be completed via third parties, such as Google login.
                  </span>
              </p>
              <p class="content-item">
                  <span class="number">
                          2.2. 
                  </span>
                  <span>
                    Additional information about the users may be collected and stored during the use of the Website's Services.
                  </span>
              </p>
              <p class="content-item">
                  <span class="number">
                      2.2.1
                  </span>
                  <span>
                    Your invitees' telephone numbers and full name. 
                  </span>
              </p>
              <p class="content-item">
                  <span class="number">
                      2.2.2
                  </span>
                  <span>
                    Your event details, such as event date, event time, event location, etc.
                  </span>
              </p>
              <p class="content-item">
                  <span class="number">
                      2.2.3
                  </span>
                  <span>
                    Various communications between you and the Website management.
                  </span>
              </p>
          <p class="content-item">
              <span class="number">
                  2.2.4
              </span>
              <span>
                All information related to the use of the Website's Services, as required for their operation and as per your request, including use of external services.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  2.2.5
              </span>
              <span>
                Information about actions that were not completed within the Service (for example, clicking on an event creation button or interrupting a completion process before it's finished, clicking on various buttons, etc.).
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  2.2.6
              </span>
              <span>
                Information about your end device, such as IP address, type of operating system, time zone, and device location.
              </span>
          </p>
  
          <p class="content-item">
              <span class="number">
                  2.3. 
              </span>
              <span>
                It is clarified that you are not legally obliged to provide all the aforementioned information details, and their provision depends solely on your will and free consent. Without providing certain details (for example, without entering identifying details for registration, etc.) we will not be able to operate and provide some or all of the offered Services.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  2.4. 
              </span>
              <span>
                All information provided or collected about you for the provision of the Services, in whole or in part, may be stored in the registered databases of the Website management and will be maintained by it or on its behalf. The use of information will be in accordance with this Policy.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  2.5. 
              </span>
              <span>
                It should be noted that the Website management does not distribute this information to third parties, but uses it for the purpose of providing services, including through third-party providers.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  2.6. 
              </span>
              <span>
                Accurate, reliable and precise information should be provided.
              </span>
          </p>
         `,
    },
    {
      title: `
                <p>
                <strong>
                    "Cookies"
                </strong>
            </p>`,

      content: `
          <p class="content-item">
              <span class="number">
                      2.7. 
              </span>
              <span>
                "Cookies" are digital tools that allow the collection and analysis of information while using a browser. The website administration or third parties operating the cookies can periodically access the accumulated information in the cookies, and it will be transferred to their servers. Deleting cookies from the browser will not delete information that has already been transferred to the website or to third parties via the cookies.
              </span>
          </p>
              <p class="content-item">
              <span class="number">
                      2.8. 
              </span>
              <span>
                Cookies contain and collect various information such as the duration of your stay on the site, where you came to the site or the page from, your IP address, your device characteristics, information you request to see when entering the site, etc.
              </span>
          </p>
          <p class="content-item">
          <span class="number">
                  2.9. 
          </span>
          <span>
            We use cookies for the smooth and proper operation of the services, to improve the browsing experience by adapting the services to your personal preferences, and for statistical and research purposes.
          </span>
          </p>
          <p class="content-item">
          <span class="number">
              2.10. 
          </span>
          <span>
            The site management may also use third-party cookies, such as Google's cookies when using Google Analytics, and Microsoft's cookies when using Clarity, which help us tailor your browsing experience and/or proper use of the site, and derive statistical insights about the overall use of the services. In addition, we use various Facebook analytics tools as a measurement tool for the characteristics of the use of the site, a measurement tool for the effectiveness of advertising campaigns.
          </span>
          </p>
            `,
    },
    {
      title: `
              <p>
                  <strong>
                      3.<u>Use of Google APIs</u> 
                  </strong>
              </p>`,
      content: `
              <p class="content-item">
              <span class="number">
              3.1. 
          </span>
                  <span>  
                  Anita lee use and transfer to any other app of information received from Google APIs will adhere to 
                  <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"> Google API Services User Data Policy</a>             
                  , including the Limited Use requirements.
                  </span>
              </p>
                    `,
    },
    {
      title: `
            <p>
                <strong>
                    4.<u>Access to Information</u>
                </strong>
            </p>`,
      content: `
            <p class="content-item">
                <span class="number">
                    4.1. 
                </span>
                <span>
                    Some of the services we provide include access to certain data for the purpose of performing certain actions as part of the services. These permissions include, but are not limited to:
                </span>
            </p>
            <p class="content-item">
            <span class="number">
                4.1.1. 
            </span>
            <span>
                Access to the Google contacts list – to enable the import of invitees to the system. The information saved for this purpose is the contact's name and phone number.
            </span>
            </p>
            <p class="content-item">
            <span class="number">
                4.1.2. 
            </span>
            <span>
                Registration and/or login via Google account – to enable quick registration and/or login to the system
            </span>
            </p>
            <p class="content-item">
            <span class="number">
                4.1.3. 
            </span>
            <span>
                Access to the photo gallery - for the purpose of uploading an event invitation photo, at your request.
            </span>
            </p>
            <p class="content-item">
                <span class="number">
                    4.2. 
                </span>
                <span>
                    It should be noted that the site management does not distribute this information to third parties, but uses it to provide services.
                </span>
            </p>
            
            `,
    },
    {
      title: `
            <p>
                <strong>
                    5.<u>Use of Information Received or Collected from Users</u>
                </strong>
            </p>`,
      content: `
            <p class="content-item">
                <span class="number">
                    5.1. 
                </span>
                <span>
                    We use the information received from you to provide you with the services, including RSVPs, displaying information about your invitees, displaying information about your event expenses, displaying statistical data about your event, etc.
                </span>
            </p>
            <p class="content-item">
                <span class="number">
                    5.2. 
                </span>
                <span>
                    Additionally, the information will be used to improve, correct, or modify the services and content offered as part of our services, to streamline the services offered by the site management.
                </span>
            </p>
            <p class="content-item">
            <span class="number">
                5.3. 
            </span>
            <span>
                The site management may use, process, label, and enrich the above information, including through external service and information providers, for management purposes, improving, adapting promotional and marketing content of the site services.
            </span>
            </p>
            <p class="content-item">
              <span class="number">
                  5.4. 
              </span>
              <span>
                As part of the services, various links to external websites and/or applications may appear. Clicking on the link will be at your discretion and at your responsibility alone, as you will be subject to its terms, including its privacy policy. From that point on, the site management will have no control over what is done with your information, and therefore will not be responsible for it.
              </span>
            </p>
            <p class="content-item">
              <span class="number">
                  5.5. 
              </span>
              <span>
                The site management will avoid as much as possible from disclosing the user's personal details to third parties, but the user hereby agrees to the site management to disclose his details and any other information, as it will be required to do so by any law.
              </span>
            </p>
            <p class="content-item">
              <span class="number">
                  5.6. 
              </span>
              <span>
                The user's account and its details, including the details of the events linked to the account, will be kept in the database for a period of half a year from the date of the event, and generally and no use will be made of the account, the site management reserves the right to reset the account and the event details that were in it, and they will be immediately deleted.
              </span>
            </p>
              `,
    },
    {
      title: `
            <p>
                <strong>
                    6.<u>Sharing Information with Third Parties</u>
                </strong>
            </p>`,
      content: `
            <p class="content-item">
                <span class="number">
                    6.1. 
                </span>
                <span>
                    Certain actions performed in the context of the services may require the transfer of information to third parties who implement the action, such as acquirers and entities involved in the clearing, credit card companies, and payment services. Although the site management does not store payment details, it may transfer information such as the payment amount for the package selected by the user to carry out a payment action, inquire about a payment action, issue an invoice, or to comply with the law.
                </span>
            </p>
            <p class="content-item">
                <span class="number">
                    6.2. 
                </span>
                <span>
                    To communication providers for the purpose of providing services such as sending SMS/WhatsApp and automatic voice calls to your event invitees.
                </span>
            </p>
            <p class="content-item">
                <span class="number">
                    6.3. 
                </span>
                <span>
                    To remove any doubt, the site does not sell or transfer the user's details to external bodies. However, the site uses the information that the user enters in the system and transfers it to third-party companies in order to provide the user with the services.
                </span>
            </p>
                `,
    },

    {
      title: `
            <p>
                <strong>
                    7.<u>Information Security</u> 
                </strong>
            </p>`,
      content: `
            <p class="content-item">
                <span class="number">
                    7.1. 
                </span>
                <span>
                    The site management makes noticeable efforts to protect the confidentiality of the data provided by the site users, while taking standard precautions. However, the site management cannot ensure that there will be no unauthorized use or disruption of the services provided.
                </span>
            </p>
            <p class="content-item">
                <span class="number">
                    7.2. 
                </span>
                <span>
                    The site management will not be responsible for the loss of information on the site as a result of force majeure, a national or global internet network crash and/or an unexpected malfunction in the company's servers.
                </span>
            </p>
          
                `,
    },
    {
      title: `
            <p>
                <strong>
                    8.<u>Contacting About Privacy</u> 
                </strong>
            </p>`,
      content: `
            <p class="content-item">
                <span>
                    For any question and/or clarification regarding the privacy policy, you can contact the site management at the email address: support@anita-lee.com
                </span>
            </p>
                  `,
    },
  ],
}
