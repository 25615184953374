import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'

import Vue from 'vue'

// import { EmojiPicker } from 'vue-emoji-picker'

import '@mdi/font/css/materialdesignicons.css'

import PreloadedImage from '@/components/PreloadedImage.vue'
import VueGtag from 'vue-gtag'
import JsonExcel from 'vue-json-excel'
import IphonePreview from 'vue-push-notification-preview'
import SlimCropper from 'vue-slim-cropper'
import Toast from 'vue-toastification'
import VueTour from 'vue-tour'
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete'
import App from './App.vue'
import './plugins/acl'
import imagePreloaderPlugin from './plugins/imagePreloaderPlugin'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)

Vue.use(VueGtag, {
    config: {
        id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
    },
})

Vue.use(Toast)
Vue.config.productionTip = false
Vue.component('IphonePreview', IphonePreview)
Vue.component('downloadExcel', JsonExcel)
Vue.component('PreloadedImage', PreloadedImage)

// Vue.use(EmojiPicker)
Vue.use(SlimCropper)

Vue.use(VuetifyGoogleAutocomplete, {
    apiKey: process.env.VUE_APP_GOOGLE_API_KEY, // Can also be an object. E.g, for Google Maps Premium API, pass `{ client: <YOUR-CLIENT-ID> }`
    installComponents: true, // Optional (default: true) - false, if you want to locally install components
    vueGoogleMapsCompatibility: false, // Optional (default: false) - true, requires vue2-google-maps to be configured see https://github.com/xkjyeah/vue-google-maps
})
const AddToCalendar = require('vue-add-to-calendar')

Vue.use(AddToCalendar)
Vue.use(imagePreloaderPlugin)

new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App),
}).$mount('#app')