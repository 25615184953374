<template>
  <v-menu
    offset-y
    nudge-bottom="12"
    min-width="175"
    left
    :elevation="$vuetify.theme.dark ? 9 : 8"
    style="z-index: 999;"
  >
    <!-- Activator -->
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        class="d-flex align-center"
        v-on="on"
      >
        <v-img
          :src="require(`@/assets/images/flags/${$i18n.locale}.png`)"
          :alt="$i18n.locale"
          height="14px"
          width="22px"
          class="me-2"
        ></v-img>
        <span v-if="$vuetify.breakpoint.smAndUp">{{ locales.find(l => l.locale === $i18n.locale).title }}</span>
      </div>
    </template>

    <!-- Options List -->
    <v-list>
      <v-list-item-group
        :value="$i18n.locale"
        @change="updateActiveLocale"
      >
        <v-list-item
          v-for="locale in locales"
          :key="locale.locale"
          :value="locale.locale"
        >
          <v-img
            :src="locale.img"
            height="14px"
            width="22px"
            :alt="locale.locale"
            class="me-2"
          ></v-img>
          <v-list-item-title>{{ locale.title }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { loadLanguageAsync } from '@/plugins/i18n'
import router from '@/router'
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'

export default {
  setup() {
    const { isRtl } = useAppConfig()
    const { route } = useRouter()

    const locales = [
      {
        title: 'עברית',
        img: require('@/assets/images/flags/he.png'),
        locale: 'he',
      },
      {
        title: 'English',
        img: require('@/assets/images/flags/en.png'),
        locale: 'en',
      },
      {
        title: 'Russia',
        img: require('@/assets/images/flags/ru.png'),
        locale: 'ru',
      },
      {
        title: 'Arabic',
        img: require('@/assets/images/flags/ar.png'),
        locale: 'ar',
      },
    ]

    const updateActiveLocale = locale => {
      const currentPath = route.value.path

      if (!locale) {
        return
      }

      // Set to RTL mode if locale is arabic or hebrew
      isRtl.value = locale === 'ar' || locale === 'he'

      if (currentPath.includes('/login')) {
        // The user is on the login page
        router.push({ path: `/login/${locale}` })
      }
      if (currentPath.includes('/register')) {
        // The user is on the login page
        router.push({ path: `/register/${locale}` })
      } else if (currentPath.includes('/manage-events')) {
        // The user is on the manage events page
        router.push({ path: `/manage-events/${locale}` })
      }

      loadLanguageAsync(locale)
    }

    return {
      locales,
      updateActiveLocale,
    }
  },
}
</script>
