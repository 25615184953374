<template>
  <component
    :is="resolveLayoutVariant"
    :class="`skin-variant--${appSkinVariant}`"
  >
    <transition
      :name="appRouteTransition"
      mode="out-in"
      appear
    >
      <div>
        <router-view></router-view>
      </div>
    </transition>
    <WhatsAppWidgetWrapper :locale="currentLocale" />
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, onMounted, onUnmounted, watch } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useLayout } from '@core/layouts/composable/useLayout'
import { useRouter } from '@core/utils'

// Layouts
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import ManageEventNav from '@/layouts/variants/manage-events/ManageEventNav.vue'

// Dynamic vh
import WhatsAppWidgetWrapper from '@/components/WhatsAppWidgetWrapper.vue'
import useEventListeners from '@/composables/useEventListeners'
import { i18n } from '@/plugins/i18n/index'
import store from '@/store'
import axios from '@axios'
import useDynamicVh from '@core/utils/useDynamicVh'
import { provideToast } from 'vue-toastification/composition'
import 'vue-toastification/dist/index.css'
import { initializePopupStore } from './store/popupStore'

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutBlank,
    ManageEventNav,
    WhatsAppWidgetWrapper,
  },
  setup() {
    const { addListener, removeAllListenersForEvent } = useEventListeners()
    const currentLocale = computed(() => i18n.locale)
    provideToast({ rtl: i18n.locale === 'ar' || i18n.locale === 'he' })
    const { route } = useRouter()
    // eslint-disable-next-line object-curly-newline
    const { appContentLayoutNav, appSkinVariant, appRouteTransition, isRtl } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()
    initializePopupStore()

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'manage-events') return 'manage-event-nav'
      if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

      return null
    })

    useDynamicVh()

    watch(
      () => i18n.locale,
      newLocale => {
        isRtl.value = newLocale === 'ar' || newLocale === 'he'
      },
      { immediate: true },
    )

    onMounted(() => {
      if (!window.ReactNativeWebView) {
        return
      }

      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'REQUEST_PLATFORM' }))

      const isInWebView = !!window.ReactNativeWebView
      store.commit('app/SET_IS_IN_WEBVIEW', isInWebView)

      addListener('PlatformDetected', event => {
        window.ReactNativePlatform = event.detail.platform
        store.commit('app/SET_PLATFORM', event.detail)
      })

      addListener('NOTIFICATION_TOKEN_RESPONSE', async event => {
        const data = event.detail
        await axios.patch('push-notifications/token', { pushNotificationToken: data.notificationToken })
      })
    })

    onUnmounted(() => {
      removeAllListenersForEvent('PlatformDetected')
      removeAllListenersForEvent('NOTIFICATION_TOKEN_RESPONSE')
    })

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
      currentLocale,
    }
  },
}
</script>
