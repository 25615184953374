import { mdiCheck, mdiHeart, mdiMapMarker, mdiPhone } from '@mdi/js'

export default function useGuestMessages() {
  const guestMessagesOptions = {
    INVITATION: 1,
    REMINDER: 2,
    THANKS: 3,
    CALL: 4,
  }
  const messagesGuestsTypeMapping = [
    {
      value: guestMessagesOptions.INVITATION,
      title: 'invitationMessage',
      icon: mdiCheck,
      color: 'success',
    },
    {
      value: guestMessagesOptions.REMINDER,
      title: 'DirectionMessage',
      icon: mdiMapMarker,
      color: 'blue',
    },
    {
      value: guestMessagesOptions.THANKS,
      title: 'ThanksMsg',
      icon: mdiHeart,
      color: 'red',
    },
    {
      value: guestMessagesOptions.CALL,
      title: 'call',
      icon: mdiPhone,
      color: 'teal',
    },
  ]

  return {
    guestMessagesOptions,
    messagesGuestsTypeMapping,
  }
}
