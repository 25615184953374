<template>
  <div
    v-if="!ifPaid && !$store.state.tourGuideShow.value && !$store.getters['app/getIsInWebView']"
    class="app-customizer"
    :style="$vuetify.breakpoint.mdAndDown ? 'display: none;': 'z-index: 999'"
  >
    <div
      class="buy-btn"
    >
      <v-btn
        v-if="$store.state.eventData.id"
        color="primary"
        class="font-weight-bold nav-link"
        rounded
        :to="{name: 'pricing-page', params: { id: $store.state.eventData.id }}"
      >
        <span>
          {{ $t('BuyPackage') }}
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const ifPaid = ref()
    const { route } = useRouter()

    const getPaidDetails = () => {
      store.dispatch('getPackages', { id: route.value.params.id }).then(res => {
        if (res.data.packageAmount > 0) {
          ifPaid.value = true
        } else {
          ifPaid.value = false
        }
      })
    }
    getPaidDetails()

    return {
      ifPaid,

      // Icons
      icons: {
        mdiClose,
      },
    }
  },
}
</script>

  <style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.v-application--is-ltr .app-customizer {
  right: 2%;
}

.app-customizer {
  position: fixed;
  inset-block-end: 7px;
  inset-inline-end: auto;

  .v-label {
    font-size: 0.875rem;
  }

  .app-customizer-header {
    position: relative;
    .icon-customizer-close {
      position: absolute;
      @include ltr() {
        right: 20px;
      }
      @include rtl() {
        left: 20px;
      }
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .buy-btn {
    border-radius: 86px;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    width: fit-content;
    margin: auto;
    margin-left: 1rem !important;
  }

  .buy-btn:hover {
    box-shadow: 0px 2px 20px 1px #9347fd;
    border-radius: 86px;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    cursor: pointer;
  }

  .buy-btn a {
    color: #fff !important;
  }
  @media (min-width: 320px) and (max-width: 1200px) {
    .menu_area #nav .nav-link {
      color: #fff;
    }
    .buy-btn {
      margin-top: 10px;
      margin-left: auto !important;
    }
  }
}
</style>
