import { ref } from '@vue/composition-api'

export default function useEventListeners() {
  const eventListeners = ref({})

  const addListener = (eventName, listener) => {
    if (!eventListeners.value[eventName]) {
      eventListeners.value[eventName] = []
    }
    document.addEventListener(eventName, listener)
    eventListeners.value[eventName].push(listener)
  }

  const removeAllListenersForEvent = eventName => {
    if (eventListeners.value[eventName]) {
      eventListeners.value[eventName].forEach(listener => {
        document.removeEventListener(eventName, listener)
      })
      eventListeners.value[eventName] = []
    }
  }

  return {
    addListener,
    removeAllListenersForEvent,
  }
}
