<template>
  <v-menu
    offset-y
    left
    nudge-bottom="22"
    :elevation="$vuetify.theme.dark ? 9 : 8"
    content-class="list-style notification-menu-content"
    style="z-index:10;"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        v-on="on"
      >
        {{ icons.mdiBellOutline }}
      </v-icon>
    </template>
    <v-card
      class="app-bar-notification-content-container"
      :style="{ width: $vuetify.breakpoint.smAndUp ? '350px' : '' }"
    >
      <perfect-scrollbar
        class="ps-user-notifications"
        :options="perfectScrollbarOptions"
      >
        <v-list
          class="py-0"
        >
          <!-- Title -->
          <v-list-item
            class="d-flex"
            link
          >
            <div class="d-flex align-center">
              <span class="font-weight-semibold">{{ $t('Notifications') }}</span>
            </div>
          </v-list-item>
          <v-divider></v-divider>

          <div v-if="notification.length === 0">
            <v-list-item
              class="align-center justify-center pt-5"
            >
              <div class="d-flex flex-column align-center justify-center">
                <v-icon
                  size="30"
                >
                  {{ icons.mdiBellOutline }}
                </v-icon>
                <p class="pt-2">
                  {{ $t('noNotifications') }}
                </p>
              </div>
            </v-list-item>
          </div>

          <!-- Notifications -->
          <div v-if="notification.length > 0">
            <template
              v-for="(notif, index) in notification"
            >
              <v-list-item
                :key="notif.index"
                link
              >
                <!-- Avatar -->
                <v-list-item-avatar
                  :color="messageSystemHandler(notif, 'color')"
                  :class="`v-avatar-light-bg ${messageSystemHandler(notif, 'color')}--text`"
                  size="35"
                  class="me-2"
                >
                  <v-icon
                    size="22"
                    :color="messageSystemHandler(notif, 'color')"
                  >
                    {{ messageSystemHandler(notif, 'icon') }}
                  </v-icon>
                </v-list-item-avatar>

                <!-- Item Action -->
                <v-list-item-content
                  class="d-block"
                >
                  <v-list-item-title
                    class="font-weight-semibold"
                    :class="$vuetify.breakpoint.mdAndDown ? 'text-sm' : 'text-base'"
                    style=" white-space: pre-line;
                    overflow-wrap: break-word;"
                  >
                    {{ notif.eventNames[$i18n.locale] || notif.eventNames[notif.defaultLang] }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    style=" white-space: pre-line;
                    overflow-wrap: break-word;"
                  >
                    <span
                      :class="$vuetify.breakpoint.smAndUp ? 'text-base' : 'text-sm'"
                    >
                      <span>
                        {{ $t('sent') }}
                      </span>
                      {{ notif.count }}
                      <span>
                        {{ $t(messageSystemHandler(notif)) }}
                      </span>
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <!-- Item Time -->
                <v-list-item-action>
                  <span class="text--secondary text-xs">{{ (moment(new Date()).diff(moment(new Date(notif.date)),'days')) > 1 ? moment(notif.date).format('DD/MM') : ((new Date().getDay() - new Date(notif.date).getDay()) === 0 ? $t('Today') : $t('Yesterday')) }}</span>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="index"></v-divider>
            </template>
          </div>
        </v-list>
      </perfect-scrollbar>
    </v-card>
  </v-menu>
</template>

<script>
import useGuestMessages from '@/composables/useGuestMessages'
import useMessagesType from '@/composables/useMessagesType'

import store from '@/store'
import { mdiBellOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import moment from 'moment'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

export default {
  components: {
    PerfectScrollbar,
  },
  setup() {
    const { messagesTypeMapping } = useMessagesType()
    const { messagesGuestsTypeMapping } = useGuestMessages()

    const notification = ref([])

    const messageSystemHandler = (item, field) => {
      // if sending type is invitation, check if it SMS or WhatsApp
      if (item.type === 1) {
        return messagesTypeMapping
          .filter(el => el.value === item.messageSystem)
          .map(el => (field === undefined ? el.title : el[field]))[0]
      }

      return messagesGuestsTypeMapping
        .filter(el => el.value === item.type)
        .map(el => (field === undefined ? el.title : el[field]))[0]
    }

    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const fetchUserNotification = () => {
      store.dispatch('fetchNotifications').then(res => {
        notification.value = res.data

        // display notification from the last action to older
        notification.value.sort((a, b) => new Date(b.statusDate) - new Date(a.statusDate))
      })
    }
    fetchUserNotification()

    return {
      perfectScrollbarOptions,
      notification,
      moment,
      fetchUserNotification,
      messagesTypeMapping,
      messageSystemHandler,
      messagesGuestsTypeMapping,

      icons: {
        mdiBellOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.app-bar-notification-content-container {
  .read-all-btn-list-item {
    padding-top: 14px;
    padding-bottom: 14px;
    min-height: unset;
  }
}

.ps-user-notifications {
  max-height: calc(var(--vh, 1vh) * 80);
}

.notification-menu-content {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-width: calc(100vw - (1.5rem * 2)) !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
}
</style>
