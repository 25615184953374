// eslint-disable-next-line object-curly-newline
import {
  mdiAccountGroupOutline,
  mdiApps,
  mdiCalculator,
  mdiCalendarClock,
  mdiEyeOutline,
  mdiMessageProcessingOutline,
  mdiPhoneOutline,
} from '@mdi/js'

export default [
  {
    subheader: 'Management',
  },
  {
    title: 'GuestsList',
    to: 'apps-user-list',
    icon: mdiApps,
  },
  {
    title: 'MessageTemplate',
    icon: mdiMessageProcessingOutline,
    to: 'apps-user-message-template',
  },

  {
    title: 'CallTemplates',
    icon: mdiPhoneOutline,
    to: 'apps-user-call-messages',
  },
  {
    title: 'actionTiming',
    icon: mdiCalendarClock,
    to: 'apps-user-manage-timing',
  },
  {
    title: 'ManageGroups',
    icon: mdiAccountGroupOutline,
    to: 'apps-user-manage-group',
  },
  {
    title: 'InvitationPreview',
    icon: mdiEyeOutline,
    to: 'invitation',
  },
  {
    title: 'eventExpenses',
    icon: mdiCalculator,
    to: 'event-expenses',
  },
]
