export default {
  terms_title: 'תקנון תנאי שימוש',
  introduction: [
    {
      content: `

        <p>
        ברוכים הבאים לאתר Anita-lee (להלן: <strong>"האתר"</strong>), אשר מציע שירותים (להלן: <strong>"השירותים"</strong>) לאירועים, לרבות מערכת לניהול מוזמנים, אישורי הגעה וניהול הוצאות האירוע. כל שימוש באתר מהווה הסכמה לתקנון (להלן: <strong>"התקנון"</strong>) זה, על התנאים וההגבלות הכלולים בו.
        </p>
        `,
    },
    {
      content: `
        <p>
            האמור בתקנון זה נכתב בלשון זכר לשם הנוחות בלבד, והינו מתייחס לכול המינים באופן שווה.
        </p>`,
    },
    {
      content: `
        <p>
            תקנון זה עלול להשתנות מעת לעת, ועל המשתמש להתעדכן בתקנון בכל כניסה לאתר. גלישה באתר ו/או שימוש בו מכל סוג, כפופה בהסכמה לתנאי התקנון והתחייבות לפעול על פיו. מובהר כי התקנון מהווה הסכם משפטי מחייב לכל דבר ועניין ומחייב את המשתמש על כל שימושיו באתר
        </p>`,
    },
    {
      content: `
        <p>
            עצם הזמנת השירותים באתר זה מהווה הצהרה חד משמעית מצד המשתמש כי קרא, הבין והסכים להוראות תקנון זה. התקנון מהווה חוזה מחייב בין המשתמש לבין הנהלת האתר.
        </p>`,
    },
  ],
  sections: [
    {
      title: `
        <p>
            <strong>
                1.<u> הגבלות שימוש </u>
            </strong>
        </p>`,

      content: `
        <p class="content-item">
            <span class="number">
                1.1. 
            </span>
            <span>
                השירותים ניתנים במדינת ישראל בלבד.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                1.2. 
            </span>
            <span>
                השימוש באתר מתאפשר למשתמש מעל גיל 18 בלבד, ואשר הינו כשיר משפטית להתקשר בהסכם זה, בכפוף להסכמה לתנאי תקנון זה.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                1.3. 
            </span>
            <span>
                המשתמש מתחייב שלא לעשות כל שימוש בשירותים על מנת להפיץ תוכן פוגעני ו/או מסית ו/או מטריד וכן כל תוכן המנוגד לדין. 
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                1.4. 
            </span>
            <span>
                המשתמש מתחייב לא לעשות שימוש באתר ובשירותי האתר למטרות שאינן אישיות.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                1.5. 
            </span>
            <span>
                הנהלת האתר רשאית למנוע ממשתמש שימוש באתר בחלק מהשירותים או בכולם, בהתאם לשיקול דעתה הבלעדי ומכל סיבה שהיא ללא חובת הנמקה החלטה זו. משתמש אשר נמנעה ממנו הגישה לאתר לא יהיה רשאי לשוב ולבצע שימוש באתר.
            </span>
        </p>
        `,
    },
    {
      title: `
        <p>
            <strong>
                2.<u> השירותים </u>
            </strong>
        </p>`,
      content: `
        <p class="content-item">
            <span class="number">
                2.1. 
            </span>
            <span>
                האתר מהווה פלטפורמה לניהול אירועים, לרבות אישורי הגעה בצורת SMS, WhatsApp ושיחות אוטומטיות. וזאת בכפוף לחבילה שהמשתמש בחר. 
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                2.2. 
            </span>
            <span>
                הנהלת האתר איננה מתחייבת כי האתר יפעל ללא תקלות או שגיאות מכל סוג. השירות באתר מסופק על פי מודל  "AS IS"  וכן מאפשר למשתמש להתנסות במערכת לפי רכישת שירותים בתשלום. הנהלת האתר לא תישא בכל אחריות, מפורשת או משתמעת על נזק ישיר ו/או עקיף.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                2.3. 
            </span>
            <span>
                ברישום לאתר ולשירות מסכים המשתמש ומתחייב לקיים את התנאים המפורטים בתקנון זה, כפי שיעודכן מעת לעת על ידי הנהלת האתר. עם הרישום למערכת, המשתמש מסכים ומתחייב כי הנהלת האתר תהיה רשאית מעת לעת לעדכן את תקנון תנאי השימוש לפי שיקולה הבלעדי, ואחריות הבלעדית של המשתמש להתעדכן בו מעת לעת.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                2.4. 
            </span>
            <span>
                השימוש באתר כפוף לתקנון זה ולתנאי השימוש בו והוא מהווה מסמך מחייב בין המשתמש לבין הנהלת האתר.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                2.5. 
            </span>
            <span>
                למען הסר ספק, השימוש באתר הוא באחריות המשתמש בלבד.
            </span>
        </p>
        `,
    },
    {
      title: `
        <p>
            <strong>
                3.<u> אחריות המשתמש </u>
            </strong>
        </p>`,
      content: `
        <p class="content-item">
            <span class="number">
                3.1. 
            </span>
            <span>
                המשתמש מתחייב שלא לבצע הפצת SMS/WhatsApp ושיחות קוליות למספרי טלפון שלא נתנו את הסכמתם לכך.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                3.2. 
            </span>
            <span>
                המשתמש מתחייב לפעול בהתאם לחוק הספאם.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                3.3. 
            </span>
            <span>
                המשתמש מתחייב לא להפיץ תכנים הקשורים לספאם.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                3.4. 
            </span>
            <span>
                המשתמש מתחייב שלא להעביר את זכות השימוש שלו באתר לכל צד שלישי וכן נושא באחריותו הבלעדית לאבטחת החשבון שלו והסיסמאות.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                3.5. 
            </span>
            <span>
                המשתמש מצהיר בזאת כי הינו מודע לנזקים אשר עשויים להיגרם אם יתקבלו תלונות ישירות או באמצעות גורם שלישי לגבי הפצת SMS/WhatsApp ו/או שיחות קוליות אוטומטיות לנמענים שלא ביקשו לקבלם באופן מפורש, וככן מתחייב המשתמש לשפות ולפצות את הנהלת האתר ומי מטעמה בגין כל תביעה ו/או דרישה אשר תשלח אליה. המשתמש מודע ומסכים לכך שביצוע אחת מפעולות אלו יגרום להפסקה חד צדדית של הפעילות מול הנהלת האתר והאתר וכן כי לא יזוכה בהחזר כלשהו עבור תשלומים ששולמו אך לא בוצעו מפאת הפרת אחד מסעיפי הסכם זה.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                3.6. 
            </span>
            <span>
                המשתמש מודע כי חלה עליו האחריות הבלעדית על הזנת נתונים והתוכן לאתר, היות והמשתמש הזין תוכן או נתונים שגויים , לא יפנה את טענותיו ו/או תלונותיו כלפי הנהלת האתר.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                3.7. 
            </span>
            <span>
                המשתמש מתחייב כי מודע לכך שתוכן ההודעות והשיחות הקוליות האוטומטיות ניתנות לעריכה וכן הן באחריות המשתמש בלבד בכפוף למגבלות שצוינו בתקנון זה, ולא יהיה לו או מי מטעמו תלונות. הנהלת האתר לא תהיה אחראית לכל נזק ישיר ו/או עקיף הקשור לשליחת תוכן ההודעות והשיחות הקוליות האוטומטיות.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                3.8. 
            </span>
            <span>
                המשתמש מודע לכך שתאריך התחלת אישורי ההגעה ניתן לעריכה, וכול עוד הוא אינו שינה את התאריך, הנהלת האתר תחל את אישורי ההגעה בתאריך הנתון לשיקול דעתה הבלעדי, ולמשתמש לא תהיה כל טענה ו/או תלונה בגין כך.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                3.9. 
            </span>
            <span>
                האחריות הבלעדית של בדיקת תקינות כלל הנתונים לרבות קישור הכוונה לאירוע הוא של המשתמש, ולא תהיה למשתמש כל טענה ו/או תלונה כלפי הנהלת האתר בגין כך.
            </span>
        </p>
        `,
    },
    {
      title: `
        <p>
            <strong>
                4.<u> קניין רוחני </u>
            </strong>
        </p>`,
      content: `
        <p class="content-item">
            <span class="number">
                4.1. 
            </span>
            <span>
                כל הזכויות באתר, לרבות זכויות הקניין הרוחני באתר, לרבות שמו, סימני מסחר, עיצוב, תוכן, וכן זכויות הקניין של השירותים והשירותים הנלווים הינם בבעלות בלעדית של האתר בלבד.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                4.2. 
            </span>
            <span>
                הנהלת האתר הינה הבעלים הבלעדיים באתר וכן במאגרי הנתונים וכן בכל תוכן אחר הכלול בו. המשתמש מתחייב כי מודע שאין להעתיקו, להפיצו, לשכפלו ו/או למסור לצד שלישי את המידע באתר, חלקים ממנו או בשלמותו. לרבות עשיית שימוש מסחרי במידע האתר, אלא בהיתר מראש ובכתב של הנהלת האתר. 
            </span>
        </p>
          `,
    },
    {
      title: `
        <p>
            <strong>
                5.<u> הפרת כללי שימוש </u>
            </strong>
        </p>`,
      content: `
        <p class="content-item">
            <span class="number">
                5.1. 
            </span>
            <span>
                כל המפר תקנון זה ישפה את הנהלת האתר, עובדיו, מנהליו, בעלי מניותיו או מי מטעמם בגין כל נזק, הפסד, אבדן רווח, הוצאות שכר טרחת עו"ד והוצאות משפט שיגרמו כתוצאה מהפרה זו.
            </span>
        </p>
            `,
    },

    {
      title: `
        <p>
            <strong>
                6.<u> שימוש באתר ובשירותיו </u> 
            </strong>
        </p>`,
      content: `
        <p class="content-item">
            <span class="number">
                6.1. 
            </span>
            <span>
                המשתמש רשאי להשתמש באתר ובשירותיו למטרות פרטיות ואישיות בלבד.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                6.2. 
            </span>
            <span>
                השימוש באתר ובשירותיו כפוף לרישום המשתמש באתר ויתאפשר למשתמשים רשומים בלבד.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                6.3. 
            </span>
            <span>
                הרישום לאתר כפוף למסירת פרטי המשתמש כגון: שם מלא, כתובת דואר אלקטרוני ובחירת סיסמה. האתר יאמת את המשתמש דרך כתובת הדואר האלקטרוני שלו.
            </span>
        </p>
        <p class="content-item">
        <span class="number">
            6.4. 
        </span>
        <span>
            השימוש באתר מהווה את הסכמנו לתקנון זה ולהסכמנו לקבל ניוזלטר ו/או חומר פרסומי מהאתר בכפוף לזכותו של המשתמש להודיע על רצונו להסירו מכל אלו בכתב אל הדואר האלקטרוני של הנהלת האתר.
        </span>
    </p>
        <p class="content-item">
            <span class="number">
                6.5. 
            </span>
            <span>
                הנהלת האתר שומרת לעצמה את הזכות להוסיף שדות זיהוי על פי שיקול דעתה הבלעדי.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                6.6. 
            </span>
            <span>
                הנהלת האתר תהיה רשאית לבטל את רישומו של המשתמש לאתר מכל סיבה, ללא כל חובת הנמקה של החלטה זו.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                6.7. 
            </span>
            <span>
                המשתמש מודע לכך שחלק מהשירותים הניתנים באתר מותנה בין היתר בקיומם של הסכמים בין הנהלת האתר ומפעילי השירות בה לבין המפעילים הסלולריים השונים. בכל מקרה שבו יסתיים ההסכם בין הנהלת האתר לבין מפעיל סלולרי כלשהו, מכל סיבה שהיא, יפסקו השירותים להינתן, ולמשתמש לא תהיה כל טענה ו/או תביעה בקשר לכך.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                6.8. 
            </span>
            <span>
                המשתמש מצהיר בזאת ומתחייב כי לא תהיה כל טענה או תביעה כנגד הנהלת האתר ו/או כנגד המפעילים הסלולריים כתוצאה מנסיבות של כוח עליון, ולרבות בגין כל שינוי שמתייחס לשינוי ברגולציה.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                6.9. 
            </span>
            <span>
                המשתמש מודע לכך שחלק מהשירותים הניתנים באתר הינם תלויים בצדדים שלישיים, ועל כן במקרה של תקלה, פגם היעדר תקינות, מחדל ו/או כל סיבה אחרת שמקורה באחד או יותר מהצדדים השלישיים, הנהלת האתר לא תהיה אחראית על כל נזק ו/או הפסד ישיר ו/או עקיף שייגרם למשתמש. 
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                6.10. 
            </span>
            <span>
                הנהלת האתר לא תישא באחריות לתוכן ו/או מידע הנוגע למידע פרסומי באתר וכן קישורים לאתרים חיצוניים צד שלישי.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                6.11. 
            </span>
            <span>
                המשתמש מודע לכך שהוא נושא באחריות הבלעדית על גיבוי הקבצים והנתונים המופיעים במערכת, ובכך פותר את הנהלת האתר מכל אחריות לנזק ישיר ו/או עקיף העלול להגרם כתוצאה מאבדן נתונים.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                6.12. 
            </span>
            <span>
            בשימוש במערכת המשתמש נותן את הסמכתו המפורשת כי הנהלת האתר תהיה רשאית לפנות אליו, מעת לעת, עם הצעות לרכישת מוצרים ושירותים שונים, מבצעים וכדומה בכל הדרכים המנויות בחוק התקשורת (בזק ושידורים), תשס"ח – 2008 , לרבות באמצעות  דואר אלקטרוני, WhatsApp ו – SMS ותהיה רשאית למסור את פרטיו גם לצד שלישי לשם ביצוע האמור. כל שאלה בעניין שימוש במידע הנאסף על ידי הנהלת האתר, לרבות דרישה להסרה ממאגר המידע או סירוב לקבל הצעות או הסרת מידע כלשהו, ניתן להפנות אל הנהלת האתר באמצעות הדואר אלקטרוני:
            support@anita-lee.com. 
            </span>
        </p>
            `,
    },
    {
      title: `
        <p>
            <strong>
                7.	<u> אחריות הנהלת האתר </u> 
            </strong>
        </p>`,
      content: `
        <p class="content-item">
            <span class="number">
                7.1. 
            </span>
            <span>
                הנהלת האתר וכל הבאים מטעמה לא יישאו בכל אחריות לכל נזק שיגרם כתוצאה מהשירותים המסופקים על ידה.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                7.2. 
            </span>
            <span>
                המשתמש מתחייב לפצות ולשפות את הנהלת האתר, עובדיה, מנהליה או מי מטעמם, ו/או את המפעילים הסלולריים, לפי העניין, בגין כל אובדן, נזק, הפסד, הוצאה או חבות שיגרמו למי מהם ושמקורם בהפרת המשתמש את תקנון שימוש זה. בנוסף, המשתמש מאשר ומסכים כי להנהלת האתר יש הזכות לדרוש פיצוי בגין כל נזקים שייגרמו לה בגין כל שימוש במערכת למטרות בלתי חוקיות, באופן בלתי חוקי, ו/או באופן שאינו עולה בקנה אחד עם תנאי הסכם זה, וכי פיצויים אלה יכול שיכללו פיצויים בשל נזק מכל סוג.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                7.3. 
            </span>
            <span>
                הנהלת האתר לא מתחייבת כי שירותיה יהיו נקיים מתקלות, כשלים והפרעות.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                7.4. 
            </span>
            <span>
                הנהלת האתר לא תהיה אחראית לכל נזק ישיר ו/או עקיף הקשור באתר ו/או הנובע משימוש אינו נכון ואינו הולם בשירותי האתר ו/או מתכנים שיופצו ע"י המשתמש באמצעות שירותי האתר ו/או מהתוכן שיפורסם באתר, וכן כל נזק ישיר ו/או עקיף הקשור בתפקוד לקוי של האתר. בהסכם זה הינך מוותר בזאת על כל טענה  ו/או תביעה כאמור כנגד הנהלת האתר.
            </span>
        </p>
              `,
    },
    {
      title: `
        <p>
            <strong>
                8.	<u> מדיניות תשלום וביטולים </u> 
            </strong>
        </p>`,
      content: `
        <p class="content-item">
            <span class="number">
                8.1. 
            </span>
            <span>
                הנהלת האתר רשאית, לפי שיקול דעתה המוחלט וללא צורך בהודעה מראש, לשנות את תנאי כל מבצע או הטבה לרבות תקופת המבצע או ההטבה לרבות לקצרה או להאריכה. הנהלת האתר שומרת לעצמה את הזכות, לפי שיקול דעתה הבלעדי, למנוע השתתפות של משתמש כלשהו במבצע או הטבה , וזאת מבלי לספק למשתמש סיבה כלשהי.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                8.2. 
            </span>
            <span>
                הנהלת האתר לא תהיה אחראית לכל נזק ו/או הפסד בנזק ישיר ו/או עקיף כלשהו שיגרם למשתמש ו/או לצד שלישי אחר כתוצאה משימוש ו/או רכישה אשר מתבצעת באתר, לרבות במקרה של רכישה אשר מבוצעת באמצעות כרטיס אשראי ללא הסכמת בעליו.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                8.3. 
            </span>
            <span>
                המשתמש לא יהיה זכאי לביטול השירות לאחר מועד תחילת אישורי ההגעה.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                8.4. 
            </span>
            <span>
                ביטול השירות הינו בהתאם להוראות חוק הגנת הצרכן, התשמ"א-1981. הודעת הביטול תעשה בכתב בלבד לכתובת הדואר האלקטרוני של הנהלת האתר בעמוד צור קשר.
            </span>
        </p>
                `,
    },
    {
      title: `
        <p>
            <strong>
                9.	<u> סמכות שיפוט </u>
            </strong>
        </p>`,
      content: `
        <p class="content-item">
            <span class="number">
                9.1. 
            </span>
            <span>
                כל מחלוקת או הליך משפטי לגבי כל עניין הקשור להסכם זה או הנובע ממנו, ייפתח בלעדית באמצעות הגשת בקשה לבתי המשפט המוסמכים של תל-אביב, ישראל, ובמקרה כזה, רק דיני מדינת ישראל יחולו על כל סכסוך בין הצדדים.
            </span>
        </p>
                  `,
    },
  ],
}
