export default {
  terms_title: 'لائحة شروط الاستخدام',
  introduction: [
    {
      content: `
              <p>
              مرحبًا بكم في موقع Anita-lee (أدناه: <strong> "الموقع" </strong>) ، والذي يقدم خدمات (أدناه: <strong>"الخدمات"</strong>) للأحداث ، بما في ذلك نظام لإدارة الضيوف وشهادات الوصول وإدارة نفقات الأحداث. يشكل أي استخدام للموقع موافقة على هذه اللوائح (أدناه: <strong>"الخدمات"</strong>) ، وفقًا للشروط والأحكام الواردة فيها.
              </p>
          `,
    },
    {
      content: `
          <p>
            المذكور في هذا القوانين تم كتابته بلغة الذكر لأسباب راحة فقط، ويشمل جميع الأجناس بالمساواة.
          </p>`,
    },
    {
      content: `
          <p>
            يمكن أن تتغير هذه اللائحة من وقت لآخر، وعلى المستخدم تحديث نفسه باللائحة في كل زيارة للموقع. يخضع تصفح الموقع و/أو استخدامه بأي طريقة لموافقة شروط اللائحة والتزام العمل وفقًا لها. يجب أن يكون واضحًا أن اللائحة تشكل اتفاقًا قانونيًا ملزمًا لأي شيء وأمر وتلزم المستخدم بجميع استخداماته للموقع.
          </p>`,
    },
    {
      content: `
          <p>
            يشكل طلب الخدمات على هذا الموقع إعلانًا صريحًا من قبل المستخدم بأنه قرأ وفهم ووافق على أحكام هذه اللائحة. تشكل اللائحة عقدًا ملزمًا بين المستخدم وإدارة الموقع.
          </p>`,
    },
  ],
  sections: [
    {
      title: `
          <p>
              <strong>
              1.<u> قيود الاستخدام </u> 
              </strong>
          </p>`,

      content: `
          <p class="content-item">
              <span class="number">
                  1.1. 
              </span>
              <span>
                يتم تقديم الخدمات في دولة إسرائيل فقط.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  1.2. 
              </span>
              <span>
                يُسمح للمستخدم بالاستخدام الموقع لمن هم فوق سن 18 فقط، والذي يكون قانونيًا قادرًا على الاتفاق على هذا العقد، شريطة الموافقة على شروط اللائحة.
              </span>
            </p>
          <p class="content-item">
              <span class="number">
                  1.3. 
              </span>
              <span>
                يلتزم المستخدم بعدم استخدام الخدمات لنشر أي محتوى مسيء و/أو محرض و/أو مزعج، بالإضافة إلى أي محتوى يتعارض مع القانون.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  1.4. 
              </span>
              <span>
                يلتزم المستخدم بعدم القيام بأي استخدام غير شخصي لخدمات الموقع.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  1.5. 
              </span>
              <span>
                تحتفظ إدارة الموقع بالحق في منع المستخدم من استخدام الموقع بالكامل أو بعض الخدمات، وذلك وفقًا لتقديرها الحصري ولأي سبب دون الحاجة إلى توضيح هذا القرار. المستخدم الذي يمنعه الوصول إلى الموقع لن يكون مخولًا بالعودة واستخدام الموقع.
              </span>
          </p>
            `,
    },
    {
      title: `
          <p>
              <strong>
              2.<u>  الخدمات </u> 
              </strong>
          </p>`,
      content: `
          <p class="content-item">
              <span class="number">
                  2.1. 
              </span>
              <span>
                يعتبر الموقع منصة لإدارة الأحداث، بما في ذلك تأكيدات الحضور عبر رسائل SMS وWhatsApp والمكالمات التلقائية. وذلك وفقًا للحزمة التي اختارها المستخدم.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  2.2. 
              </span>
              <span>
                إدارة الموقع لا تضمن أن الموقع سيعمل بدون أعطال أو أخطاء من أي نوع. يتم توفير الخدمة على الموقع وفقًا لنموذج "كما هو" ويتيح للمستخدم تجربة النظام من خلال شراء خدمات مدفوعة. إدارة الموقع ليست مسؤولة عن أي ضرر مباشر أو غير مباشر.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  2.3. 
              </span>
              <span>
                إدارة الموقع لا تضمن أن الموقع سيعمل بدون أعطال أو أخطاء من أي نوع. يتم توفير الخدمة على الموقع وفقًا لنموذج "كما هو" ويتيح للمستخدم تجربة النظام من خلال شراء خدمات مدفوعة. إدارة الموقع ليست مسؤولة عن أي ضرر مباشر أو غير مباشر.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  2.4. 
              </span>
              <span>
                يخضع استخدام الموقع لهذا الاتفاق وشروط الاستخدام الخاصة به، وهو يشكل وثيقة ملزمة بين المستخدم وإدارة الموقع.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  2.5. 
              </span>
              <span>
                يتم استخدام الموقع على مسؤولية المستخدم فقط.
              </span>
          </p>
            `,
    },
    {
      title: `
          <p>
              <strong>
              3.<u> مسؤولية المستخدم </u> 
              </strong>
          </p>`,
      content: `
          <p class="content-item">
              <span class="number">
                  3.1. 
              </span>
              <span>
                يتعهد المستخدم بعدم إرسال رسائل SMS/WhatsApp ومكالمات صوتية إلى أرقام الهواتف التي لم يعطوا موافقتهم على ذلك.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  3.2. 
              </span>
              <span>
                يتعهد المستخدم بالامتثال لقانون مكافحة البريد المزعج (السبام).
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  3.3. 
              </span>
              <span>
                يتعهد المستخدم عدم نشر محتويات تتعلق بالسبام.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  3.4. 
              </span>
              <span>
                يتعهد المستخدم عدم نقل حقوق استخدامه في الموقع لأي طرف ثالث، ويتحمل المسؤولية الحصرية لتأمين حسابه وكلمات المرور الخاصة به.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  3.5. 
              </span>
              <span>
                يقر المستخدم بأنه مدرك للأضرار التي قد تحدث إذا تلقت شكاوى مباشرة أو عن طريق جهة ثالثة بشأن إرسال رسائل SMS/WhatsApp و/أو مكالمات صوتية تلقائية إلى المستلمين الذين لم يطلبوا استلامها بشكل صريح، ويتعهد المستخدم بتسوية وتعويض إدارة الموقع وأي شخص ينوب عنها بسبب أي مطالبة و/أو مطلب يتم إرساله إليهم. المستخدم مدرك ويوافق على أن تنفيذ أيٍ من هذه الإجراءات سيؤدي إلى إيقاف النشاط أحادي الجانب أمام إدارة الموقع والموقع، وأنه لن يحصل على أي استرداد مالي للمبالغ المدفوعة ولم تتم المعالجة بسبب انتهاك أحد بنود هذا الاتفاق.
              </span>
            </p>
          <p class="content-item">
              <span class="number">
                  3.6. 
              </span>
              <span>
                يدرك المستخدم أنه يتحمل المسؤولية الحصرية عن إدخال البيانات والمحتوى إلى الموقع، حيث يقوم المستخدم بإدخال محتوى أو بيانات غير صحيحة، فلن يقدم أي ادعاءات أو شكاوى ضد إدارة الموقع.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  3.7. 
              </span>
              <span>
                يتعهد المستخدم بأنه مدرك لحقيقة أن محتوى الرسائل والمكالمات الصوتية التلقائية قابلة للتعديل وأنه يتحمل المسؤولية الحصرية لذلك وفقًا للقيود المنصوص عليها في هذا الاتفاق، ولن يكون له أو لأي طرف آخر أي ادعاءات. إدارة الموقع لن تكون مسؤولة عن أي ضرر مباشر أو غير مباشر يتعلق بإرسال محتوى الرسائل والمكالمات الصوتية التلقائية.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  3.8. 
              </span>
              <span>
                يدرك المستخدم أن تاريخ بدء تأكيدات الحضور قابل للتعديل، وطالما لم يقم بتغيير التاريخ، ستبدأ إدارة الموقع تأكيدات الحضور في التاريخ المحدد وفقًا لتقديرها الحصري، ولن يكون للمستخدم أي ادعاءات أو شكاوى بشأن ذلك.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  3.9. 
              </span>
              <span>
                المسؤولية الحصرية للتحقق من صحة جميع البيانات بما في ذلك ربط الإشارة بالحدث تقع على عاتق المستخدم، ولن يكون للمستخدم أي ادعاءات أو شكاوى ضد إدارة الموقع بشأن ذلك.
              </span>
          </p>
            `,
    },
    {
      title: `
          <p>
              <strong><u> 
              4.<u> كماليات الملكية الفكرية </u> 
              </strong>
          </p>`,
      content: `
          <p class="content-item">
              <span class="number">
                  4.1. 
              </span>
              <span>
                جميع الحقوق في الموقع، بما في ذلك حقوق الملكية الفكرية في الموقع، بما في ذلك اسمه، وعلاماته التجارية، وتصميمه، ومحتواه، وكذلك حقوق الملكية في الخدمات والخدمات المرتبطة به، تكون مملوكة حصرياً للموقع فقط.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  4.2. 
              </span>
              <span>
                إدارة الموقع هي المالك الحصري للموقع وقواعد البيانات وأي محتوى آخر مشمول به. المستخدم ملتزم بأنه مدرك بأنه غير مسموح له بنسخه أو توزيعه أو استنساخه و/أو تحويله إلى أطراف ثالثة سواء كان جزءًا منه أو بالكامل. بما في ذلك الاستخدام التجاري للمعلومات الموجودة في الموقع، إلا بموافقة مسبقة مكتوبة من إدارة الموقع.
              </span>
          </p>
              `,
    },
    {
      title: `
          <p>
              <strong>
              5.<u> انتهاك قواعد الاستخدام </u> 
              </strong>
          </p>`,
      content: `
          <p class="content-item">
              <span class="number">
                  5.1. 
              </span>
              <span>
                أي شخص ينتهك هذه الشروط والأحكام سيكون مسؤولاً أمام إدارة الموقع، وموظفيه، ومدرائه، وحاملي الأسهم أو أي شخص يمثلهم بسبب أي ضرر، خسارة، أو خسائر مالية، وتكاليف الأتعاب القانونية وتكاليف المحاكمة التي ينتج عن هذا الانتهاك.
              </span>
          </p>
                `,
    },

    {
      title: `
          <p>
              <strong>
              6.<u> الاستخدام في الموقع وخدماته </u> 
              </strong>
          </p>`,
      content: `
          <p class="content-item">
              <span class="number">
                  6.1. 
              </span>
              <span>
                يُسمح للمستخدم باستخدام الموقع وخدماته لأغراض شخصية وخاصة فقط.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  6.2. 
              </span>
              <span>
                يخضع استخدام الموقع وخدماته لتسجيل المستخدم في الموقع، ويتاح فقط للمستخدمين المسجلين.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  6.3. 
              </span>
              <span>
                يخضع التسجيل في الموقع لتقديم معلومات المستخدم مثل الاسم الكامل والبريد الإلكتروني واختيار كلمة المرور. سيقوم الموقع بالتحقق من هوية المستخدم من خلال عنوان البريد الإلكتروني الخاص به.
              </span>
          </p>
          <p class="content-item">
          <span class="number">
              6.4. 
          </span>
          <span>
              استخدام الموقع يعتبر موافقة المستخدم على هذه الشروط والأحكام والموافقة على تلقي النشرة الإخبارية و/أو المواد الإعلانية من الموقع، على أن يكون للمستخدم حق إخطار إدارة الموقع برغبته في إزالته من كل ذلك عبر البريد الإلكتروني لإدارة الموقع.
          </span>
          </p>
          <p class="content-item">
              <span class="number">
                  6.5. 
              </span>
              <span>
                تحتفظ إدارة الموقع بحقها في إضافة حقول تعريف وفقًا لتقديرها الخاص.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  6.6. 
              </span>
              <span>
                تحتفظ إدارة الموقع بالحق في إلغاء تسجيل المستخدم في الموقع لأي سبب، دون أي التزام بتوضيح هذا القرار.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  6.7. 
              </span>
              <span>
                يدرك المستخدم أن بعض الخدمات المقدمة في الموقع مشروطة بتواجد اتفاقيات بين إدارة الموقع ومقدمي الخدمة المتنقلة المختلفين. في أي حالة يتم فيها إنهاء الاتفاق بين إدارة الموقع ومشغل الاتصالات المحمولة أيًا كان السبب، ستتوقف الخدمات المقدمة ولن يكون للمستخدم أي ادعاء أو مطالبة بشأن ذلك.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  6.8. 
              </span>
              <span>
                يعلن المستخدم ويتعهد بعدم وجود أي ادعاء أو مطالبة ضد إدارة الموقع و/أو مشغلي الاتصالات المحمولة نتيجة لظروف قوة قاهرة، بما في ذلك أي تغيير يتعلق بالتنظيمات.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  6.9. 
              </span>
              <span>
                يدرك المستخدم أن بعض الخدمات المقدمة في الموقع تعتمد على أطراف ثالثة، وبالتالي، في حالة حدوث خلل أو عيب أو عدم صلاحية أو أي سبب آخر يرجع إلى إحدى الأطراف الثالثة، فإن إدارة الموقع لن تكون مسؤولة عن أي ضرر أو خسارة مباشرة أو غير مباشرة يتكبدها المستخدم.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  6.10. 
              </span>
              <span>
                لا تتحمل إدارة الموقع أي مسؤولية عن محتوى و/أو معلومات المعلومات الإعلانية على الموقع وكذلك الروابط إلى مواقع خارجية لأطراف ثالثة.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  6.11. 
              </span>
              <span>
                يدرك المستخدم أنه هو المسؤول الحصري عن نسخ احتياطي للملفات والبيانات المعروضة في النظام، وبذلك يعفي إدارة الموقع من أي مسؤولية عن أي ضرر مباشر أو غير مباشر يمكن أن يحدث نتيجة لفقدان البيانات.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  6.12. 
              </span>
              <span>
                باستخدام المنصة، يمنح المستخدم إذنه الصريح لإدارة الموقع للتواصل معه من وقت لآخر بعروض لشراء منتجات وخدمات مختلفة وعروض وما إلى ذلك بجميع الوسائل المسموح بها وفقًا لقانون الاتصالات (بيزك وبث) لعام 2008، بما في ذلك عبر البريد الإلكتروني وتطبيق واتساب ورسائل قصيرة، وتكون مخولة بتسليم معلوماته أيضًا لأطراف ثالثة لأغراض التنفيذ. يمكن توجيه أي سؤال بشأن استخدام المعلومات التي يتم جمعها بواسطة إدارة الموقع، بما في ذلك طلب الحذف من قاعدة البيانات أو رفض قبول أي عروض أو حذف أي معلومات، إلى إدارة الموقع.
              </span>
          </p>
                `,
    },
    {
      title: `
          <p>
              <strong>
              7.<u> مسؤولية إدارة الموقع </u>
              </strong>
          </p>`,
      content: `
          <p class="content-item">
              <span class="number">
                  7.1. 
              </span>
              <span>
                لا تتحمل إدارة الموقع وجميع الأشخاص المرتبطين بها أي مسؤولية عن أي ضرر يحدث نتيجة للخدمات المقدمة من خلالها.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  7.2. 
              </span>
              <span>
                يلتزم المستخدم بتعويض وتبرئة إدارة الموقع وموظفيها ومديريها أو أي شخص يمثلهم و/أو مشغلي الهواتف الخلوية، بما يتعلق به، عن أي خسارة أو ضرر أو خسارة أو مصاريف أو التزام تسببه المستخدم نتيجة لانتهاكه لشروط استخدام هذا الاتفاق. بالإضافة إلى ذلك، يوافق المستخدم ويوافق على أن لدى إدارة الموقع الحق في المطالبة بالتعويض عن أي ضرر يتسبب فيها نتيجة استخدام النظام لأغراض غير قانونية، بطرق غير قانونية، و/أو بطرق لا تتفق مع شروط هذا الاتفاق، وأن هذه التعويضات قد تشمل تعويضات بسبب أي نوع من الأضرار.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  7.3. 
              </span>
              <span>
                لا تتعهد إدارة الموقع بأن خدماتها ستكون خالية من الأعطال والأخطاء والاضطرابات.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  7.4. 
              </span>
              <span>
                لا تتحمل إدارة الموقع أي مسؤولية عن أي ضرر مباشر و/أو غير مباشر يتعلق بالموقع و/أو الذي ينشأ عن استخدام غير صحيح وغير ملتزم بخدمات الموقع و/أو المحتويات التي يتم تحديثها بواسطة المستخدم من خلال خدمات الموقع و/أو المحتوى المنشور على الموقع، بالإضافة إلى أي ضرر مباشر و/أو غير مباشر يتعلق بعملية تشغيل غير مثالية للموقع. من خلال هذا الاتفاق، أنت تتنازل عن أي ادعاء أو مطالبة بح.
              </span>
          </p>
                  `,
    },
    {
      title: `
          <p>
              <strong>
              8.<u> سياسة الدفع والإلغاء </u>
              </strong>
          </p>`,
      content: `
          <p class="content-item">
              <span class="number">
                  8.1. 
              </span>
              <span>
                تحتفظ إدارة الموقع بالحق، وبتقديرها الكامل وبدون الحاجة إلى إشعار مسبق، بتغيير شروط أي عرض أو منفعة بما في ذلك فترة العرض أو المنفعة بما في ذلك اختصارها أو تمديدها. تحتفظ إدارة الموقع بالحق، وبتقديرها الحصري، في منع أي مشاركة لأي مستخدم في العرض أو المنفعة، دون أن توفر للمستخدم أي سبب.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  8.2. 
              </span>
              <span>
                لا تتحمل إدارة الموقع أي مسؤولية عن أي ضرر مباشر و/أو غير مباشر أو أي خسارة تتعرض لها المستخدم و/أو طرف ثالث آخر نتيجة استخدامه و/أو شرائه الذي يتم على الموقع، بما في ذلك في حالة الشراء الذي يتم عن طريق بطاقة ائتمان دون موافقة صاحب البطاقة.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  8.3. 
              </span>
              <span>
                المستخدم ليس له الحق في إلغاء الخدمة بعد موعد بدء تأكيدات الوصول.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  8.4. 
                </span>
                <span>
                    إلغاء الخدمة يتم وفقًا لأحكام قانون حماية المستهلك، العام 1981. يتم إرسال إشعار الإلغاء بالكتابة فقط إلى عنوان البريد الإلكتروني لإدارة الموقع الموجود على صفحة الاتصال.
                </span>
          </p>
                    `,
    },
    {
      title: `
          <p>
            <strong>
                9.<u> الاختصاص القضائي </u> 
            </strong>
          </p>`,
      content: `
          <p class="content-item">
              <span class="number">
                  9.1. 
              </span>
              <span>
                يتم تطبيق أي خلاف أو إجراء قضائي فيما يتعلق بأي مسألة تتعلق باتفاق هذا الاتفاق أو ينشأ عنه، حصريًا من خلال تقديم طلب للمحاكم المختصة في تل أبيب، إسرائيل، وفي حالة كهذه، يتم تطبيق قوانين دولة إسرائيل فقط على أي نزاع بين الأطراف.
              </span>
          </p>
                      `,
    },
  ],
}
