export default {
  terms_title: 'ПОЛОЖЕНИЯ ИСПОЛЬЗОВАНИЯ',
  introduction: [
    {
      content: `
            <p>
              Добро пожаловать на сайт Anita-lee (далее <strong>"Сайт"</strong>), который предлагает услуги (далее <strong>"Услуги"</strong>) для проведения мероприятия. Включает систему управления гостями, подтверждение прибытия и управление затратами на мероприятие. Любое использование Сайта является согласием с данными Положениями (далее <strong>"Положения"</strong>), включая условия и ограничения, изложенные в них.
            </p>
          `,
    },
    {
      content: `
          <p>
            Положения использования написаны на мужском языке только для удобства и в равной степени относятся ко всем полам.
          </p>`,
    },
    {
      content: `
          <p>
            Положения могут изменяться время от времени, и пользователь обязан ознакомиться с ними при каждом посещении Сайта. Доступ и/или использование Сайта любого вида подразумевает согласие с Положениями и обязанность действовать в соответствии с ними. Заметим, что Положения являются юридически обязательным соглашением в отношении любого вопроса или дела и обязывают пользователя в отношении всех его действий на Сайте.
          </p>`,
    },
    {
      content: `
          <p>
            Само бронирование Услуг на данном Сайте является ясным заявлением пользователя о том, что он прочитал, понял и согласился с положениями данных Положений. Положения являются юридически обязательным соглашением между пользователем и администрацией Сайта.
          </p>`,
    },
  ],
  sections: [
    {
      title: `
          <p>
              <strong>
                1.<u> Ограничения использования </u>
              </strong>
          </p>`,

      content: `
          <p class="content-item">
              <span class="number">
                  1.1. 
              </span>
              <span>
                Услуги предоставляются только в Государстве Израиль.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  1.2. 
              </span>
              <span>
                Использование Сайта доступно только пользователям старше 18 лет, которые имеют право заключать юридические соглашения и соглашаются с условиями данных Положений.
              </span>
            </p>
          <p class="content-item">
              <span class="number">
                  1.3. 
              </span>
              <span>
                Пользователь обязуется не использовать Услуги для распространения вредоносного, провокационного или тревожащего контента, а также любого контента, противоречащего закону.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  1.4. 
              </span>
              <span>
                Пользователь обязуется не осуществлять использование Услуг в коммерческих целях.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  1.5. 
              </span>
              <span>
                Администрация Сайта вправе запретить пользователю доступ к Сайту в части или полностью, по своему исключительному усмотрению и без необходимости объяснять причины этого решения. Пользователь, которому отказано в доступе к Сайту, не будет иметь прав.
              </span>
          </p>
            `,
    },
    {
      title: `
          <p>
              <strong>
              2.<u> Сервисы </u>
              </strong>
          </p>`,
      content: `
          <p class="content-item">
              <span class="number">
                  2.1. 
              </span>
              <span>
                Сайт представляет собой платформу для управления мероприятиями, включая уведомления о прибытии в форме SMS, WhatsApp и автоматических звонков, в зависимости от выбранного пользователем пакета.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  2.2. 
              </span>
              <span>
                Администрация сайта не гарантирует бесперебойную и безошибочную работу сайта. Сервис на сайте предоставляется "как есть" и позволяет пользователю опробовать систему при условии платной покупки услуг. Администрация сайта не несет ответственности явно или косвенно за непосредственный и/или косвенный ущерб.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  2.3. 
              </span>
              <span>
                При регистрации на сайте и использовании сервиса пользователь соглашается и обязуется соблюдать условия, описанные в настоящем соглашении, которые могут быть периодически обновлены администрацией сайта. При регистрации в системе пользователь соглашается и обязуется с тем, что администрация сайта вправе периодически обновлять условия использования в соответствии со своим усмотрением, и полностью обязан самостоятельно обновлять их время от времени.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  2.4. 
              </span>
              <span>
                Использование сайта осуществляется в соответствии с настоящим соглашением и условиями использования и является обязательным документом между пользователем и администрацией сайта.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  2.5. 
              </span>
              <span>
                Использование сайта осуществляется исключительно на собственную ответственность пользователя.
              </span>
          </p>
            `,
    },
    {
      title: `
          <p>
              <strong> 
                3.<u> Ответственность пользователя </u>
              </strong>
          </p>`,
      content: `
          <p class="content-item">
              <span class="number">
                  3.1. 
              </span>
              <span>
                Пользователь обязуется не рассылать SMS/WhatsApp и совершать голосовые вызовы на номера телефонов без их согласия.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  3.2. 
              </span>
              <span>
                Пользователь обязуется действовать в соответствии с законом о спаме.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  3.3. 
              </span>
              <span>
                Пользователь обязуется не распространять контент, связанный со спамом.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  3.4. 
              </span>
              <span>
                Пользователь обязуется не передавать свое право использования сайта третьим лицам, а также нести исключительную ответственность за безопасность своей учетной записи и паролей.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  3.5. 
              </span>
              <span>
                Пользователь заявляет, что осознает возможные последствия, если будут получены жалобы прямо или через третьи стороны относительно рассылки SMS/WhatsApp и/или автоматических голосовых вызовов получателям, которые явно не запрашивали их, и пользователь обязуется защищать и возмещать любые претензии и/или требования, направленные к администрации сайта и представителям сайта. Пользователь осознает и соглашается с тем, что выполнение любого из этих действий приведет к одностороннему приостановлению деятельности по отношению к администрации сайта и самому сайту, а также что он не будет получать какую-либо компенсацию за платежи, произведенные, но не выполненные из-за нарушения данного соглашения.
              </span>
            </p>
          <p class="content-item">
              <span class="number">
                  3.6. 
              </span>
              <span>
                Пользователь осознает, что он несет исключительную ответственность за ввод данных и контента на сайт, так как пользователь вводит неверный контент или данные, он не будет предъявлять свои претензии и/или жалобы к администрации сайта.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  3.7. 
              </span>
              <span>
                Пользователь обязуется осознавать, что содержание автоматических сообщений и голосовых вызовов может быть изменено и полностью несет ответственность только за это, с ограничениями, указанными в настоящих правилах, и у него и/или у его представителей не будет никаких претензий. Администрация сайта не будет нести ответственности за любой непосредственный и/или косвенный ущерб, связанный с отправкой содержания автоматических сообщений и голосовых вызовов.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  3.8. 
              </span>
              <span>
                Пользователь осознает, что дата начала подтверждений прибытия может быть изменена, и до тех пор, пока он не изменит дату, администрация сайта начнет подтверждения прибытия в указанную дату по ее исключительному усмотрению, и у пользователя не будет никаких претензий и/или жалоб по этому поводу.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  3.9. 
              </span>
              <span>
                Исключительная ответственность за проверку правильности всех данных, включая ссылки на мероприятия, лежит на пользователе, и у пользователя не будет никаких претензий и/или жалоб к администрации сайта по этому поводу.
              </span>
          </p>
            `,
    },
    {
      title: `
          <p>
              <strong>
                4.<u> Корпоративные права </<u> 
              </strong>
          </p>`,
      content: `
          <p class="content-item">
              <span class="number">
                  4.1. 
              </span>
              <span>
                Все права на сайт, включая права интеллектуальной собственности на сайт, включая его название, товарные знаки, дизайн, контент, а также права собственности на услуги и сопутствующие услуги, принадлежат исключительно сайту.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  4.2. 
              </span>
              <span>
                Администрация сайта является исключительным владельцем сайта, баз данных и любого другого содержимого, содержащегося на нем. Пользователь обязуется не копировать, распространять, воспроизводить и/или передавать информацию на сайте, его части или в целом третьим лицам. Это включает использование коммерческого характера информации на сайте, если не получено предварительное письменное разрешение от администрации сайта.
              </span>
          </p>
              `,
    },
    {
      title: `
          <p>
              <strong>
                5.<u> Нарушение условий использования  </u>
              </strong>
          </p>`,
      content: `
          <p class="content-item">
              <span class="number">
                  5.1. 
              </span>
              <span>
                Любое нарушение настоящих условий использования приведет к преследованию администрации сайта, их сотрудников, руководителей, акционеров или лиц от их имени, в связи с любыми убытками, ущербом, упущенной прибылью, расходами на оплату услуг адвоката и судебные издержки, которые возникнут вследствие такого нарушения.
              </span>
          </p>
                `,
    },

    {
      title: `
          <p>
              <strong>
                6.<u> Использование сайта и его сервисов </u>
              </strong>
          </p>`,
      content: `
          <p class="content-item">
              <span class="number">
                  6.1. 
              </span>
              <span>
                Пользователь имеет право использовать сайт и его сервисы только в личных и личностных целях.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  6.2. 
              </span>
              <span>
                Использование сайта и его сервисов доступно только зарегистрированным пользователям.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  6.3. 
              </span>
              <span>
                Регистрация на сайте требует предоставления пользовательских данных, таких как полное имя, электронная почта и выбор пароля. Сайт будет проверять подлинность пользователя через его электронную почту.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  6.4. 
              </span>
              <span>
                  Использование сайта означает согласие с настоящими условиями и согласие на получение информационных рассылок и/или рекламных материалов от сайта, с возможностью пользователю уведомить администрацию сайта письменно по электронной почте о своем желании отказаться от них.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  6.5. 
              </span>
              <span>
                Администрация сайта оставляет за собой право добавлять идентификационные поля по своему исключительному усмотрению.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  6.6. 
              </span>
              <span>
                Администрация сайта имеет право отменить регистрацию пользователя на сайте по любой причине без объяснения данного решения.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  6.7. 
              </span>
              <span>
                Пользователь осознает, что некоторые сервисы, предоставляемые на сайте, зависят от соглашений между администрацией сайта и различными мобильными операторами. В случае прекращения соглашения между администрацией сайта и любым мобильным оператором по любой причине, услуги будут прекращены, и пользователь не имеет права предъявлять претензии или требования в этом отношении.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  6.8. 
              </span>
              <span>
                Пользователь заявляет и обязуется, что не будет предъявлять никаких претензий или требований против администрации сайта и/или мобильных операторов по причинам, связанным с форс-мажорными обстоятельствами, включая любые изменения, связанные с регулированием.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  6.9. 
              </span>
              <span>
                Пользователь осознает, что часть услуг, предоставляемых на сайте, зависит от третьих сторон, поэтому в случае сбоя, недостатка работоспособности, отказа и/или любой другой причины, исходящей от одной или нескольких третьих сторон, администрация сайта не несет ответственности за любой прямой и/или косвенный ущерб, возникший у пользователя.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  6.10. 
              </span>
              <span>
                Администрация сайта не несет ответственности за содержание и/или информацию, касающуюся рекламной информации на сайте, а также за ссылки на сторонние веб-сайты.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  6.11. 
              </span>
              <span>
                Пользователь осознает, что он лично несет исключительную ответственность за резервное копирование файлов и данных, отображаемых в системе, и тем самым освобождает администрацию сайта от любой ответственности за возможный прямой и/или косвенный ущерб, вызванный потерей данных.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  6.12. 
              </span>
              <span>
                При использовании системы пользователь явно дает согласие на то, что администрация сайта имеет право время от времени обратиться к нему с предложениями о приобретении различных товаров, услуг, акций и т.д. в соответствии с требованиями Закона о связи (Bezeq and Broadcasting), 2008, включая электронную почту, WhatsApp и SMS, а также передавать его данные третьим сторонам для выполнения вышеуказанного. Все вопросы, связанные с использованием информации, собранной администрацией сайта, включая запросы на удаление из базы данных или отказ от получения любых предложений или удаления какой-либо информации, могут быть направлены в администрацию сайта по электронной почте: support@anita-lee.com.
              </span>
          </p>
                `,
    },
    {
      title: `
          <p>
              <strong>
                7.<u> Ответственность администрации сайта </u>
              </strong>
          </p>`,
      content: `
          <p class="content-item">
              <span class="number">
                  7.1. 
              </span>
              <span>
                Администрация сайта и все лица, действующие от ее имени, не несут ответственности за любой ущерб, возникший в результате предоставления услуг ею.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  7.2. 
              </span>
              <span>
                Пользователь обязуется компенсировать и защитить администрацию сайта, ее сотрудников, управляющих или уполномоченных лиц, а также мобильных операторов в отношении любой потери, ущерба, убытка, расходов или обязательств, возникших у любого из них и обусловленных нарушением пользователем настоящих Правил использования. Кроме того, пользователь подтверждает и соглашается с тем, что администрация сайта имеет право требовать компенсации за любой ущерб, причиненный ей в результате незаконного использования системы, незаконных действий и/или действий, несоответствующих условиям настоящего Соглашения, и такая компенсация может включать компенсацию любого вида ущерба.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  7.3. 
              </span>
              <span>
                Администрация сайта не гарантирует, что ее услуги будут свободны от сбоев, ошибок и препятствий.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  7.4. 
              </span>
              <span>
                Администрация сайта не несет ответственности за любой прямой и/или косвенный ущерб, связанный с сайтом и/или вызванный неправильным использованием и неправомерным использованием услуг сайта и/или содержимого, предоставленного пользователем с помощью услуг сайта и/или опубликованного на сайте, а также за любой прямой и/или косвенный ущерб, связанный с неправильной работой сайта. Соглашаясь с настоящим Соглашением, вы отказываетесь от любых претензий и/или исков против администрации сайта.
              </span>
          </p>
        `,
    },
    {
      title: `
          <p>
              <strong>
                8.<u> Политика платежей и отмены </u>
              </strong>
          </p>`,
      content: `
          <p class="content-item">
              <span class="number">
                  8.1. 
              </span>
              <span>
                Администрация сайта имеет право по своему единоличному усмотрению и без предварительного уведомления изменять условия любой сделки или льготы, включая срок действия сделки или льготы, в том числе сокращать или продлевать их. Администрация сайта оставляет за собой право по своему единоличному усмотрению запретить участие любого пользователя в сделке или льготе, не предоставляя пользователю каких-либо объяснений.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  8.2. 
              </span>
              <span>
                Администрация сайта не несет ответственности за любой прямой и/или косвенный ущерб или потерю, возникшие у пользователя и/или третьей стороны в результате использования и/или покупки, осуществляемой на сайте, включая случаи покупки, совершаемой без согласия владельца кредитной карты.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  8.3. 
              </span>
              <span>
                Пользователь не имеет права на отмену услуги после начала подтверждения бронирования.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  8.4. 
                </span>
                <span>
                    Отмена услуги осуществляется в соответствии с положениями Закона о защите прав потребителей, 1981. Уведомление об отмене должно быть направлено только в письменной форме на электронный адрес администрации сайта, указанный на странице контактов.
                </span>
          </p>
                    `,
    },
    {
      title: `
          <p>
                <strong>
                    9.<u> Компетенция суда  </u>
                </strong>
          </p>`,
      content: `
          <p class="content-item">
              <span class="number">
                  9.1. 
              </span>
              <span>
                Любой спор или правовое разбирательство, связанное с любым вопросом, касающимся настоящего Соглашения или вытекающим из него, будет исключительно рассматриваться путем подачи заявления в уполномоченные суды Тель-Авива, Израиль, и в таком случае применяются только законы государства Израиль в отношении любых споров между сторонами.
              </span>
          </p>
                      `,
    },
  ],
}
