import { initialAbility } from '@/plugins/acl/config'
import { useRouter } from '@core/utils'
import { getCurrentInstance } from '@vue/composition-api'

export default function useLogoutUser() {
  const vm = getCurrentInstance().proxy
  const { router } = useRouter()

  const logoutUser = () => {
    localStorage.removeItem('accessToken')

    // Remove userData & Ability from localStorage
    localStorage.removeItem('userData')
    localStorage.removeItem('userAbility')

    // Reset ability
    vm.$ability.update(initialAbility)

    // Redirect to login page
    router.push({ name: 'auth-login' })
  }

  return {
    logoutUser,
  }
}
