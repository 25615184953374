export default {
  willHappyToHelpYou: 'We will be happy to help you!',
  notFindAnswerForQa: "Didn't find an answer to your question?",
  sendUsEmail: 'Contact us',
  stillNeedHelp: "If you still need assistance, don't hesitate to contact us",
  questionsAndAnswers: 'Q&A about',

  // tabs
  messages: 'Messages',
  calls: 'Calls',
  system: 'System',
  payment: 'Payment',

  // messages
  question_about_msg_flow: 'How is the process of sending messages will done?',
  answer_for_msg_flow:
    'The system will send messages to your invitees based on the message format you have set. Each invitee will receive messages according to the settings you have chosen for them, such as special meals and transportation. The message will be displayed in the language you have selected for that invitee.<br> The "RSVP message" will be sent on the day you have set for RSVPs to begin.<br> The reminder and direction message will be sent on the day of the event, only to invitees who have confirmed their attendance.<br> The thank you message will be sent the day after the event, only to invitees who have confirmed their attendance.',
  question_about_edit_msg_content: 'Is it possible to edit the content of the messages?',
  answer_for_edit_msg_content:
    'Yes, you can edit the message content in the "Message Template" tab in the menu. For each language, there are several types of messages (RSVP message, direction, and thanks), and all of them can be edited as you wish.',
  question_about_visibility_msg: 'How will the message look at my invitee?',
  answer_for_visibility_msg:
    'The method by which the invitee will receive the message will depend on the package you purchased (SMS/WhatsApp). At the bottom of the message that you edited in the "Message Template" tab, a unique link will be attached to invitees for your event. The invitation can be viewed in the "Invitation" tab. The language and questions asked in the invitation will be unique based on the settings you have chosen for each invitee.',
  question_about_edit_msg_content_after_start_proccess:
    'Is it possible to edit the content of the message after the process has started?',
  answer_for_edit_edit_msg_content_after_start_proccess:
    'Yes, you can edit the content of the message even after the process has started. However, if the message has already been sent, it will not be sent again. The new wording will only be updated in messages that have not yet been sent.',
  question_about_sending_time_msg: 'At what times are the messages sent?',
  answer_for_sending_time_msg:
    'The messages are sent during operating hours: Sunday-Thursday between 9:00-21:00, and on Fridays between 9:00-15:00.',

  // calls
  question_about_calls_flow: 'How does the process of contacting our invitees work?',
  answer_for_calls_flow:
    'The calls are automatic without human intervention, thus allowing you to contact your invitees in any language you choose.<br>The call will be made if the invitee has not yet confirmed arrival in the messages sent to him.<br>The conversation will take place in the language you set for the invitee and according to the content you set for that language in the "Call Template" tab.',
  question_about_edit_calls_content: 'Is it possible to edit the call conversation?',
  answer_for_edit_calls_content:
    'Yes, with the premium package, you can edit and listen to the conversation in the "Call Template" tab of the menu.<br> Each language has its own template that can be edited as desired. However, only the beginning of the conversation can be edited, as the remainder of the conversation involves prompts to press keys and is not customizable.',
  question_about_how_calls_listen: 'How will the call sound when you call the invitee?',
  answer_for_how_calls_listen:
    'The first part of the call is the opening message that you defined in the "call template" tab, which the invitee will hear when they answer the phone call.<br> Later, the invitee will need to press a key on their phone\'s keypad to indicate whether they intend to attend or not. If they choose to attend, the system will ask them to enter the number of people attending.<br>  If the invitee does not respond or does not answer the call, the system will continue to call them in the next round.<br> Once the conversation is complete, you can update their responses in the system.',
  question_about_edit_calls_content_after_start_proccess:
    'Is it possible to change the content of the conversation after the process has started?',
  answer_for_edit_edit_calls_content_after_start_proccess:
    'Yes, the content of the call can be edited after the process has started. However, if the call has already been made, the invitee will not receive another call with the new content.<br> The updated content will only be used in the next round of calls.',
  question_about_sending_time_calls: 'At what times are the calls made?',
  answer_for_sending_time_calls:
    'The calls are made during operating hours: Sunday-Thursday between 9:00-21:00, and on Fridays between 9:00-15:00.',

  // system
  question_about_how_add_invitees: 'What is the easiest and quickest way to add invitees to the guest list?',
  answer_for_how_add_invitees:
    "Invitees can be added in several ways:<br> The first is manually through the 'Add Invitee' button.<br>The other two methods are via clicking on the 'Import Invitees' button.<br>The second method is through your Google account if your contacts are stored there. Click on 'Import from Google,' select all the contacts you wish to add, and set their desired settings. After pressing the 'Confirm and Import' button, you're done; all the invitees will appear in a table. <br> Note that when importing contacts from your Google account, the number of invitees for each invitee is set to 1 by default. Therefore, if you wish to change the number of invitees, you must do so manually by clicking on the invitee in the invitee table. <br>The third way to import invitees is from Excel. Click on 'Import from Excel,' choose the file of the invitees you want to add. The file must include a column for the invitee's name, phone number, and number of invitees. For each field like 'Full Name,' 'Phone Number,' and 'Number of Invitees,'you need to select the corresponding column (as you have named it in your file).",
  question_about_how_customize_invitees_settings: 'Is it possible to adjust personal settings for each invitee?',
  answer_for_how_customize_invitees_settings:
    'In the event settings, you can choose the languages relevant to the event and what specific questions you want to ask the invitees. Then in the "Guest list" tab you can choose for each guest in which language you wish to contact him and whether to ask about special dishes and/or transportation individually.',
  question_about_export_to_excel: 'Is it possible to export the table of guests to Excel?',
  answer_for_export_to_excel:
    'Absolutely! You can easily and quickly export the guest list by clicking the "Export" button located on the "Guest List" tab.',
  question_about_bulk_edit_invitees: 'Is it possible to edit bulk guests at once?',
  answer_for_bulk_edit_invitees:
    'Yes, you can edit multiple invitees at once by selecting them in the guest table and clicking the "Edit" button.',
  question_about_edit_invitee_status_manually:
    'My guest informed me privately about their arrival status, how can I update this information in the guest list?',
  answer_for_edit_invitee_status_manually:
    'To update an invitee\'s arrival status, you can manually click on the invitee\'s name in the "Guest List" tab and make the necessary changes. Next, go to the "Guest response" tab to update the invitee\'s response.<br> Alternatively, you can select the invitee in the "Guest List" tab, and then click the "Edit" button to make the necessary changes.',
  question_about_rsvp_status: 'Is it possible to see the RSVP status?',
  answer_for_rsvp_status:
    'Sure, you can see the status of your RSVP\'S in the "Guest List" tab under the "Progress" column. The icon in the column indicates the type of action, and the number above the icon shows the number of messages or calls that have been sent to the invitee.',
  question_about_edit_start_rsvp: "Is it possible to set the start date for RSVP's?",
  answer_for_edit_start_rsvp:
    'Yes, you can choose the RSVP start date on the payment page.<br> However, please be aware that the RSVP process usually takes several days, so we recommend initiating it at least two weeks before the event.',

  // payment
  question_about_update_package: 'Is it possible to upgrade an existing package?',
  answer_for_update_package:
    'Yes. We allow upgrading an existing package in the "My Profile" tab, as long as the process does not end.<br> You can upgrade an existing package and increase the number of invitees as needed.',
  question_about_coupon_use: 'I have a coupon code, how can I redeem it? ',
  answer_for_coupon_use:
    'The coupon can be redeemed at the time of payment, the coupon code must be entered in the designated area and press the "Apply" key, after which the price will be updated.',
}
