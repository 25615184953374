<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <slot></slot>

    <!-- Slot: Navbar -->
    <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
      <div
        class="navbar-content-container"
      >
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon>
          <v-spacer></v-spacer>
        </div>
        <div
          v-if="$vuetify.breakpoint.mdAndUp"
          class="d-flex justify-center align-center text-center flex-grow-1"
        >
          <ButtonsNav></ButtonsNav>
        </div>
        <v-spacer v-if="!$vuetify.breakpoint.mdAndUp"></v-spacer>

        <!-- Left Content: I18n, Notification & User Dropdown -->
        <div class="d-flex align-center right-row justify-end">
          <div class="mx-2">
            <app-bar-i18n></app-bar-i18n>
          </div>

          <user-notifications></user-notifications>
          <v-btn
            icon
            @click="$store.commit('setTourGuide', { value: true, type: '' })"
          >
            <!-- @click="$store.commit('setTourGuide', !$store.state.tourGuideShow)" -->

            <v-icon>{{ icons.mdiHumanGreeting }}</v-icon>
          </v-btn>
          <app-bar-user></app-bar-user>
        </div>
      </div>
    </template>

    <!-- Slot: Footer -->
    <template #footer>
      <v-card
        class="justify-center align-center rounded-lg py-2 ma-0"
        color="transparent"
        flat
      >
        <v-card-text
          class="align-end justify-end text-center pt-0"
          style="word-break: break-word"
        >
          <div class="mb-2">
            <a href="mailto:support@anita-lee.com">
              <v-avatar
                size="50"
                class="v-avatar-light-bg primary--text"
              >
                <v-icon
                  color="primary"
                  size="35"
                >
                  {{ icons.mdiEmailOutline }}
                </v-icon>
              </v-avatar>
            </a>
          </div>
          <div
            style="word-break: break-word"
            class="align-center justify-center text-center text-xl"
          >
            <span :class="$vuetify.breakpoint.smAndDown ? 'text-base font-weight-bold' : ''">
              {{ $t('NeedHelp') }}
            </span>
          </div>

          <div>
            <a
              class="text-lg"
              :href="`mailto:${contactUsEmail}`"
            >
              <span :class="$vuetify.breakpoint.smAndDown ? 'text-sm' : ''">
                {{ contactUsEmail }}
              </span>
            </a>
          </div>
        </v-card-text>
      </v-card>
      <div
        class="d-flex justify-center"
      >
        <span>COPYRIGHT &copy; {{ new Date().getFullYear() }} Anita-lee

          <span class="d-none d-md-inline">| All rights Reserved</span>
        </span>
      </div>
    </template>

    <template>
      <BuyPackage></BuyPackage>
    </template>
    <template
      v-if="!$vuetify.breakpoint.mdAndUp"
      #menu-navigation-buttons
    >
      <ButtonsNav class="mb-3"></ButtonsNav>
    </template>
  </layout-content-vertical-nav>
</template>

<script>
import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import ButtonsNav from '@/layouts/variants/botton-nav/ButtonsNav.vue'
import navMenuItems from '@/navigation/vertical'

// App Bar Components
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import {
  mdiAccountGroup,
  mdiCreditCardOutline,
  mdiEmailOutline,
  mdiHeartOutline,
  mdiHumanGreeting,
  mdiMenu,
  mdiMessageProcessingOutline,
  mdiPhoneOutline,
} from '@mdi/js'

// Search Data

import BuyPackage from '@/components/EventDetails/BuyPackage.vue'
import useContactUsDetails from '@/composables/useContactUsDetails'
import AppBarUser from '@/views/UserViews/AppBarUser.vue'
import UserNotifications from '@/views/UserViews/UserNotifications.vue'

export default {
  components: {
    LayoutContentVerticalNav,

    // App Bar Components
    AppBarI18n,
    AppBarUser,
    UserNotifications,
    BuyPackage,
    ButtonsNav,
  },
  setup() {
    const { contactUsEmail } = useContactUsDetails()

    const openTourGuide = () => {}

    return {
      navMenuItems,
      contactUsEmail,
      openTourGuide,

      icons: {
        mdiMenu,
        mdiHeartOutline,
        mdiAccountGroup,
        mdiCreditCardOutline,
        mdiMessageProcessingOutline,
        mdiPhoneOutline,
        mdiEmailOutline,
        mdiHumanGreeting,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  align-items: center;
  flex-grow: 1;
  position: relative;
}
@media (min-width: 960px) {
  .navbar-content-container {
    justify-content: center !important;
  }
}
</style>
