// axios
import { i18n } from '@/plugins/i18n/index'
import router from '@/router'
import axios from 'axios'
import Vue from 'vue'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_LOCAL_HOST,
  timeout: 300000,

  headers: { 'ngrok-skip-browser-warning': 'true' },
})

console.log('baseURL:', process.env.VUE_APP_LOCAL_HOST)

axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent

    const accessToken = localStorage.getItem('accessToken')

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    // eslint-disable-next-line no-param-reassign
    config.headers.Platform = window.ReactNativePlatform

    return config
  },
  error => Promise.resolve(error),
)

axiosIns.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 429) {
      Vue.$toast.error(i18n.t('errorManageEvents'))
    }
    if (error.response.status === 401) {
      console.log('error.response', error.response)

      if (error.response?.data?.message === 'verify_email') {
        router.push({ name: 'send-verification-email' })

        return Promise.resolve()
      }
      localStorage.removeItem('accessToken')
      localStorage.removeItem('userData')
      localStorage.removeItem('userAbility')
      router.push({ name: 'auth-login' })
    }

    return Promise.reject(error)
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
