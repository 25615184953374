import userStoreModule from '@/views/apps/user/userStoreModule'
import axios from '@axios'
import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'

import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    eventData: {},
    groupsData: [],
    tourGuideShow: {
      value: JSON.parse(localStorage.getItem('tourGuideShowValue')) || false,
      type: localStorage.getItem('tourGuideShowType') || null,
    },
    timingData: [],
    packages: {},
    activeCall: false,
    guestStatsData: {},
  },
  mutations: {
    setEventData(state, data) {
      state.eventData = { ...data }
      state.groupsData = [...data.groups]
    },
    setTimingData(state, data) {
      state.timingData = data
    },
    setTourGuide(state, payload) {
      state.tourGuideShow = { value: payload.value, type: payload.type }
      localStorage.setItem('tourGuideShowValue', JSON.stringify(payload.value))
      localStorage.setItem('tourGuideShowType', payload.type)
    },
    setCallsPreference(state, data) {
      state.eventData.includeCallsPreference = data
      this.commit('setEventData', state.eventData)
    },
    setPackages(state, data) {
      state.packages = data
    },
    setActiveCall(state, value) {
      state.activeCall = value
    },
    setGuestStatsData(state, value) {
      state.guestStatsData = value
    },
  },
  actions: {
    newEvent(ctx, eventData) {
      return new Promise((resolve, reject) => {
        axios
          .post('event/newEvent', eventData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => {
            ctx.commit('setTourGuide', { value: true, type: 'newEvent' })
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    editEvent(ctx, { id, eventData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`event/editEvent/${id}`, eventData)
          .then(response => {
            resolve(response)
            ctx.dispatch('fetchEvent', { id })
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getMeals() {
      return new Promise((resolve, reject) => {
        axios
          .get('meals/all')
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchEvents() {
      return new Promise((resolve, reject) => {
        axios
          .get('event/fetchEvents')
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`event/${id}`)
          .then(response => {
            ctx.commit('setEventData', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`event/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    createNewGuest(ctx, { id, guestData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`guests/new/${id}`, { ...guestData })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    editGuest(ctx, { id, guestID, guestData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`guests/edit/${id}/${guestID}`, { ...guestData })
          .then(response => {
            ctx.dispatch('fetchEvent', { id })
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchGroups(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`groups/fetch/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    createNewGroup(ctx, { id, name }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`groups/new/${id}`, { name })
          .then(response => {
            ctx.dispatch('fetchEvent', { id })
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    editGroup(ctx, { id, groupID, name }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`groups/${id}/${groupID}`, { name })
          .then(response => {
            ctx.dispatch('fetchEvent', { id })
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteGroup(ctx, { id, guestID }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`groups/${id}/${guestID}`)
          .then(response => {
            ctx.dispatch('fetchEvent', { id })
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getMessageTemplate(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`messageTemplate/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteMessageTemplate(ctx, { id, lang }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`messageTemplate/delete/${id}/${lang}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    editMessageTemplate(ctx, { id, msgData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`messageTemplate/edit/${id}`, { ...msgData })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getVoiceMessagesTemplate(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`voiceTemplate/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    editVoiceMessagesTemplate(ctx, { id, callTemplate }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`voiceTemplate/edit/${id}`, { ...callTemplate })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    textToSpeech(ctx, { id, callTemplate }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`text-to-speech/${id}`, { ...callTemplate })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getInvite(ctx, { guestID }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`invite/${guestID}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    wrongNumberComment(ctx, { guestID }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`invite/wrongNumber/${guestID}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    postInvite(ctx, { guestID, responseData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`invite/${guestID}`, { ...responseData })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateInvitationDate(ctx, { id, date }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`event/${id}/updateInvitationDate`, { date })
          .then(response => {
            ctx.dispatch('fetchEvent', { id })
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    eventStatisticsDetails(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`stats/${id}`)
          .then(response => {
            const filterKey = ctx.state.eventData.progressStatus > 0 ? 'pending' : 'inProgress'
            const { [filterKey]: _, changedDecision, ...filteredStats } = response.data.guestStats
            ctx.commit('setGuestStatsData', filteredStats)

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getPackages(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`packages/${id}`)
          .then(response => {
            ctx.commit('setPackages', response.data)
            ctx.dispatch('fetchEvent', { id })
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getUserOrders() {
      return new Promise((resolve, reject) => {
        axios
          .get('orders')
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    verifyEmail(ctx, { token }) {
      return new Promise((resolve, reject) => {
        axios
          .post('auth/verifyEmail', { token })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    resendEmailVerification(ctx, { lang }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`auth/resendVerificationEmail/${lang}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    resetPassword(ctx, { token, password }) {
      return new Promise((resolve, reject) => {
        axios
          .post('auth/resetPassword', { token, password })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    sendResetPassword(ctx, { email, lang }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`auth/sendPasswordReset/${lang}`, { email })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchNotifications() {
      return new Promise((resolve, reject) => {
        axios
          .get('notifications')
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getEventExpenses(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`expenses/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateEventExpenses(ctx, { id, expenses }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`expenses/${id}`, { expenses })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    exportDataToExcel(ctx, { id, lang }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`expense/export/${id}/${lang}`, { responseType: 'blob' })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteImage(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`event/deleteImage/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    nonRemovableLang(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`event/non-removable-languages/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchTiming(ctx, { id, data = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`actionTiming/${id}`, { ...data })
          .then(response => {
            ctx.commit('setTimingData', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    packagePreference(ctx, { includeCallsPreference, id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`event/includeCallsPreference/${id}`, { includeCalls: includeCallsPreference })
          .then(response => {
            resolve(response)
            ctx.commit('setCallsPreference', includeCallsPreference)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
  modules: {
    appConfig: appConfigStoreModule,
    app,
    'app-user': userStoreModule,
  },
})
