export default {
  MyEvents: 'Мои мероприятия',
  MyProfile: 'Мой профиль',
  ContactUS: 'Связаться с нами',
  CallTemplate: 'Шаблон разговора',
  NewEvent: 'Новое мероприятие',
  NewGroup: 'Создать группу',
  GroupName: 'Название группы',
  EditGroupName: 'изменить название группы',
  NumberGroupMember: 'Участники группы',
  Edit: 'Изменить',
  EditingInvitees: 'Редактирование',
  WhoAttending: 'Сколько приедет?',
  AttendingPerInvitee: 'Введите количество участников для каждой записи',
  AddNewInvitee: 'Добавить гостя',
  GuestDetails: 'Данные гостя',
  GuestAnswer: 'Ответы гостя',
  fullName: 'Ф.И.',
  phoneNumber: 'Номер телефона',
  countOfInvites: 'Количество приглашенных',
  NumberGuestAnswer: 'Прибывают',
  outOf: 'из',
  Progress: 'Динамика',
  Export: 'Экспорт в Excel',
  Delete: 'Удалить',
  Saving: 'Сохранить',
  DeleteLang: 'Удалить язык',
  GeneralSettings: 'Общие настройки',
  eventSettings: 'Детали мероприятия',
  AdvancedSettings: 'Расширенные настройки',
  Personalize: 'Персонализируйте',
  Terms: 'Условиями',
  PrivacyTerms: 'Политика Конфиденциальности',
  EventName: 'Название мероприятия',
  EventNamePerLang: 'Название мероприятия на ',
  ExampleEventName: 'Свадьба Хаима и Ади',
  EventLocationName: 'Место мероприятия (для приглашения)',
  EventLocationGoogle: 'Выберите место мероприятия для ссылки Waze',
  EventDate: 'Дата мероприятия',
  Date: 'Дата',
  EventTime: 'Время мероприятия',
  Add: 'Добавить',
  Cancel: 'Отменить',
  Ok: 'Ok',
  Send: 'Отправить',
  Close: 'Закрыть',
  UploadInvitation: 'Загрузить',
  InvitationPreview: 'приглашение',
  ContactInvitees: 'Общение с гостями',
  DefaultLanguage: 'Главный язык',
  EventLanguages: 'Языки мероприятия',
  EditInvitation: 'Редактирование',
  Language: 'Язык',
  MainLanguage: 'Русский',
  hebrew: 'Иврит',
  english: 'Английский',
  russian: 'Русский',
  arabic: 'Арабский',
  he: 'Иврит',
  en: 'Английский',
  ru: 'Русский',
  ar: 'Арабский',
  work_days: 'Воскресенье-Четверг',
  all_days: 'Все дни недели',
  specialMeals: 'Спец. блюда',
  ChooseSpecialMeals: 'Выберите блюда',
  no_special_dish: 'Обычные блюда',
  glatt: 'Глат кошер',
  vegetarian: 'Вегетарианское',
  gluten_free: 'Не содержит глютен',
  vegan: 'Веганское',
  no_need_to_ask: 'Не спрашивать',
  some_guests: 'Спросить некоторых гостей',
  all_guests: 'Спросить всех гостей',
  Transport: 'Подвозка',
  Meal: 'блюдо',
  GetPlacesName: 'Введите название города',
  AddName: 'ENTER - Добавить',
  CreateOne: 'Добавить вариант',
  ClassGroup: 'Группа',
  Previous: 'Назад',
  Next: 'Далее',
  Finish: 'Финиш',
  FieldRequired: 'Обязательное поле',
  RequireNum: 'Должно быть число',
  MaxField: 'Это поле содержит не более {num} символов',
  MinField: 'Это поле содержит минимум {num} символа',
  WelcomeToAnitaLee: 'Добро пожаловать!',
  HappyToBePartOfYourEvent: 'Рады помочь вам в организации вашего мероприятия',
  PleaseSignUp: 'Создайте ваш аккаунт',
  YourEventComingUp: 'Ваше мероприятие приближается',
  CreateAccount: 'Регистрация',
  YourEventYourControl: 'Ваше мероприятие - ваш контроль! Зарегистрируйтесь сейчас',
  IAgree: 'Я согласен с',
  AlreadyHaveAccount: 'Уже есть аккаунт?',
  AccountExist: '*Аккаунт уже существует',
  Or: 'или',
  FirstEvent: 'Ваше первое мероприятие?',
  Register: 'Регистрация',
  ConfirmPasswordNotMatch: 'Пароли не совпадают',
  NewPassword: 'Новый пароль',
  Password: 'Пароль',
  ConfirmPassword: 'Подтверждение пароля',
  ChangePassword: 'Изменить пароль',
  ForgotPassword: 'Забыли пароль?',
  PasswordChangeSuccessful: 'Пароль успешно изменен',
  ForgotPasswordMsg:
    'Введите адрес электронной почты, который вы использовали при регистрации, и мы вышлем вам инструкции по сбросу пароля.',
  BackToLogin: 'Вернуться к подключению',
  Login: 'Логин',
  Logout: 'Выйти',
  PasswordGuide: [
    'Выберите пароль от 4 до 20 символов',
    'Хотя бы одна цифра (0-9) или один специальный символ (!،@..)',
    'Хотя бы одна заглавная буква (A-Z)',
    'Хотя бы одна строчная буква (a-z)',
  ],
  WeakPassword: 'Слабый пароль',
  NotValidEmail: 'Неверный адрес электронной почты',
  errorNewEvent: 'Не удалось создать мероприятие. Повторите попытку позже',
  errorManageEvents: 'Что-то пошло не так. Пожалуйста, повторите попытку позже',
  errorNewGroup: 'Это название группы уже существует',
  invalid_guests_coming: 'Количество респондентов больше, чем количество приглашенных',
  search: 'Поиск',
  advancedFilter: 'Фильтр гостей',
  filter: 'фильтр',
  status: 'Статус',
  delete: 'Удалить',
  inProgress: 'В процессе',
  approved: 'Приходят',
  declined: 'Не приходят',
  Yes: 'Да',
  No: 'Нет',
  Navigation: 'Навигация',
  AddToCalendar: 'Добавить в календарь',
  wrongNumber: 'Неверный номер',
  pending: 'В ожидании',
  viewCount: 'Просмотрели',
  changedDecision: 'Передумали',
  ifArriveQA: 'Подтверждаете прибытие?',
  AmountPeople: 'Количество людей',
  comments: 'Комментарии',
  messageBeforeDelete: 'Вы уверены, что хотите удалить?',
  oops: 'Упс...',
  cantDeleteGuestsInProcess: 'Не удалось удалить приглашенных, для которых мы уже начали процесс',
  cantDeletePaidEvent: 'Невозможно удалить оплаченное мероприятия',
  messageBeforeStartProccess: 'Вы уверены, что хотите запустить процесс для',
  messageForNumberOfGroups: 'на мероприятии',
  ActiveOrders: 'Активные заказы',
  FinishedOrders: 'Закрыты заказы',
  Details: 'Детали',
  Invitees: 'гостей',
  RecentResponse: 'Недавно ответили',
  StartDateRsvp: 'Дата начала заявок',
  StartDateTip: 'Наш совет - 14 дней до мероприятия',
  EventStatus: 'Статус мероприятия',
  TypeOfOrderPackage: 'Заказанный пакет',
  OurPackages: 'Наши пакеты',
  ExplainAboutThePackages: 'Все наши пакеты включают систему управления гостями и персонализацию',
  GuideMessageSelectPackage: 'Пожалуйста, выберите тип сообщения SMS/Whatsapp и количество приглашенных',
  ViewAll: 'Посмотреть всё',
  ChangedDecision: 'Изменили решение',
  attendingByGroups: 'Прибытие по группам',
  Dashboards: 'Dashboards',
  Dashboard: 'Домашняя страница',
  GuestsList: 'Список гостей',
  Email: 'Адрес E-mail',
  incorrectIdentificationDetails: 'Введена неверная идентификационная информация',
  Groups: 'групп',
  ManageGroups: 'Менеджер групп',
  AdvantageGroupsManagement: [
    'Oрганизация гостей',
    'Статистика по группам',
    'Быстрый поиск гостей',
    'Оптимизация процесса',
  ],
  CallTemplatesExplain: [
    'Мы подготовили для вас шаблоны разговоров на основе вашей информации.',
    'Вы можете отредактировать шаблоны разговоров.',
  ],
  ListenCall: 'Чтобы прослушать разговор, нажмите ',
  MessageTemplatesExplain: [
    'Мы подготовили для вас шаблоны сообщений на основе вашей информации.',
    'Вы можете редактировать сообщения на любом языке.',
  ],
  InvitationPreviewExp: 'Персонализированная ссылка на приглашение для каждого гостя ниже каждого сообщения.',
  ViewInvitation: 'Посмотреть приглашение',
  MessageTemplates: 'Шаблон сообщений',
  SettingEvent: 'Настройки',
  MessageTemplate: 'шаблон сообщения',
  CallTemplates: 'шаблон звонков',
  MyOrders: 'Мои заказы',
  MyAccount: 'Мой аккаунт',
  Notifications: 'Уведомления',
  Today: 'Сегодня',
  Yesterday: 'Вчера',
  basic_package: 'базовый',
  premium_package: 'Премиум',
  basic_desc: 'Только сообщения',
  premium_desc: 'Сообщения и звонки',
  basic_package_include_sms: [
    'Управление гостями',
    'Управление расходами',
    'Дигитальная приглашение',
    'Редактирования сообщений',
    '3 раунда подтверждения прибытия по SMS',
    'SMS с навигацией в день мероприятия',
    'SMS с благодарностью на следующий день',
  ],
  basic_package_include_whatsapp: [
    'Управление гостями',
    'Управление расходами',
    'Дигитальная приглашение',
    'Редактирования сообщений',
    '3 раунда подтверждения прибытия по WhatsApp',
    'SMS с навигацией в день мероприятия',
    'SMS с благодарностью на следующий день',
  ],
  basic_package_not_include: ['Настраиваемый телефонный звонок', '3 раунда автоматических звонков'],
  premium_package_include_sms: [
    'Управление гостями',
    'Управление расходами',
    'Дигитальная приглашение',
    'Настраиваемые сообщения',
    '3 раунда подтверждения прибытия по SMS',
    'SMS с навигацией в день мероприятия',
    'SMS с благодарностью на следующий день',
    'Настраиваемый телефонный звонок',
    '3 раунда автоматических звонков',
  ],
  premium_package_include_whatsapp: [
    'Управление гостями',
    'Управление расходами',
    'Дигитальная приглашение',
    'Настраиваемые сообщения',
    '3 раунда подтверждения прибытия по WhatsApp',
    'SMS с навигацией в день мероприятия',
    'SMS с благодарностью на следующий день',
    'Настраиваемый телефонный звонок',
    '3 раунда автоматических звонков',
  ],
  premium_package_not_include: [],
  ActivePackage: 'On',
  Active: 'Активный',
  NotActive: 'Не активен',
  For: 'для',
  Total: 'общий',
  TotalPaid: 'Итого:',
  ExistingPackageAmount: 'Цена вашего пакета:',
  VerifyEmail: 'Подтвердите ваш электронный почты',
  NotReceiveEmailQA: 'Не получили электронное письмо?',
  Resend: 'Переотправить',
  ChooseGuestAmount: 'Выберите количество гостей',
  AddLang: 'Добавить язык',
  BuyPackage: 'Купить пакет',
  UpgradePackage: 'Обновление пакета',
  FullyUsedPackage: 'Пакет полностью использован',
  CantAddInvitee: 'Невозможно добавить дополнительных гостей',
  GroupsClassificationChart: 'Классификация групп',
  NoData: 'Нет данных',
  NoHaveInvitees: 'Нет гостей',
  noNotifications: 'Нет уведомлений',
  OverView: 'Обзор',
  EventDetails: 'Данные мероприятия',
  EventDetailsMessage: 'Количество гостей: ',
  invitationMessage: 'Подтвер.',
  call: 'Автозвонок',
  NotAnswerMessage: 'Не подтвердили',
  DirectionMessage: 'Напоминание',
  ThanksMsg: 'благодарность',
  limitCountOfInvitesMessage: 'Количество приглашенных меньше, чем количество респондентов',
  HappyToSeeYou: 'будем рады видеть вас',
  ResponseSuccess: 'Получено, спасибо!',
  Management: 'Управление',
  Settings: 'Настройки',
  Support: 'поддержка',
  AttendingNotZero: 'Количество посещающих недействительно',
  PhoneNumExist: 'Номер телефона уже существует',
  importContacts: 'Добавить контакты',
  ImportGuests: 'Импорт гостей',
  submitImportContacts: 'Подтвердить',
  UploadExcelFile: 'Импорт Excel',
  RegularExcelTemplate: 'Обычный Excel',
  IplanExcelTemplate: 'Excel для Iplan',
  importContactsSettingsDesc: 'Выберите настройки, которые будут применяться к выбранным приглашенным гостям',
  AskTransportation: 'Спросить о подвозке?',
  AskSpecialMeals: 'Спросить о спец. блюдах?',
  pleaseWait: 'Пожалуйста подождите',
  errorFetchingGoogleContacts: 'Не удалось импортировать контакты. Повторите попытку позже.',
  thanksForChoosingUs: 'Спасибо что выбрали нас',
  createYourExcitingEvent: 'Создайте свое незабываемое мероприятия здесь!',
  WhatsAppMessages: 'приглашение на WhatsApp',
  SmsMessages: 'приглашение на SMS',
  Reminder: 'Напоминательные сообщения',
  ThanksMessages: 'благодарственные сообщения',
  CallMessages: 'Телефонных звонков',
  sent: 'Отправлено',
  expensesDescription: 'Название расхода',
  supplierName: 'Имя поставщика',
  totalPayment: 'К оплате',
  paidAmount: 'Оплачено',
  leftToPay: 'Не заплачено',
  eventExpenses: 'Мои расходы',
  requiredTerms: '*Подтвердить условия',
  InvitationToEvent: 'Приглашение',
  invitationUploadOption: 'загрузить свое приглашение на мероприятие(возможно позже)',
  page_not_found: 'Страница не найдена',
  back_to_home: 'Вернуться на главную',
  sent_to_email: 'Мы отправили на ваш адрес электронной почты:',
  verification_link: 'ссылку для подтверждения.',
  please_verify_email:
    'Пожалуйста, подтвердите свой адрес электронной почты, перейдя по ссылке, которую мы отправили вам.',
  invalidPhoneNumber: 'Неправильный номер',
  PhoneNumberDuplicate: 'Дублированный номер телефона',
  maxNumOfGroup: 'Максимальное количество групп - 10',
  package_price: 'Цена пакета: ',
  ForRsvp: 'Подтверждение прибытия: ',
  ForDirection: 'Навигация: ',
  invalid_coupon: 'Неверный код купона',
  payment_method_credit_card: 'Кредитка',
  payment_method_bit: 'Через Bit',
  Order: 'Заказ',
  Confirmation: 'Подтверждение',
  How_pay: 'Выберите форму оплаты',
  EnterPromoCode: 'Введите промо-код',
  Apply: 'Применить',
  OrderDetails: 'Детали заказа',
  TotalForPay: 'Итого',
  Payment: 'Оплата',
  Payment_completed_Successfully: 'Оплата успешно завершена!',
  Thank_you_for_your_purchase: 'Спасибо, что выбрали нас! Номер подтверждения вашего заказа: ',
  Go_To_Event_Page: 'На страницу мероприятия',
  bad_date: 'Дата мероприятия слишком близка',
  wrong_package: 'Выбранный пакет не существует',
  payment_failed: 'Ошибка оплаты',
  payment: 'Оплата',
  agree_invitation_start_date: 'Я подтверждаю дату начала подтверждение прибытия: ',
  agree_message_templates: 'Я подтверждаю шаблоны сообщений, которые будут отправлены гостям на языках: ',
  agree_call_templates: 'Я подтверждаю шаблоны звонков, которые будут сделаны гостям на языках: ',
  confirm_all_fields: '* Пожалуйста, подтвердите все поля',
  must_choose_from_google_location: 'Пожалуйста, выберите место мероприятия из списка',
  eventDateInPast: 'Дата мероприятия уже прошла',
  CouponDiscount: 'Скидка',
  orderNumber: 'Номер заказа: ',
  GroupsNav: 'Группы',
  GuestNav: 'Гости',
  Messages: 'Сооб.',
  Calls: 'Звонки',
  PaymentNav: 'Оплата',
  navTiming: 'Отправка',
  ImportContactsViaGoogle: 'Импорт Google',
  CreateGroupsNav: 'Создайте группы для вашего мероприятия',
  SelectTimingNav: 'Установите время отправки для подтверждений прибытия',
  AddGuestsNav: 'Добавьте своих приглашенных',
  EditMessagesNav: 'Настройте шаблон сообщений',
  EditCallsNav: 'Настройте шаблон звонков',
  ChoosePackageNav: 'Приобретите наиболее подходящий пакет для вашего мероприятия',
  Attending: 'Прибывают',
  NotAttending: 'Не прибывают',
  PlanChanged: 'Планы изменились?',
  WeAreSorry: 'Мы извиняемся',
  WeUpdateWrongNumberInSystem: 'Спасибо за уточнение',
  YouChoseWhatsAppMessage: 'Вы выбрали пакет с сообщениями WhatsApp',
  WhatsAppMessageWarning:
    'Обратите внимание, что если возникнет проблема с отправкой сообщения WhatsApp конкретному  гостю , не зависящим от нас, то вместо сообщения WhatsApp будет отправлено SMS-сообщение.',
  SortBy: 'Сортировать по',
  addToGroup: 'Добавить в группу',
  countOfInvitesMustBeUntil10: 'Макс. количество - 10',
  countOfInvitesBetween1to10: 'Мин. количество - 1, макс. - 10',
  ImportYourInviteeFromExcel: 'Импортировать приглашенных из Excel',
  ChooseForEachColumnFromExcel: 'Выберите для каждого поля соответствующее название столбика из вашего Excel',
  recognizeErrorsFromExcel: 'Мы обнаружили проблему с импортом приглашённых',
  moreInfoAboutErrors: 'Для получения дополнительной информации об ошибках нажмите на',
  fixTheExcelErrors: 'Пожалуйста, исправьте ошибки и повторно импортируйте файл',
  SuccessfullyAdded: 'Успешно добавлено ',
  SuccessfullyAddedOneGuest: 'успешно добавлен',
  InvalidFullNameFormatColumn: 'Выбранная столбик с именами приглашённых не соответствует формату',
  isValidFormatPhoneColumn: 'Выбранная столбик с номерами телефонов не соответствует формату',
  isValidFormatCountOfInviteeColumn: 'Выбранный столбик с количеством приглашённых не соответствует формату',
  ClearAll: 'Очистить',
  AllInviteeAlreadyExist: 'Все приглашенные из файла уже добавлены',
  InvalidFileType: 'Неверный файл, пожалуйста, загрузите файл Excel типа xlsx',
  InvalidFile: 'Неверный файл',
  NeedHelp: 'Нужна помощь?',
  StartRsvpDataInPast: 'Дата начала подтверждения прибытия не может быть в прошлом',
  AddInvitees: 'Добавьте гостей',
  NotEnoughData: 'Недостаточно данных',
  AssignGroupsToInvitees: 'Добавить группы к гостям',
  CreateGroups: 'Создать группы',
  NoDataYet: 'Данных пока нет',
  viewedInvitation: 'Увидели приглашение',
  notViewedInvitation: 'Не увидели приглашение',
  FileIsEmpty: 'Файл пустой',
  PhoneNumberNotChanged: 'Номер телефона не изменен. Чтобы продолжить, измените номер телефона',
  EditPhoneNumber: 'Редактирование но. телефона',
  ChangedYourMind: 'Вы передумали?',
  FileMustHaveTitles: 'Файл должен содержать заголовки',
  NavigationByWaze: 'Через Waze',
  NavigationByGoogleMaps: 'Через Google Maps',
  partiallyComing: 'Частично прибывает',
  actionTiming: 'Время отправки',
  sameDay: 'В день мероприятия',
  dayBefore: 'За день до мероприятия',
  twoDaysBefore: 'За два дня до мероприятия',
  dayAfter: 'На следующий день после мероприятия',
  twoDaysAfter: 'Через два дня после мероприятия',
  daysBeforeEvent: 'Дни до мероприятия',
  daysAfterEvent: 'Дни после мероприятия',
  startFrom: 'Начало с ',
  activityDays: ['С воскресенья по четверг 09:00-20:30', 'Пятница 09:00-14:30'],
  selectActivityDays: 'Дни активности службы, которые можно выбрать:',
  Agree: 'Согласен',
  WhatsAppMessageTemplateExplain: 'Обратите внимание, первое сообщение, отправленное в WhatsApp, будет следующим: ',
  WhatsAppTemplate: `Привет😊
Вы приглашены на мероприятие, {eventName}.
Чтобы получить приглашение, где вы можете подтвердить свое прибытие, нажмите на кнопку ниже "Получить приглашение" ⬇

*Отправлено от Anita Lee, система подтверждения прибытия на мероприятие.`,

  AfterWhatsAppButtonClick:
    'После того как кнопка "Получить приглашение" будет нажата, приглашенный получит ответное сообщение, определенное в шаблоне сообщений (редактируемом), со ссылкой на страницу приглашения на мероприятие, где он сможет подтвердить свое прибытие.',
  edit_details_in_event_settings: 'Вы можете редактировать детали в настройках мероприятие',
  edit_messages_in_all_languages:
    'Вы можете редактировать сообщения на всех языках, которые вы выбрали в шаблоне сообщений',
  digital_invitation_is_ready: 'Мы подготовили для вас цифровое приглашение на мероприятие...',
  whenTimingWillSending: 'Когда это будет отправлено?',
  round: 'раунд ',
  NoHaveClosestAction: 'Мероприятие завершилось',
  closestAction: 'Ближайший тайминг',
  upgrade: 'Обновление',
  edit_details_in_message_template: 'Вы можете редактировать содержимое в шаблоне сообщений',
  how_messages_look_to_you: 'Как вам эти сообщения?',
  we_set_when_each_round_will_sent: 'Мы определили, когда будет отправлен каждый раунд',
  edit_timing: 'Вы можете редактировать тайминги позже',
  starting_in_just_a_moment: 'Это скоро начнется...',
  we_prepare_everything_you_need: 'Мы подготовили почти всё, что вам нужно,',
  lets_see: 'Просмотрите',
  AddManually: 'Добавить вручную',
  we_do_almost_all_job: 'Кажется, мы сделали почти всю работу',
  you_need_only_add_guests: '- Вам осталось только добавить гостей',
  PleaseNote: 'Пожалуйста, обратите внимание',
  TimingCanChange: 'Время отправки подтверждение прибытия может измениться',
  TemplatesNeedManualEdit: 'Детали изменений в шаблонах сообщений/звонков нужно обновить вручную',
  DateOrTimeChanged: 'Дата и/или время мероприятия были изменены',
  only_messages: 'Только сообщения',
  calls_with_messages: 'Сообщения и звонки',
  interests_package: 'Пакет, который меня интересует, включает...',
  add_guests_later: 'добавить гостей позже',
  Calling: 'Звонит...',
  Accept: 'Ответить',
  Decline: 'Отклонить',
  how_call_listening: 'Мы также подумали как будут звучать звонки...',
  edit_call_in_all_languages:
    'Вы можете редактировать вводную часть звонка на всех языках, которые вы выбрали в шаблоне звонков',
  edit_details_in_call_template: 'Вы можете редактировать вводную часть звонка в шаблоне звонков',
  invitation_page_loading_text: 'Особое приглашение уже в пути к вам...',
  googleSignInButton: 'Войти через Google',
  paymentNotAllowedInWebView: 'Оплата доступна только на сайте',
  pleaseUseWebsiteForPayment: 'Пожалуйста, используйте сайт для завершения оплаты.',
  continueToWebsite: 'Перейти на сайт',
}
