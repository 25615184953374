export default {
  willHappyToHelpYou: 'נשמח לעזור לכם!',
  notFindAnswerForQa: 'לא מצאתם תשובה לשאלתכם?',
  sendUsEmail: 'צרו קשר',
  stillNeedHelp: 'לא הסתדרתם? דברו איתנו!',
  questionsAndAnswers: 'שאלות/תשובות בנושא',

  // tabs
  messages: 'הודעות',
  calls: 'שיחות',
  system: 'מערכת',
  payment: 'תשלום',

  // messages
  question_about_msg_flow: 'איך מתבצע תהליך שליחת ההודעות?',
  answer_for_msg_flow:
    'המערכת שולחת למוזמנים שלכם את ההודעות אשר מוגדרות בתבנית ההודעות. כול מוזמן יקבל את ההודעה לפי ההגדרות שבחרתם לו (מנה, הסעה) והודעות אלו יוצגו בשפה שבחרתם לאותו מוזמן. <br> ההודעה הראשונית תשלח ביום שקבעתם שיתחילו אישורי ההגעה. <br> הודעת התזכורת והכוונה תשלח ביום האירוע רק למוזמנים אשר אישרו את הגעתם. <br> הודעת התודה תשלח יום למחרת האירוע ורק למוזמנים אשר אישרו את הגעתם.',
  question_about_edit_msg_content: 'האם ניתן לערוך את תוכן ההודעות?',
  answer_for_edit_msg_content:
    'כן, ניתן לערוך את תוכן ההודעות בלשונית "תבנית הודעות" בתפריט. לכול שפה יש מספר הודעות(הודעת אישור הגעה, הודעת הכוונה והודעת תודה). את כולן ניתן לערוך כרצונכם. ',
  question_about_visibility_msg: 'איך תראה ההודעה אצל המוזמן שלי?',
  answer_for_visibility_msg:
    'האופן שבו המוזמן יקבל את ההודעה יהיה לפי בחירתכם בחבילה שרכשתם (סמס/וואטסאפ), בתחתית ההודעה שערכתם בלשונית "תבנית הודעות" יצורף לינק ייחודי לכול מוזמן להזמנה של האירוע שלכם. את התצוגה של ההזמנה ניתן לראות בלשונית "הזמנה".  <br>השפה והשאלות שיישאלו בהזמנה הינם ייחודים לפי מה שבחרתם בהגדרות לכול מוזמן. ',
  question_about_edit_msg_content_after_start_proccess: 'האם ניתן לשנות את תוכן ההודעה לאחר שהתהליך התחיל?',
  answer_for_edit_edit_msg_content_after_start_proccess:
    'כן, ניתן לשנות את תוכן ההודעה לאחר שהתהליך התחיל, אך במידה וההודעה כבר נשלחה היא לא תשלח שוב. הנוסח החדש יעודכן בהודעות שטרם נשלחו.',
  question_about_sending_time_msg: 'באיזה שעות ההודעות נשלחות?',
  answer_for_sending_time_msg:
    "ההודעות נשלחות בשעות הפעילות: ימי א'-ה' בין השעות 9:00-21:00, ימי ו' בין השעות 9:00-15:00.",

  // calls
  question_about_calls_flow: 'איך מתבצע תהליך ההתקשרות עם המוזמנים שלנו?',
  answer_for_calls_flow:
    'השיחות הינן אוטומטיות ללא התערבות אנושית, ובכך מאפשרות לכם לפנות למוזמנים שלכם בכול שפה שתבחרו. <br>השיחה תתבצע במידה ורכשתם את חבילת הפרימיום הכוללת שיחות ובמידה והמוזמן טרם אישר הגעה בהודעות שנשלחו אליו. <br>השיחה תתבצע בשפה שהגדרתם לאותו מוזמן ובהתאם לתוכן הפתיח שהגדרתם לאותה שפה בלשונית "תבנית שיחות". ',
  question_about_edit_calls_content: 'האם ניתן לערוך את תוכן השיחה?',
  answer_for_edit_calls_content:
    'כן, במידה ורכשתם את חבילת הפרימיום, ניתן לערוך ולהאזין לתוכן השיחה בלשונית "תבנית שיחות" בתפריט. <br>לכול שפה יש תסריט שיחה, בכול שפה ניתן לערוך את התסריט כרצונכם. <br>ניתן לערוך את פתיח השיחה בלבד, המשך השיחה הינה בקשה להקשת מקשים ולא ניתן לעריכה. ',
  question_about_how_calls_listen: 'איך השיחה תשמע כשתתקשרו למוזמן?',
  answer_for_how_calls_listen:
    'החלק הראשון של השיחה הוא הפתיח שהגדרתם בלשונית "תבנית שיחות", אותו ישמע המוזמן כאשר יענה לשיחת הטלפון. <br>בהמשך, המוזמן יצטרך להקיש בלוח המקשים האם הוא מתכוון להגיע או לא. במידה ויבחר בכוונתו להגיע, המערכת תבקש להקיש את כמות המגיעים. במידה והמוזמן לא יקיש את בחירתו או לא יענה לשיחת טלפון, נמשיך להתקשר אליו בסבב הבא. <br>ברגע שהשיחה הסתיימה תוכלו להתעדכן בתשובותיו במערכת.',
  question_about_edit_calls_content_after_start_proccess: 'האם ניתן לשנות את תוכן השיחה לאחר שהתהליך התחיל?',
  answer_for_edit_edit_calls_content_after_start_proccess:
    'כן, ניתן לערוך את תוכן השיחה לאחר שהתהליך התחיל, אך אם השיחה כבר בוצעה, המוזמן לא יקבל שיחה נוספת עם התוכן החדש. שינוי התוכן יתעדכן רק בסבב השיחות הבא. ',
  question_about_sending_time_calls: 'באיזה שעות מתבצעות השיחות?',
  answer_for_sending_time_calls:
    "השיחות מתבצעות בשעות הפעילות: ימי א'-ה' בין השעות 9:00-21:00, ימי ו' בין השעות 9:00-15:00.",

  // system
  question_about_how_add_invitees: 'איך ניתן להוסיף מוזמנים לרשימת מוזמנים בקלות ובמהירות?',
  answer_for_how_add_invitees:
    "ניתן להוסיף מוזמנים בכמה דרכים: <br>הראשונה היא באופן ידני דרך הכפתור 'הוספת מוזמן'. <br>שתי הדרכים האחרות הן דרך לחיצה על כפתור 'ייבוא מוזמנים', <br>הדרך השנייה היא באמצעות חשבון הגוגל שלכם במידה ואנשי הקשר שלכם מאוחסנים בו, לוחצים על 'יבוא מגוגל' בוחרים את כל אנשי הקשר שרוצים להוסיף, מגדירים להם את ההגדרות הרצויות. לוחצים על הכפתור 'אישור וייבוא' וסיימתם, כל המוזמנים מופיעים בטבלה. <br>שימו לב, כי בעת יבוא אנשי קשר מחשבון הגוגל, מספר המוזמנים לכל מוזמן הינו 1, לכן אם ברצונכם לשנות את מספר המוזמנים יש לערוך זאת באופן ידני על ידי לחיצה על המוזמן בטבלת המוזמנים. <br>הדרך השלישית של ייבוא מוזמנים היא ייבוא מאקסל, בוחרים בכפתור 'ייבוא מאקסל', בוחרים את הקובץ של המוזמנים שאתם מעוניינים להוסיף ממנו, הקובץ חייב לכלול עמודה של שם המוזמן, מספר טלפון, ומספר מוזמנים. לכל שדה כמו 'שם מלא', 'מספר טלפון' ו'מספר מוזמנים' יש לבחור את העמודה המתאימה לו (זאת אומרת איך שאתם בחרתם לקרוא לה בקובץ שלכם)'.",
  question_about_how_customize_invitees_settings: 'איך ניתן להתאים לכול מוזמן הגדרות אישיות עבורו?',
  answer_for_how_customize_invitees_settings:
    'בהגדרות האירוע באפשרותכם לבחור את השפות הרלוונטיות לאירוע ואיזה שאלות ספציפיות ברצונכם לשאול את המוזמנים. לאחר מכן בלשונית "רשימת מוזמנים" באפשרותכם לבחור עבור כל מוזמן באיזו שפה ברצונכם לפנות אליו והאם לשאול לגבי מנות מיוחדות ו/או הסעות באופן פרטני. ',
  question_about_export_to_excel: 'האם ניתן לייצא את טבלת המוזמנים לאקסל?',
  answer_for_export_to_excel: 'בהחלט. באופן קל ומהיר על ידי לחיצה על כפתור "יצוא" בלשונית "רשימת מוזמנים".',
  question_about_bulk_edit_invitees: 'האם ניתן לערוך כמה מוזמנים בבת אחת?',
  answer_for_bulk_edit_invitees: 'כן. ניתן לערוך כמה מוזמנים יחד על ידי בחירתם בטבלת המוזמנים ולחיצה על כפתור "עריכה".',
  question_about_edit_invitee_status_manually:
    'המוזמן שלי עדכן אותי בסטטוס ההגעה שלו באופן פרטי, איך אפשר לעדכן זאת בטבלת המוזמנים?',
  answer_for_edit_invitee_status_manually:
    'ניתן לעדכן באופן ידני על ידי לחיצה על המוזמן בלשונית "רשימת מוזמנים". לאחר מכן יש לעדכן את תשובת המוזמן בלשונית "תשובות מוזמן". אפשרות נוספת הינה באמצעות בחירת המוזמן בלשונית "רשימת מוזמנים" ולחיצה על כפתור "עריכה".',
  question_about_rsvp_status: 'האם ניתן לראות באיזה שלב נמצא תהליך אישורי ההגעה? ',
  answer_for_rsvp_status:
    'בהחלט. ניתן לראות זאת בלשונית "רשימת מוזמנים" בעמודת "התקדמות" בטבלה. כאשר האייקון מסמל את סוג הפעולה, והמספר מעל האייקון מסמל את כמות ההודעות/שיחות שנשלחו למוזמן.',
  question_about_edit_start_rsvp: 'האם ניתן להחליט על תאריך התחלת אישורי ההגעה?',
  answer_for_edit_start_rsvp:
    'בהחלט. ניתן להחליט על תאריך תחילת אישורי ההגעה בעת התשלום כרצונכם.<br> שימו לב, תהליך אישורי ההגעה לוקח מספר ימים בהתאם לחבילה, לכן ההמלצה שלנו היא להתחיל את התהליך שבועיים מראש.',

  // payment
  question_about_update_package: 'האם יש אפשרות לשדרג חבילה קיימת?',
  answer_for_update_package:
    'כן. אנו מאפשרים לשדרג חבילה קיימת בלשונית "הפרופיל שלי", כול עוד התהליך אינו נגמר. ניתן לשדרג חבילה קיימת ולהגדיל את מספר הרשומות לפי הצורך.  ',
  question_about_coupon_use: 'יש לי קוד קופון, איך ניתן לממש אותו?',
  answer_for_coupon_use:
    'ניתן לממש את הקופון בעת התשלום, יש להכניס את הקוד קופון באזור המיועד לכך וללחוץ על מקש "החל", לאחר מכן המחיר יתעדכן.',
}
