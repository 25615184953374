export default {
  terms_title: 'سياسة الخصوصية',
  introduction: [
    {
      content: `
              <p>            
              مرحبًا بك في موقع Anita-lee (أدناه: <strong> "الموقع" </strong>)، الذي يقدم خدمات (أدناه: <strong>"الخدمات"</strong>) للأحداث، بما في ذلك نظام لإدارة الدعوات للحدث، وتأكيد الوصول، وإدارة النفقات. استخدام الموقع يعتبر موافقة على القواعد وسياسة الخصوصية هذه، وفقاً للشروط والقيود الواردة فيها. استخدام الموقع خاضع لسياسة الخصوصية المُحدثة الواردة فيه، ويشهد على موافقتك على سياسة الخصوصية والتغييرات فيها.
              </p>
              `,
    },
    {
      content: `
              <p>
              ما هو مدرج في سياسة الخصوصية هذه مكتوب باللغة الذكور للراحة فقط ويشير إلى كلا الجنسين بالتساوي.
              </p>`,
    },
  ],
  sections: [
    {
      title: `
              <p>
                  <strong>
                      1.<u>عام</u>
                  </strong>
              </p>`,

      content: `
              <p class="content-item">
                  <span class="number">
                      1.1. 
                  </span>
                  <span>
                    إدارة الموقع تحترم خصوصية جميع المستخدمين.
                  </span>
              </p>
              <p class="content-item">
                  <span class="number">
                        1.2. 
                  </span>
                  <span>
                    الهدف من سياسة الخصوصية هذه هو تقديم تفصيل حول المعلومات التي يتم جمعها عن المستخدمين بسبب استخدام الخدمات، وكذلك لمراجعة الطريقة التي تستخدم فيها إدارة الموقع المعلومات التي تم تقديمها والتي تم جمعها بسبب استخدام خدماتها.
                  </span>
              </p>
              <p class="content-item">
                  <span class="number">
                        1.3. 
                  </span>
                  <span>
                  شروط سياسة الخصوصية المفصلة أدناه هي جزء لا يتجزأ من قواعد الموقع، إدارة الموقع لها الحق في تغيير سياسة الخصوصية والقواعد من وقت لآخر، معا أو بشكل منفصل. ولذلك، يجب على المستخدم البقاء مُحدثا مع هذه الوثيقة في نسختها المُحدثة على الإنترنت.
                  </span>
              </p>
              <p class="content-item">
                  <span class="number">
                        1.4. 
                  </span>
                  <span>
                    خدمات الموقع مخصصة للاستخدام للمستخدمين الذين تزيد أعمارهم عن 18 عامًا.
                  </span>
              `,
    },
    {
      title: `
              <p>
                  <strong>
                      2.<u>جمع المعلومات</u>
                  </strong>
              </p>`,
      content: `
                <p class="content-item">
                    <span class="number">
                            2.1. 
                    </span>
                    <span>
                    الخدمات التي يقدمها الموقع تتطلب التسجيل، الذي يتضمن تقديم معلومات شخصية، مثل: الاسم الكامل وعنوان البريد الإلكتروني. تقديم المعلومات أثناء التسجيل يمكن أن يكون أيضًا عبر أطراف ثالثة من خلالهم يتم ملء التفاصيل، مثل تسجيل الدخول إلى Google.
                    </span>
                </p>
                <p class="content-item">
                    <span class="number">
                            2.2. 
                    </span>
                    <span>
                    قد يتم جمع معلومات إضافية حول المستخدمين وتخزينها في إطار استخدام خدمات الموقع.
                    </span>
                </p>
                <p class="content-item">
                    <span class="number">
                        2.2.1
                    </span>
                    <span>
                        أرقام الهواتف لضيوفك.
                    </span>
                </p>
                <p class="content-item">
                    <span class="number">
                        2.2.2
                    </span>
                    <span>
                     تفاصيل حدثك، مثل تاريخ الحدث، ووقت الحدث، وموقع الحدث، وما إلى ذلك.
                    </span>
                </p>
                <p class="content-item">
                    <span class="number">
                        2.2.3
                    </span>
                    <span>
                    طلباتك المختلفة إلى إدارة الموقع.
                    </span>
                </p>
            <p class="content-item">
                <span class="number">
                    2.2.4
                </span>
                <span>
                    جميع المعلومات المتعلقة بالاستخدام في خدمات الموقع، لتشغيلها ووفقًا لطلبك، بما في ذلك استخدام الخدمات الخارجية.
                </span>
            </p>
            <p class="content-item">
                <span class="number">
                    2.2.5
                </span>
                <span>
                    معلومات حول الأعمال التي لم تكتمل في إطار الخدمة (على سبيل المثال، النقر على زر إنشاء حدث أو إيقاف عملية التعبئة قبل الانتهاء منها، النقر على أزرار مختلفة، إلخ).
                </span>
            </p>
            <p class="content-item">
                <span class="number">
                    2.2.6
                </span>
                <span>
                    معلومات حول جهازك المحمول، مثل عنوان IP، نوع نظام التشغيل، منطقة الوقت وموقع الجهاز.
                </span>
            </p>
    
            <p class="content-item">
                <span class="number">
                    2.3. 
                </span>
                <span>
                 يشدد على أنك لست ملزماً قانونيًا بتقديم جميع التفاصيل المذكورة، والتي يعتمد تقديمها فقط على رغبتك وموافقتك الحرة. بدون تقديم بعض التفاصيل (على سبيل المثال، دون إدخال تفاصيل تعريفية للتسجيل، إلخ)، لن نتمكن من تشغيل وتقديم الخدمات المعروضة، جميعها أو بعضها.
                </span>
            </p>
            <p class="content-item">
                <span class="number">
                    2.4. 
                </span>
                <span>
                 يمكن أن يتم حفظ جميع المعلومات التي تم تقديمها أو التي تم جمعها عنك لتوفير الخدمات، جميعها أو بعضها، في قواعد بيانات الموقع المسجلة، وسيتم الاحتفاظ بها من قبل الإدارة أو من يمثلها. سيتم استخدام المعلومات وفقًا لما ورد في هذه السياسة.
                </span>
            </p>
            <p class="content-item">
                <span class="number">
                    2.5. 
                </span>
                <span>
                يجب التنويه بأن إدارة الموقع لا تنشر هذه المعلومات لأطراف ثالثة، بل تستخدمها فقط لتقديم الخدمات، بما في ذلك عبر مزودي الخدمة الثالثة.
                </span>
            </p>
            <p class="content-item">
                <span class="number">
                    2.6. 
                </span>
                <span>
                يجب تقديم معلومات صحيحة، موثوقة ودقيقة.
                </span>
            </p>
           `,
    },
    {
      title: `
                  <p>
                  <strong>
                  كوكيز الموقع" أو "Cookies"
                </strong>
              </p>`,

      content: `
            <p class="content-item">
                <span class="number">
                        2.7. 
                </span>
                <span>
                "Cookies" هي أدوات رقمية تسمح بجمع وتحليل المعلومات أثناء استخدام المتصفح. إدارة الموقع أو الأطراف الثالثة التي تعمل على الكوكيز، يمكنها الوصول من حين لآخر إلى المعلومات المستقرة في الكوكيز وسوف تتم إعادة توجيهها إلى خوادمها. حذف الكوكيز من المتصفح لن يحذف المعلومات التي تم نقلها بالفعل إلى الموقع أو إلى الأطراف الثالثة عبر الكوكيز.
                </span>
            </p>
                <p class="content-item">
                <span class="number">
                        2.8. 
                </span>
                <span>
                الكوكيز تحتوي وتجمع مجموعة متنوعة من المعلومات مثل المدة التي تستغرقها في الموقع، من أين وصلت إلى الموقع أو الصفحة، عنوان IP الخاص بك، خصائص جهازك، المعلومات التي تطلب رؤيتها عند الدخول إلى الموقع وما إلى.
                </span>
            </p>
            <p class="content-item">
            <span class="number">
                    2.9. 
            </span>
            <span>
            نحن نستخدم الكوكيز للتشغيل السلس والسليم للخدمات، لتحسين تجربة التصفح من خلال تكييف الخدمات لتفضيلاتك الشخصية وأيضًا لأغراض إحصائية وبحثية.
            </span>
            </p>
            <p class="content-item">
            <span class="number">
                2.10. 
            </span>
            <span>
            قد تستخدم إدارة الموقع أيضًا كوكيز من الأطراف الثالثة، مثل كوكيز من Google بالاستعانة بـ Google Analytics، ومن Microsoft بالاستعانة بـ Clarity، والتي تساعدنا على تكييف تجربة التصفح لك و / أو الاستخدام الصحيح للموقع، واستخراج أفكار إحصائية حول الاستخدام العام للخدمات. بالإضافة إلى ذلك، نحن نستخدم أدوات تحليلية مختلفة من Facebook كأداة لقياس خصائص استخدام الموقع، وأداة لقياس فعالية الحملات الإعلانية.
            </span>
            </p>
              `,
    },
    {
      title: `
                <p>
                    <strong>
                        3.<u> استخدام Google APIs</u> 
                    </strong>
                </p>`,
      content: `
                <p class="content-item">
                <span class="number">
                3.1. 
            </span>
                    <span>  
                    إدارة الموقع تستخدم وتقوم بنقل المعلومات المتلقاة من Google APIs إلى أي تطبيق آخر، وذلك وفقًا لـ 
                    <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"> Google API Services User Data Policy</a>             
                    , بما في ذلك متطلبات الاستخدام المحدود.
                    </span>
                </p>
                      `,
    },
    {
      title: `
              <p>
                  <strong>
                      4.<u>الوصول إلى المعلومات</u>
                  </strong>
              </p>`,
      content: `
              <p class="content-item">
                  <span class="number">
                      4.1. 
                  </span>
                  <span>
                  بعض الخدمات التي نقدمها تشمل الوصول إلى بيانات معينة لأجل تنفيذ أعمال معينة كجزء من الخدمات. هذه الأذونات تشمل، ولكن ليست محصورة في:
                  </span>
              </p>
              <p class="content-item">
              <span class="number">
                  4.1.1. 
              </span>
              <span>
              الوصول إلى قائمة جهات الاتصال من حساب Google – لتمكين استيراد الضيوف إلى النظام. المعلومات التي ستحفظ لهذا الغرض هي اسم ورقم هاتف جهة الاتصال.
              </span>
              </p>
              <p class="content-item">
              <span class="number">
                  4.1.2. 
              </span>
              <span>
              التسجيل و / أو تسجيل الدخول عبر حساب Google - لتمكين التسجيل و / أو تسجيل الدخول السريع إلى النظام.
              </span>
              </p>
              <p class="content-item">
              <span class="number">
                  4.1.3. 
              </span>
              <span>
              الوصول إلى معرض الصور - لرفع صورة دعوة للحدث، حسب طلبك.
              </span>
              </p>
              <p class="content-item">
                  <span class="number">
                      4.2. 
                  </span>
                  <span>
                    يجب الإشارة إلى أن إدارة الموقع لا توزع هذه المعلومات على الأطراف الثالثة، ولكنها تستخدمها لتقديم الخدمات.
                  </span>
              </p>
              
              `,
    },
    {
      title: `
              <p>
                  <strong>
                      5.<u>استخدام المعلومات الواردة أو المجموعة من المستخدمين</u>
                  </strong>
              </p>`,
      content: `
              <p class="content-item">
                  <span class="number">
                      5.1. 
                  </span>
                  <span>
                  نحن نستخدم المعلومات التي تم استلامها منك لتقديم الخدمات لك، بما في ذلك تأكيدات الحضور، عرض معلومات حول المدعوين لديك، عرض معلومات حول تكاليف حدثك، عرض بيانات إحصائية حول حدثك، إلخ.
                  </span>
              </p>
              <p class="content-item">
                  <span class="number">
                      5.2. 
                  </span>
                  <span>
                  بالإضافة إلى ذلك، ستُستخدم المعلومات لتحسين، إصلاح أو تغيير الخدمات والمحتوى المقدم ضمن خدماتنا، لتحسين الخدمات التي يقدمها الموقع.
                  </span>
              </p>
              <p class="content-item">
              <span class="number">
                  5.3. 
              </span>
              <span>
                يجوز لإدارة الموقع استخدام ومعالجة وتصنيف وتحسين المعلومات المذكورة، بما في ذلك من خلال مزودي الخدمات والمعلومات الخارجية، لأغراض الإدارة، والكفاءة، وتخصيص المحتوى الإعلاني والتسويقي لخدمات الموقع.
              </span>
              </p>
              <p class="content-item">
                <span class="number">
                    5.4. 
                </span>
                <span>
                    في إطار الخدمات، قد يظهر روابط مختلفة إلى مواقع و / أو تطبيقات خارجية. النقر على الرابط يتم بمعرفتك ومسؤوليتك فقط، حيث ستكون خاضعًا لشروطه، بما في ذلك سياسة الخصوصية الخاصة به. ومن هذه المرحلة، لن تكون لإدارة الموقع أي سيطرة على ما سيتم فعله بمعلوماتك، ولذلك لن تتحمل المسؤولية بالنسبة لذلك.
                </span>
              </p>
              <p class="content-item">
                <span class="number">
                    5.5. 
                </span>
                <span>
                    ستحاول إدارة الموقع قدر الإمكان عدم تقديم التفاصيل الشخصية للمستخدم لأطراف ثالثة، ولكن المستخدم يعطي هنا موافقته لإدارة الموقع لكشف تفاصيله وأي معلومات أخرى، كما سيتعين عليها القيام بذلك وفقا لأي قانون.
                </span>
              </p>
              <p class="content-item">
                <span class="number">
                    5.6. 
                </span>
                <span>
                    سيتم الاحتفاظ بحساب المستخدم وتفاصيله بما في ذلك تفاصيل الأحداث المرتبطة بالحساب، في قاعدة البيانات لمدة نصف سنة من تاريخ الحدث، وبشكل عام ولن يكون هناك استخدام للحساب، تحتفظ إدارة الموقع لنفسها بالحق في إعادة تعيين الحساب وتفاصيل الأحداث المرتبطة به، وسيتم حذفها فورًا.
                </span>
              </p>
                `,
    },
    {
      title: `
              <p>
                  <strong>
                      6.<u>نقل المعلومات إلى جهة ثالثة</u>
                  </strong>
              </p>`,
      content: `
              <p class="content-item">
                  <span class="number">
                      6.1. 
                  </span>
                  <span>
                    عند تنفيذ بعض الإجراءات ضمن الخدمات، قد يُطلب منك نقل المعلومات أيضًا إلى أطراف ثالثة، التي تنفذ الإجراء، مثل معالجي الدفع والأطراف المشاركة في معالجة الدفع وشركات بطاقات الائتمان وخدمات الدفع. إدارة الموقع لا تحتفظ بتفاصيل الدفع، ولكنها قد تقدم معلومات مثل مبلغ الدفع للحزمة التي اختارها المستخدم لتنفيذ عملية الدفع، التحقق من عملية الدفع، إصدار الفاتورة أو للامتثال للقوانين.
                  </span>
              </p>
              <p class="content-item">
                  <span class="number">
                      6.2. 
                  </span>
                  <span>
                    لمزودي الاتصالات لتقديم الخدمات مثل إرسال رسائل نصية/واتساب ومكالمات صوتية تلقائية للمدعوين إلى حدثك.
                  </span>
              </p>
              <p class="content-item">
                  <span class="number">
                      6.3. 
                  </span>
                  <span>
                    لغرض الاستبعاد، الموقع لا يبيع أو ينقل تفاصيل المستخدم للهيئات الخارجية. ومع ذلك، يستخدم الموقع المعلومات التي يدخلها المستخدم في النظام وينقلها إلى شركات طرف ثالث لتوفير الخدمات للمستخدم.
                  </span>
              </p>
                  `,
    },

    {
      title: `
              <p>
                  <strong>
                      7.<u>حماية المعلومات</u> 
                  </strong>
              </p>`,
      content: `
              <p class="content-item">
                  <span class="number">
                      7.1. 
                  </span>
                  <span>
                    تبذل إدارة الموقع جهودًا واضحة لحماية سرية البيانات التي يقدمها مستخدمي الموقع، وذلك من خلال اتخاذ التدابير الاحترازية المقبولة. ولكن، لا يمكن لإدارة الموقع ضمان عدم وجود أي استخدام غير مصرح به أو تشويه للخدمات المقدمة.
                  </span>
              </p>
              <p class="content-item">
                  <span class="number">
                      7.2. 
                  </span>
                  <span>
                    لن تتحمل إدارة الموقع المسؤولية عن فقدان المعلومات في الموقع نتيجة لقوة قاهرة، أو انهيار وطني أو عالمي لشبكة الإنترنت و / أو عطل غير متوقع في خوادم الشركة.
                  </span>
              </p>
            
                  `,
    },
    {
      title: `
              <p>
                  <strong>
                      8.<u>الاتصال بشأن الخصوصية</u> 
                  </strong>
              </p>`,
      content: `
              <p class="content-item">
                  <span>
                  لأي سؤال و / أو توضيح حول سياسة الخصوصية، يمكنك الاتصال بإدارة الموقع على العنوان البريدي التالي:
                  support@anita-lee.com                  
                  </span>
              </p>
                    `,
    },
  ],
}
