import { mdiMessageProcessingOutline, mdiPhone, mdiWhatsapp } from '@mdi/js'

export default function useMessagesType() {
  const messagesType = {
    SMS: 1,
    CALL: 2,
    WHATSAPP: 3,
  }
  const messagesTypeMapping = [
    {
      value: messagesType.WHATSAPP,
      title: 'WhatsAppMessages',
      icon: mdiWhatsapp,
      color: 'success',
    },
    {
      value: messagesType.SMS,
      title: 'SmsMessages',
      icon: mdiMessageProcessingOutline,
      color: 'indigo',
    },
    {
      value: messagesType.CALL,
      title: 'CallMessages',
      icon: mdiPhone,
      color: 'teal',
    },
  ]

  return {
    messagesType,
    messagesTypeMapping,
  }
}
