class ImagePreloader {
  constructor() {
    this.imageCache = new Map() // Cache to store loaded images
    this.imagePromises = new Map() // Cache to store ongoing promises
  }

  preloadImage(src) {
    // Return the cached promise if it exists
    if (this.imagePromises.has(src)) {
      return this.imagePromises.get(src)
    }

    // Otherwise, create a new promise for the image loading process
    const imagePromise = new Promise((resolve, reject) => {
      const img = new Image()
      img.src = src

      img.onload = () => {
        this.imageCache.set(src, src)
        this.imagePromises.delete(src) // Remove from promises cache once loaded
        resolve(src)
      }

      img.onerror = error => {
        this.imagePromises.delete(src) // Remove from promises cache on error
        reject(error)
      }
    })

    // Cache the promise and return it
    this.imagePromises.set(src, imagePromise)

    return imagePromise
  }

  getImage(src) {
    if (this.imageCache.has(src)) {
      return Promise.resolve(this.imageCache.get(src))
    }

    return this.preloadImage(src)
  }
}

export default new ImagePreloader()
