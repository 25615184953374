export default {
  terms_title: 'מדיניות פרטיות',
  introduction: [
    {
      content: `
          <p>
            ברוכים הבאים לאתר Anita-lee (להלן: <strong>"האתר"</strong>), אשר מציע שירותים (להלן: <strong>"השירותים"</strong>) לאירועים, לרבות מערכת לניהול מוזמנים, אישורי הגעה וניהול הוצאות האירוע. כל שימוש באתר מהווה הסכמה לתקנון ולמדיניות פרטיות זו, על התנאים וההגבלות הכלולים בו. השימוש באתר כפוף למדיניות הפרטיות העדכנית בה, ומעיד על הסכמתך למדיניות הפרטיות וכן לשינויים בה. 
          </p>
          `,
    },
    {
      content: `
          <p>
              האמור במדיניות פרטיות זו נכתב בלשון זכר לשם הנוחות בלבד, והינו מתייחס לכול המינים באופן שווה.
          </p>`,
    },
  ],
  sections: [
    {
      title: `
          <p>
              <strong>
                  1.<u> כללי</u>
              </strong>
          </p>`,

      content: `
          <p class="content-item">
              <span class="number">
                  1.1. 
              </span>
              <span>
                  הנהלת האתר מכבדת את פרטיות כלל המשתמשים.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                    1.2. 
              </span>
              <span>
                    מטרת מדיניות פרטיות זו היא לספק פירוט בדבר המידע הנאסף אודות המשתמשים עקב השימוש בשירותים, וכן לסקור את האופן שבו משתמשת הנהלת האתר במידע שנמסר לה ונאסף על ידה עקב השימוש בשירותיה.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                    1.3. 
              </span>
              <span>
                    תנאי מדיניות הפרטיות המפורטים להלן מהווים חלק בלתי נפרד מתקנון האתר, הנהלת האתר רשאית לשנות מעת לעת את מדיניות הפרטיות ואת התקנון, יחד או לחוד. ועל כן, על המשתמש להתעדכן במסמך זה במתכונתו המעודכנת באתר האינטרנט. 
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                    1.4. 
              </span>
              <span>
                    שירותי האתר מיועדים לשימוש למשתמשים בני 18 ומעלה.
              </span>
          `,
    },
    {
      title: `
          <p>
              <strong>
                  2.<u>איסוף מידע</u>
              </strong>
          </p>`,
      content: `
            <p class="content-item">
                <span class="number">
                        2.1. 
                </span>
                <span>
                        השירותים שהאתר מציע דורשים הרשמה, הכוללים מסירת מידע אישי, לדוגמא: שם מלא וכתובת דואר אלקטרוני. מסירת המידע בעת ההרשמה יכולה להיות גם באמצעות צדדים שלישיים שבאמצעותם ימולאו הפרטים, דוגמת Google login.
                </span>
            </p>
            <p class="content-item">
                <span class="number">
                        2.2. 
                </span>
                <span>
                        מידע נוסף אודות המשתמשים עשוי להיאסף ולהישמר במסגרת השימוש בשירותי האתר.
                </span>
            </p>
            <p class="content-item">
                <span class="number">
                    2.2.1
                </span>
                <span>
                    שם מלא ומספרי טלפון של המוזמנים שלך.
                </span>
            </p>
            <p class="content-item">
                <span class="number">
                    2.2.2
                </span>
                <span>
                    פרטי האירוע שלך, כדוגמת תאריך האירוע, שעת האירוע, מיקום האירוע וכו'.
                </span>
            </p>
            <p class="content-item">
                <span class="number">
                    2.2.3
                </span>
                <span>
                    פניות שונות שלך אל הנהלת האתר.
                </span>
            </p>
        <p class="content-item">
            <span class="number">
                2.2.4
            </span>
            <span>
                כל מידע הקשור בשימוש בשירותי האתר, לצורך הפעלתם ובהתאם לבקשתך, לרבות שימוש בשירותים חיצוניים.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                2.2.5
            </span>
            <span>
                מידע אודות פעולות שלא הושלמו במסגרת השירות (לדוגמא, לחיצה על כפתור יצירת אירוע או הפסקת תהליך המילוי בטרם הושלם, לחיצה על כפתורים שונים וכד').
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                2.2.6
            </span>
            <span>
                מידע על מכשיר הקצה שלך, לדוגמת כתובת IP, סוג מערכת ההפעלה, אזור זמן ומיקום המכשיר.
            </span>
        </p>

        <p class="content-item">
            <span class="number">
                2.3. 
            </span>
            <span>
                מובהר כי אינך מחויב על פי דין למסור את כל פרטי המידע האמורים, ומסירתם תלויה אך ורק ברצונך ובהסכמתך החופשיים. ללא מסירת פרטים מסוימים (למשל, ללא הזנת פרטים מזהים לצורך הרשמה וכד') לא נוכל לתפעל ולספק את השירותים המוצעים, חלקם או את כולם. 
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                2.4. 
            </span>
            <span>
                כלל המידע שנמסר או ייאסף אודותיך לצורך אספקת השירותים, חלקם או כולם, עשוי להישמר במאגרי המידע הרשומים של הנהלת האתר, ויוחזק על ידה או על ידי מי מטעמה. השימוש במידע יהיה בהתאם לאמור במדיניות זו.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                2.5. 
            </span>
            <span>
                יש לציין כי הנהלת האתר לא מפיצה מידע זה לגורמים שלישיים, אלא משתמשת בהם לצורך אספקת שירותים לרבות באמצעות ספקי צד שלישי.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                2.6. 
            </span>
            <span>
                יש למסור מידע נכון, אמין ומדויק.
            </span>
        </p>
       `,
    },
    {
      title: `
              <p>
              <strong>
                  "עוגיות"
              </strong>
          </p>`,

      content: `
        <p class="content-item">
            <span class="number">
                    2.7. 
            </span>
            <span>
                "Cookies" ו/או "עוגיות" הן כלים דיגיטליים המאפשרים איסוף וניתוח מידע בזמן שימוש בדפדפן. הנהלת האתר או צדדים שלישיים המפעילים את העוגיות, יכולים לגשת מעת לעת למידע הנצבר בעוגיות והוא יעבור לשרתים שלהן. מחיקת העוגיות מהדפדפן לא תמחק מידע שכבר הועבר לאתר או לצדדים שלישיים באמצעות העוגיות. 
            </span>
        </p>
            <p class="content-item">
            <span class="number">
                    2.8. 
            </span>
            <span>
                העוגיות מכילות ואוספות מידע מגוון כדוגמא משך הזמן ששהית באתר, מהיכן הגעת אל האתר או אל הדף, כתובת ה-IP שלך, מאפייני המכשיר שלך, מידע שתבקש לראות בעת הכניסה לאתר וכו'. 
            </span>
        </p>
        <p class="content-item">
        <span class="number">
                2.9. 
        </span>
        <span>
                אנו עושים שימוש בעוגיות לצורך תפעול שוטף ותקין של השירותים, לשם שיפור חווית הגלישה תוך התאמת השירותים להעדפותיך האישיות וכן למטרות סטטיסטיות  ומחקריות.
        </span>
        </p>
        <p class="content-item">
        <span class="number">
            2.10. 
        </span>
        <span>
            הנהלת האתר עשויה לעשות שימוש אף בעוגיות צד שלישי, למשל עוגיות של חברת Google תוך שימוש בGoogle Analytics, ושל חברת Microsoft תוך שימוש בClarity, העוזרת לנו להתאים עבורך את חווית הגלישה ו/או את השימוש התקין באתר, ולהסיק תובנות סטטיסטיות בנוגע לכלל השימושים בשירותים. בנוסף, אנו עושים שימוש בכלי ניתוח שונים של פייסבוק ככלי מדידה של מאפייני השימוש באתר, כלי מדידה של אפקטיביות קמפיינים פרסומיים.
        </span>
        </p>
          `,
    },
    {
      title: `
            <p>
                <strong>
                    3.<u> שימוש ב- Google APIs </u> 
                </strong>
            </p>`,
      content: `
            <p class="content-item">
            <span class="number">
            3.1. 
        </span>
                <span>  
                הנהלת האתר משתמשת ומעבירה לספקים ו/או לצדדים שלישיים מידע שמתקבל מ- Google APIs, בהתאם לתנאי השימוש המוגבלים כפי שהם מופיעים ב-  
                <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"> Google API Services User Data Policy.</a>             
                </span>
            </p>
                  `,
    },
    {
      title: `
          <p>
              <strong>
                  4.<u>גישה למידע</u>
              </strong>
          </p>`,
      content: `
          <p class="content-item">
              <span class="number">
                  4.1. 
              </span>
              <span>
                    חלק מהשירותים שאנו מספקים כוללים גישה לנתונים מסוימים וזאת לטובת ביצוע פעולות מסוימות כחלק מהשירותים. הרשאות אלו כוללות, אך אינן מוגבלות:
              </span>
          </p>
          <p class="content-item">
          <span class="number">
              4.1.1. 
          </span>
          <span>
            גישה לרשימת אנשי קשר מחשבון הGoogle – על מנת לאפשר יבוא מוזמנים למערכת. המידע שישמר לצורך כך הינו שם ומספר טלפון של איש הקשר.
          </span>
          </p>
          <p class="content-item">
          <span class="number">
              4.1.2. 
          </span>
          <span>
                הרשמה ו/או התחברות דרך חשבון הGoogle – על מנת לאפשר הרשמה ו/או התחברות מהירה למערכת.
          </span>
          </p>
          <p class="content-item">
          <span class="number">
              4.1.3. 
          </span>
          <span>
            גישה לגלריית התמונות – לצורך העלאת תמונת הזמנה לאירוע, על פי בקשתך.
          </span>
          </p>
          <p class="content-item">
              <span class="number">
                  4.2. 
              </span>
              <span>
                יש לציין כי הנהלת האתר לא מפיצה מידע זה לגורמים שלישיים, אלא משתמשת בהם לצורך אספקת שירותים.
              </span>
          </p>
          
          `,
    },
    {
      title: `
          <p>
              <strong>
                  5.<u>שימוש במידע שמתקבל או נאסף מהמשתמשים</u>
              </strong>
          </p>`,
      content: `
          <p class="content-item">
              <span class="number">
                  5.1. 
              </span>
              <span>
                אנו עושים שימוש במידע שמתקבל ממך על מנת לספק לך את השירותים, לרבות אישורי הגעה, הצגת מידע אודות המוזמנים שלך, הצגת מידע אודות הוצאות האירוע שלך, הצגת נתונים סטטיסטיים אודות האירוע שלך וכו'.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  5.2. 
              </span>
              <span>
                בנוסף המידע ישמש על מנת לשפר, לתקן או לשנות את השירותים והתכנים המוצעים במסגרת שירותינו, על מנת לייעל את השירותים המוצעים על ידי הנהלת האתר.
              </span>
          </p>
          <p class="content-item">
          <span class="number">
              5.3. 
          </span>
          <span>
            הנהלת האתר תוכל לעשות שימוש, לעבד, לטייב ולהעשיר את המידע הנ"ל, לרבות באמצעות ספקי שירותים ומידע חיצוניים, למטרות ניהול, ייעול, התאמת תוכן פרסומי ושיווקי של שירותי האתר. 
          </span>
          </p>
          <p class="content-item">
            <span class="number">
                5.4. 
            </span>
            <span>
                במסגרת השירותים עשויים להופיע קישורים שונים לאתרים ו/או יישומים חיצוניים. לחיצה על הקישור תעשה על דעתך ועל אחריותך בלבד, שכן תהיה כפוף לתנאיו, לרבות מדיניות הפרטיות שלו. ומאותו שלב, להנהלת האתר לא תהיה שליטה על הנעשה במידע שלך, ולפיכך לא תישא באחריות ביחס לכך.
            </span>
          </p>
          <p class="content-item">
            <span class="number">
                5.5. 
            </span>
            <span>
            הנהלת האתר תמנע ככל האפשר ממסירת פרטיו האישיים של המשתמש לצדדים שלישיים, אך המשתמש נותן בזאת את הסכמתו להנהלת האתר לחשוף את פרטיו וכל מידע אחר, ככל שיחויב לעשות זאת על פי כל דין.
            </span>
          </p>
          <p class="content-item">
            <span class="number">
                5.6. 
            </span>
            <span>
                חשבון המשתמש ופרטיו לרבות פרטי האירועים המקושרים לחשבון, יישמרו במאגר למשך תקופה של חצי שנה מיום האירוע, וככלל ולא יהיה שימוש בחשבון, הנהלת האתר שומרת לעצמה את הזכות לבצע איפוס לחשבון ולפרטי האירועים שהיו בו, ואלו יימחקו לאלתר.
            </span>
          </p>
            `,
    },
    {
      title: `
          <p>
              <strong>
                  6.<u>מסירת מידע לצד שלישי</u>
              </strong>
          </p>`,
      content: `
          <p class="content-item">
              <span class="number">
                  6.1. 
              </span>
              <span>
              בעת ביצוע פעולות מסוימות במסגרת השירותים, ייתכן ותידרש העברת מידע גם לצדדים שלישיים, המיישמים את ביצוע הפעולה, דוגמת סולקים וגורמים המעורבים בסליקה וחברות כרטיסי אשראי ושירותי תשלום. אמנם הנהלת האתר אינה שומרת את פרטי התשלום, אך היא עשויה למסור מידע כגון סכום התשלום עבור החבילה שנבחרה על ידי המשתמש לצורך ביצוע פעולת תשלום, בירור על פעולת תשלום, הפקת חשבונית או לצורך קיום הוראות הדין.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  6.2. 
              </span>
              <span>
                לספקי תקשורת לצורך אספקת השירותים כגון שליחת סמס/וואטסאפ ושיחות קוליות אוטומטיות למוזמנים לאירוע שלך.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  6.3. 
              </span>
              <span>
                למען הסר ספק, האתר אינו מוכר ו/או מעביר את פרטי המשתמש לגופים חיצוניים. עם זאת, האתר עושה שימוש במידע שהמשתמש מזין במערכת ומעביר אותם לחברות צד שלישי על מנת לספק למשתמש את השירותים.
              </span>
          </p>
              `,
    },

    {
      title: `
          <p>
              <strong>
                  7.<u>אבטחת מידע</u> 
              </strong>
          </p>`,
      content: `
          <p class="content-item">
              <span class="number">
                  7.1. 
              </span>
              <span>
                הנהלת האתר עושה מאמצים ניכרים כדי להגן על סודיות הנתונים אשר נמסרו על ידי משתמשי האתר, זאת תוך נקיטת אמצעי זהירות מקובלים. ואולם, הנהלת האתר אינה יכולה להבטיח כי לא יהיה כל שימוש בלתי מורשה או שיבוש בשירותים הניתנים.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  7.2. 
              </span>
              <span>
                הנהלת האתר לא תישא באחריות לאובדן המידע באתר כתוצאה של כוח עליון, קריסה ארצית או עולמית של רשת האינטרנט ו/או תקלה בלתי צפויה בשרתי החברה. 
              </span>
          </p>
        
              `,
    },
    {
      title: `
          <p>
              <strong>
                  8.<u>יצירת קשר בנושא פרטיות</u> 
              </strong>
          </p>`,
      content: `
          <p class="content-item">
              <span>
              בכל שאלה ו/או הבהרה בנוגע למדיניות פרטיות ניתן לפנות אל הנהלת האתר בכתובת המייל:
              support@anita-lee.com
              
              </span>
          </p>
                `,
    },
  ],
}
