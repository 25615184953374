<template>
  <div
    class="vertical-nav-header d-flex justify-space-between"
  >
    <div class="d-flex align-center justify-center pt-5 pb-0 text-center"></div>
    <!-- <v-spacer></v-spacer> -->
    <div
      dir="ltr"
      class="d-flex align-center justify-center pt-5 pb-0 text-center"
    >
      <router-link
        :to="{name: 'home-page'}"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="appLogo"
          max-height="35px"
          max-width="35px"
          alt="logo"
          contain
          eager
        ></v-img>
        <v-img
          v-show="!menuIsVerticalNavMini"
          :src="appName"
          max-height="40px"
          max-width="120px"
          alt="name"
          contain
          eager
          class="mt-2"
        ></v-img>
      </router-link>
    </div>
    <!-- <v-spacer></v-spacer> -->
    <div class="d-flex align-center justify-end pt-5 pb-0">
      <v-slide-x-transition>
        <div
          v-show="!(menuIsVerticalNavMini && !isMouseHovered)"
          v-if="$vuetify.breakpoint.lgAndUp"
          class="d-flex align-center justify-end"
          @click.stop="menuIsVerticalNavMini = !menuIsVerticalNavMini"
        >
          <v-icon
            v-show="!menuIsVerticalNavMini"
            size="20"
            class="cursor-pointer mx-0 px-0"
          >
            {{ icons.mdiRecordCircleOutline }}
          </v-icon>
          <v-icon
            v-show="menuIsVerticalNavMini"
            size="20"
            class="cursor-pointer"
          >
            {{ icons.mdiRadioboxBlank }}
          </v-icon>
        </div>
        <v-icon
          v-else
          size="20"
          class="d-inline-block me-2"
          @click.stop="$emit('close-nav-menu')"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </v-slide-x-transition>
    </div>
  </div>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiClose, mdiRadioboxBlank, mdiRecordCircleOutline } from '@mdi/js'
import themeConfig from '@themeConfig'
import { inject } from '@vue/composition-api'

export default {
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()
    const isMouseHovered = inject('isMouseHovered')

    return {
      menuIsVerticalNavMini,
      isMouseHovered,
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Icons
      icons: {
        mdiClose,
        mdiRadioboxBlank,
        mdiRecordCircleOutline,
      },
    }
  },
}
</script>
