export default {
  terms_title: 'Terms of Use Agreement',
  introduction: [
    {
      content: `
        <p>
            Welcome to the Anita-lee website ("<strong>the Site</strong>"), which offers services ("<strong>the Services</strong>") for events management. These services include a system for managing guests, handling RSVPs', and managing event expenses. Any use of the Site constitutes acceptance of this agreement (<strong>"the Agreement"</strong>), inclusive of the terms and restrictions contained herein.
        </p>
        `,
    },
    {
      content: `
        <p>
            The terminology in this Terms of Use Agreement is written in masculine language for convenience only, and is intended to refer to all genders equally.
        </p>`,
    },
    {
      content: `
        <p>
            This agreement may change from time to time, and it is the user's responsibility to stay updated with the agreement upon each visit to the site. Browsing and/or using the site in any way constitutes acceptance of the terms and commitment to act accordingly. It should be noted that this agreement constitutes a legally binding agreement for all purposes and binds the user to all of their site usage.
        </p>`,
    },
    {
      content: `
        <p>
            The act of ordering services on this site constitutes a clear declaration by the user that they have read, understood, and agreed to the provisions of this agreement. The agreement constitutes a legally binding contract between the user and the site administration.
        </p>`,
    },
  ],
  sections: [
    {
      title: `
        <p>
            <strong>
                1.<u>Usage Restrictions</u>
            </strong>
        </p>`,

      content: `
        <p class="content-item">
            <span class="number">
                1.1. 
            </span>
            <span>
                The services are provided in the State of Israel only.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                1.2. 
            </span>
            <span>
                The use of the site is only permitted for users above the age of 18 who are legally capable of entering into this agreement, subject to acceptance of the terms of this agreement.
            </span>
          </p>
        <p class="content-item">
            <span class="number">
                1.3. 
            </span>
            <span>
                The user agrees not to use the services to distribute harmful, provocative, or harassing content or any content that is contrary to the law.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                1.4. 
            </span>
            <span>
                The user agrees not to use the site's services for non-personal purposes.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                1.5. 
            </span>
            <span>
                The site administration reserves the right to prevent a user from accessing and using the site, either partially or entirely, at its sole discretion and for any reason, without the need to provide a detailed explanation. A user who is denied access to the site is not allowed to return and use the site.
            </span>
        </p>
          `,
    },
    {
      title: `
        <p>
            <strong>
                2.<u> Terms of Service </u>
            </strong>
        </p>`,
      content: `
        <p class="content-item">
            <span class="number">
                2.1. 
            </span>
            <span>
                The site serves as a platform for event management, including sending SMS, WhatsApp messages, and automated calls for attendance confirmations, subject to the package chosen by the user.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                2.2. 
            </span>
            <span>
                The site administration does not guarantee that the site will operate without any malfunctions or errors of any kind. The service on the site is provided "AS IS" and allows the user to experience the system through the purchase of paid services. The site administration assumes no explicit or implied responsibility for direct or indirect damages.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                2.3. 
            </span>
            <span>
                By registering on the site and using the service, the user agrees and commits to comply with the terms specified in this agreement, as periodically updated by the site administration. Upon registration, the user agrees and commits to staying updated with the terms of use as periodically updated by the site administration.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                2.4. 
            </span>
            <span>
                The use of the site is subject to this agreement and its terms of use, and it constitutes a binding document between the user and the site administration.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                2.5. 
            </span>
            <span>
                It is solely the responsibility of the user to use the site.
            </span>
        </p>
          `,
    },
    {
      title: `
        <p>
            <strong>
                3.<u>  User Agreement </u>
            </strong>
        </p>`,
      content: `
        <p class="content-item">
            <span class="number">
                3.1. 
            </span>
            <span>
                The user undertakes not to distribute SMS/WhatsApp messages and voice calls to phone numbers that have not given their consent.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                3.2. 
            </span>
            <span>
                The user agrees to act in accordance with the spam law.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                3.3. 
            </span>
            <span>
                The user agrees not to distribute content related to spam.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                3.4. 
            </span>
            <span>
                The user undertakes not to transfer his/her usage rights on the website to any third party and assumes exclusive responsibility for securing his/her account and passwords.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                3.5. 
            </span>
            <span>
                The user hereby declares that he/she is aware of the potential damages that may arise if direct complaints or complaints through a third party are received regarding the distribution of unsolicited SMS/WhatsApp messages and/or automated voice calls to recipients who have not explicitly requested them. The user also agrees to indemnify and hold the website administration and its representatives harmless against any claims or demands sent to them. The user is aware and agrees that performing any of these actions may result in the unilateral cessation of activity with the website administration and the website, and that he/she will not be entitled to any refunds for payments made but not executed due to a breach of any provisions of this agreement.
            </span>
          </p>
        <p class="content-item">
            <span class="number">
                3.6. 
            </span>
            <span>
                The user is aware that he/she bears sole responsibility for entering data and content onto the website, and in the event that the user enters incorrect content or data, he/she will not direct any claims or complaints towards the website administration.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                3.7. 
            </span>
            <span>
                The user agrees that the content of automated messages and voice calls is subject to editing and is solely the responsibility of the user, subject to the limitations specified in this agreement. The user or any third party on his/her behalf will not have any complaints. The website administration shall not be held responsible for any direct or indirect damages related to the transmission of the content of automated messages and voice calls.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                3.8. 
            </span>
            <span>
                The user is aware that the start date of arrival approvals can be modified, and as long as he/she does not change the date, the website administration will initiate the arrival approvals on the specified date at its sole discretion, and the user will have no claims or complaints regarding this matter.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                3.9. 
            </span>
            <span>
                The sole responsibility for verifying the correctness of all data, including event reference links, rests with the user, and the user will not have any claims or complaints against the website administration regarding this matter.
            </span>
        </p>
          `,
    },
    {
      title: `
        <p>
            <strong>
                4.<u> Intellectual Property </u>
            </u></strong>
        </p>`,
      content: `
        <p class="content-item">
            <span class="number">
                4.1. 
            </span>
            <span>
                All rights to the website, including intellectual property rights on the website, including its name, trademarks, design, content, as well as the intellectual property rights of the services and accompanying services, are solely owned by the website.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                4.2. 
            </span>
            <span>
                The website administration is the exclusive owner of the website, the databases, and all other content included therein. The user acknowledges that copying, distributing, reproducing, and/or transferring the information on the website, either in part or in its entirety, is strictly prohibited. This includes any commercial use of the website's information without prior written permission from the website administration.
            </span>
        </p>
            `,
    },
    {
      title: `
        <p>
            <strong>
                5.<u> Violation of Terms of Use </u>
            </u></strong>
        </p>`,
      content: `
        <p class="content-item">
            <span class="number">
                5.1. 
            </span>
            <span>
                Any violation of this agreement will result in the website administration, its employees, managers, shareholders, or representatives holding the violator liable for any damages, losses, lost profits, attorney fees, and legal expenses incurred as a result of such violation.
            </span>
        </p>
              `,
    },
    {
      title: `
        <p>
            <strong>
                6.<u> Use of the Website and its Services </u>
            </u></strong>
        </p>`,
      content: `
        <p class="content-item">
            <span class="number">
                6.1. 
            </span>
            <span>
                The user may use the Website and its services for personal and private purposes only.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                6.2. 
            </span>
            <span>
                The use of the Website and its services is subject to user registration on the Website and will be available to registered users only.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                6.3. 
            </span>
            <span>
                Website registration is subject to the user providing their personal details, such as full name, email address, and password selection. The Website will verify the user through their email address.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                6.4. 
            </span>
            <span>
                The use of the website signifies the user's agreement to this policy and their consent to receive newsletters and/or promotional material from the website, subject to the user's right to unsubscribe from such communications by written notice to the website's email.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                6.5. 
            </span>
            <span>
                The Website administration reserves the right to add identification fields at its sole discretion.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                6.6. 
            </span>
            <span>
                The Website administration reserves the right to cancel the user's registration on the Website for any reason, without any obligation to provide justification for this decision.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                6.7. 
            </span>
            <span>
                The user acknowledges that some of the services provided on the Website are dependent on agreements between the Website administration, service operators, and various mobile operators. In any case where the agreement between the Website administration and any mobile operator terminates for any reason, the services will cease to be provided, and the user will have no claim or demand in connection with this.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                6.8. 
            </span>
            <span>
                The user hereby declares and undertakes that there will be no claim or lawsuit against the Website administration or mobile operators resulting from circumstances of force majeure, including any change related to regulation.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                6.9. 
            </span>
            <span>
                The user is aware that some of the services provided on the Website are dependent on third parties. Therefore, in the event of a malfunction, lack of functionality, failure, or any other reason originating from one or more of the third parties, the Website administration will not be liable for any direct or indirect damage or loss incurred by the user.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                6.10. 
            </span>
            <span>
                The Website administration is not responsible for the content and/or information regarding advertising on the Website, as well as links to third-party external websites.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                6.11. 
            </span>
            <span>
                The user is aware that they are solely responsible for backing up the files and data displayed in the system and thereby releases the Website administration from any liability for direct or indirect damage that may arise as a result of data loss.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                6.12. 
            </span>
            <span>
                By using the system, the user expressly authorizes the Website administration to contact them from time to time with offers to purchase various products, services, promotions, and the like, in any manner permitted by the Communications (Bezeq and Broadcasting) Law, 5768-2008, including by email, WhatsApp, and SMS. The user also authorizes the transfer of their details to third parties for the purpose of fulfilling the aforementioned. Any inquiry regarding the use of information collected by the Website administration, including requests for removal from the database or refusal to receive any offers or the deletion of any information, can be directed to the Website administration via email at: support@anita-lee.com.
            </span>
        </p>
              `,
    },
    {
      title: `
        <p>
            <strong>
                7.<u> Liability of the Website Administration </u>
            </u></strong>
        </p>`,
      content: `
        <p class="content-item">
            <span class="number">
                7.1. 
            </span>
            <span>
                The website administration and all individuals acting on its behalf shall not be held liable for any damages resulting from the services provided by the website.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                7.2. 
            </span>
            <span>
                The user agrees to indemnify and hold harmless the website administration, its employees, managers, or representatives, and/or the cellular operators, as the case may be, for any loss, damage, expense, or liability incurred by any of them arising from the user's violation of these Terms of Use. In addition, the user acknowledges and agrees that the website administration has the right to seek compensation for any damages incurred as a result of any unlawful use of the system, unauthorized use, and/or use that violates the terms of this agreement. Such compensation may include damages of any kind.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                7.3. 
            </span>
            <span>
                The website administration does not guarantee that its services will be free from errors, malfunctions, or disruptions.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                7.4. 
            </span>
            <span>
                The website administration shall not be held responsible for any direct or indirect damage related to the website and/or arising from incorrect or unsatisfactory use of the website services and/or content posted by the user through the website services, as well as any direct or indirect damage related to the faulty functioning of the website. By agreeing to these terms, you waive any claims and/or demands against the website administration as stated above.
            </span>
        </p>
                `,
    },
    {
      title: `
        <p>
            <strong>
                8.<u> Payment and Cancellation Policy </u>
            </u></strong>
        </p>`,
      content: `
        <p class="content-item">
            <span class="number">
                8.1. 
            </span>
            <span>
                The website administration reserves the right, at its sole discretion and without prior notice, to modify the terms of any promotion or offer, including the duration of the promotion or offer, by either shortening or extending it. The website administration also reserves the exclusive right, at its sole discretion, to prevent any user from participating in a promotion or offer, without providing any reason to the user.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                8.2. 
            </span>
            <span>
                The website administration shall not be held responsible for any direct or indirect damage, loss, or harm caused to the user or any third party as a result of using or making a purchase on the website, including cases where a purchase is made without the cardholder's consent.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                8.3. 
            </span>
            <span>
                The user is not entitled to cancel the service after the start date of the booking confirmation.
            </span>
        </p>
        <p class="content-item">
            <span class="number">
                8.4. 
              </span>
              <span>
                Cancellation of the service shall be in accordance with the provisions of the Consumer Protection Law, 1981. The cancellation notice shall be made in writing only and sent to the email address of the website administration provided on the contact page.
              </span>
        </p>
                  `,
    },
    {
      title: `
        <p>
              <strong>
                    9.<u> Jurisdiction </u>
              </u></strong>
        </p>`,
      content: `
        <p class="content-item">
            <span class="number">
                9.1. 
            </span>
            <span>
                Any dispute or legal proceeding relating to any matter arising from or in connection with this agreement shall be exclusively initiated by filing a request with the authorized courts of Tel Aviv, Israel, and in such case, only the laws of the State of Israel shall apply to any dispute between the parties.
            </span>
        </p>
                    `,
    },
  ],
}
