<template>
  <v-bottom-navigation
    class="d-flex rounded-xl justify-center align-center"
    :class="$vuetify.breakpoint.mdAndUp? 'py-2 px-5 mt-3': 'pb-2'"
    color="primary"
    height="auto"
    style="flex-flow: wrap; background-color: transparent;"
    elevation="0"
  >
    <v-btn
      id="navGuestList"
      elevation="0"
      :class="{ 'active-nav': activeButtonId === 'navGuestList', 'mx-1': !callActive }"
      class="ma-0 pa-0 rounded-lg"
      :to="{name: 'apps-user-list'}"
    >
      <span :class="activeButtonId !== 'navGuestList' ? 'black--text': 'white--text'">{{ $t('GuestNav') }}</span>

      <svg-icon
        size="27"
        :style="activeButtonId !== 'navGuestList' ? 'color:var(--v-primary-base)': 'color:var(--v-white-base)'"
        type="mdi"
        :path="path"
      ></svg-icon>
    </v-btn>

    <v-btn
      id="navMessages"
      elevation="0"
      :class="{ 'active-nav': activeButtonId === 'navMessages', 'mx-1': !callActive }"
      class="ma-0 pa-0 rounded-lg"
      :to="{name: 'apps-user-message-template'}"
    >
      <span :class="activeButtonId !== 'navMessages' ? 'black--text': 'white--text'">{{ $t('Messages') }}</span>

      <v-icon
        :color="activeButtonId !== 'navMessages' ? 'primary' : 'white'"
        size="27"
      >
        {{ icons.mdiMessageProcessing }}
      </v-icon>
    </v-btn>
    <v-btn
      v-if="$store.state.packages.orderPackageId !== 1 && $store.state.eventData.includeCallsPreference"
      id="navCalls"
      elevation="0"
      class="ma-0 pa-0 rounded-lg"
      :class="{ 'active-nav': activeButtonId === 'navCalls', 'mx-1': !callActive }"
      :to="{name: 'apps-user-call-messages'}"
    >
      <span :class="activeButtonId !== 'navCalls' ? 'black--text': 'white--text'">
        {{ $t('Calls') }}
      </span>
      <v-icon
        :color="activeButtonId !== 'navCalls' ? 'primary' : 'white'"
        size="27"
      >
        {{ icons.mdiPhone }}
      </v-icon>
    </v-btn>
    <v-btn
      id="navTiming"
      elevation="0"
      :class="{ 'active-nav': activeButtonId === 'navTiming', 'mx-1': !callActive }"
      class="ma-0 pa-0 rounded-lg"
      :to="{name: 'apps-user-manage-timing'}"
    >
      <span :class="activeButtonId !== 'navTiming' ? 'black--text': 'white--text'">{{ $t('navTiming') }}</span>

      <v-icon
        size="27"
        :color="activeButtonId !== 'navTiming' ? 'primary' : 'white'"
      >
        {{ icons.mdiCalendarClock }}
      </v-icon>
    </v-btn>
    <v-btn
      id="navPayment"
      :class="{ 'active-nav': activeButtonId === 'navPayment', 'mx-1': !callActive }"
      elevation="0"
      class="ma-0 pa-0 rounded-lg"
      @click.stop="$router.push({name: 'pricing-page', params: { id: $store.state.eventData.id }})"
    >
      <span :class="activeButtonId !== 'navPayment' ? 'black--text': 'white--text'">{{ $store.state.eventData.progressStatus > 0 ? $t('upgrade') : $t('PaymentNav') }}</span>

      <v-icon
        :color="activeButtonId !== 'navPayment' ? 'primary' : 'white'"
        size="27"
      >
        {{ icons.mdiCreditCard }}
      </v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { useRouter } from '@/@core/utils'
import store from '@/store'
import SvgIcon from '@jamescoyle/vue-icon'

import {
  mdiAccountGroup,
  mdiCalendarClock,
  mdiCreditCard,
  mdiHeart,
  mdiHeartOutline,
  mdiMenu,
  mdiMessageProcessing,
  mdiPhone,
} from '@mdi/js'
import { computed } from '@vue/composition-api'

export default {
  components: { SvgIcon },
  setup() {
    const { route } = useRouter()
    const activeButtonId = computed(() => {
      // Find active button based on the current route
      switch (route.value.name) {
        case 'apps-user-manage-timing':
          return 'navTiming'
        case 'apps-user-list':
          return 'navGuestList'
        case 'apps-user-message-template':
          return 'navMessages'
        case 'apps-user-call-messages':
          return 'navCalls'
        case 'pricing-page':
          return 'navPayment'
        default:
          return ''
      }
    })

    const callActive = computed(() => {
      if (store.state.packages.orderPackageId !== 1) {
        return true
      }

      return false
    })

    return {
      activeButtonId,
      callActive,

      icons: {
        mdiAccountGroup,
        mdiCreditCard,
        mdiHeartOutline,
        mdiMenu,
        mdiMessageProcessing,
        mdiPhone,
        mdiCalendarClock,
      },
      path: mdiHeart,
    }
  },
}
</script>
<style scoped>
.v-item-group.v-bottom-navigation .v-btn {
  background-color: transparent;
  height: 55px !important;
}
@media (max-width: 960px) {
  .v-item-group.v-bottom-navigation .v-btn {
    min-width: 54px;
  }
}
.v-application.theme--light .v-item-group.v-bottom-navigation {
  box-shadow: 0 6px 0px -8px rgba(94, 86, 105, 0.56) !important;
}
.active-nav {
  background-color: #9155fd !important;
  color: white !important;
}
</style>
