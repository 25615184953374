import { useRouter } from '@/@core/utils'
import { reactive, watch } from '@vue/composition-api'

const popupStore = reactive({
  popups: {},
})

export function usePopupStore() {
  return popupStore
}

export function initializePopupStore() {
  const { route, router } = useRouter()

  let isUpdatingHash = false
  let isUpdatingPopups = false

  let prevPopups = { ...popupStore.popups }

  const updatePopupsFromHash = newHash => {
    if (isUpdatingHash) return
    isUpdatingPopups = true

    const hashArray = newHash ? newHash.slice(1).split('#').filter(Boolean) : []

    Object.keys(popupStore.popups).forEach(hash => {
      popupStore.popups[hash] = hashArray.includes(hash)
    })

    prevPopups = { ...popupStore.popups }

    isUpdatingPopups = false
  }

  watch(
    () => route.value.hash,
    newHash => {
      updatePopupsFromHash(newHash)
    }, { immediate: true },
  )

  watch(
    () => popupStore.popups,
    newPopups => {
      if (isUpdatingPopups) return

      isUpdatingHash = true

      const openedPopups = Object.keys(newPopups).filter(hash => newPopups[hash] && !prevPopups[hash])
      const closedPopups = Object.keys(newPopups).filter(hash => !newPopups[hash] && prevPopups[hash])
      const currentHashArray = route.value.hash.slice(1).split('#').filter(Boolean)

      if (openedPopups.length > 0) {
        openedPopups.forEach(hash => {
          if (!currentHashArray.includes(hash)) {
            currentHashArray.push(hash)
          }
        })
        const newHash = `#${currentHashArray.join('#')}`
        router.push({ hash: newHash }).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            console.error(`Error while opening popup (${newHash}): ${err}`)
          }
        })
      }

      if (closedPopups.length > 0) {
        const newHashArray = currentHashArray.filter(hash => !closedPopups.includes(hash))
        const newHash = newHashArray.length ? `#${newHashArray.join('#')}` : ''
        router.replace({ hash: newHash }).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            console.error(`Error while closing popups: ${err}`)
          }
        })
      }

      prevPopups = { ...newPopups }

      isUpdatingHash = false
    }, { deep: true },
  )
}
