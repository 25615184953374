import ImagePreloader from '../services/imagePreloader'

export default {
    install(Vue) {
        Vue.prototype.$getImage = src => {
            return ImagePreloader.getImage(src)
        }

        Vue.prototype.$preloadImage = src => {
            return ImagePreloader.preloadImage(src)
        }
    },
}