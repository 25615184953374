import { mdiAccountOutline, mdiCalendarOutline, mdiCogOutline } from '@mdi/js'

export default [
  {
    subheader: 'Settings',
  },

  {
    title: 'SettingEvent',
    icon: mdiCogOutline,
    to: 'apps-user-event-settings',
  },
  {
    title: 'MyProfile',
    icon: mdiAccountOutline,
    to: 'user-settings',
  },
  {
    title: 'MyEvents',
    icon: mdiCalendarOutline,
    to: 'manage-events',
  },
]
