export default {
  namespaced: true,
  state: {
    shallContentShowOverlay: false,
    isInWebView: false,
    platform: 'web',
  },
  getters: {
    getIsInWebView(state) {
      return state.isInWebView
    },
    getPlatform(state) {
      return state.platform
    },
  },
  mutations: {
    SET_IS_IN_WEBVIEW(state, value) {
      state.isInWebView = value
    },
    SET_PLATFORM(state, value) {
      state.platform = value
    },
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
    },
  },
  actions: {},
}
