export default {
  willHappyToHelpYou: 'سنكون سعداء لمساعدتك!',
  notFindAnswerForQa: 'لم تجد إجابة لسؤالك؟',
  sendUsEmail: 'اتصل بنا',
  stillNeedHelp: 'إذا كنت لا تزال بحاجة إلى المساعدة ، فلا تتردد في الاتصال بنا',
  questionsAndAnswers: 'أسئلة / أجوبة حول الموضوع',

  // tabs
  messages: 'رسائل',
  calls: 'محادثات',
  system: 'نظام',
  payment: 'قسط',

  // messages
  question_about_msg_flow: 'كيف تعمل عملية المراسلة؟',
  answer_for_msg_flow:
    'يرسل النظام الرسائل المحددة في "نموذج الرسالة" إلى المدعوين. سيتلقى كل مدعو الرسالة وفقًا للإعدادات التي اخترتها له (أطباق خاصة ، وسائل نقل) وسيتم عرض هذه الرسائل باللغة التي اخترتها لهذا المدعو.<br> سيتم إرسال رسالة تأكيد الوصول في اليوم الذي تشير فيه إلى أن تأكيدات الوصول ستبدأ ، وسيتم إرسال رسالة التذكير والاتجاه إلى الحدث في يوم الحدث فقط لأولئك المدعوين الذين أكدوا الوصول. سيتم إرسال خطاب الشكر في اليوم التالي للحدث وإلى الضيوف الذين أكدوا وصولهم فقط.',
  question_about_edit_msg_content: 'هل يمكن تعديل محتوى الرسائل؟',
  answer_for_edit_msg_content:
    'نعم ، يمكنك تعديل محتوى الرسالة في علامة التبويب " نموذج الرسائل" في القائمة. تحتوي كل لغة على عدد من الرسائل (رسالة تأكيد وصول ، رسالة تذكير واتجاه الحدث ورسالة شكر). يمكن تعديل كل منهم كما يحلو لك.',
  question_about_visibility_msg: 'كيف ستبدو رسالتي لضيفي؟',
  answer_for_visibility_msg:
    'الطريقة التي سيتلقى بها المدعو الرسالة ستكون وفقًا لاختيارك في الحزمة التي اشتريتها (SMS / WhatsApp) ، أسفل الرسالة التي قمت بتحريرها في علامة التبويب " نموذج الرسائل " ، سيتم إرفاق رابط فريد بـ كل مدعو مع الدعوة إلى الحدث الخاص بك.<br> يمكن رؤية عرض الأمر في علامة التبويب "الطلب".<br> تعتبر اللغة والأسئلة التي سيتم طرحها في الدعوة فريدة وفقًا لما اخترته في الإعدادات الخاصة بكل مدعو.',
  question_about_edit_msg_content_after_start_proccess: 'هل يمكن تغيير محتوى الرسالة بعد بدء العملية؟',
  answer_for_edit_edit_msg_content_after_start_proccess:
    'نعم ، يمكن تغيير محتوى الرسالة بعد بدء العملية ، ولكن إذا تم إرسال الرسالة بالفعل ، فلن يتم إرسالها مرة أخرى. سيتم تحديث الصياغة الجديدة في الرسائل التي لم يتم إرسالها بعد.',
  question_about_sending_time_msg: 'في أي أوقات يتم إرسال الرسائل؟',
  answer_for_sending_time_msg:
    'تُرسل الرسائل خلال ساعات العمل: من الأحد إلى الخميس بين الساعة 9:00 والساعة 21:00، ويوم الجمعة بين الساعة 9:00 والساعة 15:00.',

  // calls
  question_about_calls_flow: 'كيف تجري عملية المحادثة مع ضيوفنا؟',
  answer_for_calls_flow:
    'المكالمات تلقائية دون تدخل بشري ، مما يتيح لك الاتصال بمدعوين بأي لغة تختارها.<br> سيتم إجراء المكالمة إذا لم يقم الضيف بالرد على الرسائل المرسلة إليه.<br> ستتم المحادثة باللغة التي تحددها للمدعو ووفقًا للمحتوى الافتتاحي الذي قمت بتعيينه لتلك اللغة في علامة التبويب " نموذج المكالمات".',
  question_about_edit_calls_content: 'هل يمكن تعديل محتوى المكالمة الهاتفية؟',
  answer_for_edit_calls_content:
    'نعم ، باستخدام الحزمة المميزة ، يمكنك تعديل المحادثة والاستماع إليها في علامة التبويب " نموذج المكالمات " في القائمة. <br> كل لغة لها قالبها الخاص الذي يمكن تعديله حسب الرغبة. ومع ذلك ، يمكن تعديل بداية المحادثة فقط ، حيث تتضمن بقية المحادثة مطالبات بالضغط على المفاتيح وغير قابلة للتخصيص.',
  question_about_how_calls_listen: 'كيف ستبدو المكالمة عندما تتصل بضيفي؟',
  answer_for_how_calls_listen:
    'الجزء الأول من المحادثة هو الرسالة الافتتاحية التي حددتها في علامة تبويب " نموذج المكالمات "، والتي سيسمعها المدعو عند الرد على المكالمة الهاتفية.<br> في وقت لاحق ، سيتعين على المدعو الضغط على مفتاح على لوحة مفاتيح الهاتف للإشارة إلى ما إذا كان ينوي الحضور أم لا. إذا اختاروا الحضور ، سيطلب منهم النظام إدخال عدد المشاركين.<br> إذا لم يرد المدعو على المكالمة أو لم يرد عليها ، فسيستمر النظام في الاتصال بهم في الجولة التالية. <br>بعد اكتمال المحادثة ، يمكنك رؤية ردودهم في النظام. ',
  question_about_edit_calls_content_after_start_proccess: 'هل يمكن تغيير محتوى المحادثة بعد بدء العملية؟',
  answer_for_edit_edit_calls_content_after_start_proccess:
    'نعم ، يمكن تحرير محتوى المكالمة بعد بدء العملية ، ولكن إذا تم إجراء المكالمة بالفعل ، فلن يتلقى المدعو مكالمة أخرى بالمحتوى الجديد. سيتم تحديث تغيير المحتوى في الجولة التالية من المكالمات.',
  question_about_sending_time_calls: 'في أي أوقات تتم المكالمات؟',
  answer_for_sending_time_calls:
    'تتم المكالمات خلال ساعات العمل: من الأحد إلى الخميس بين الساعة 9:00 والساعة 21:00، ويوم الجمعة بين الساعة 9:00 والساعة 15:00.',

  // system
  question_about_how_add_invitees: 'كيف يمكنك إضافة مدعوين إلى قائمة المدعوين بسهولة وسرعة؟',
  answer_for_how_add_invitees:
    "يمكن إضافة المدعوين بعدة طرق:<br>الأولى يدويًا، من خلال زر 'إضافة مدعو'.<br>الطريقتان الأخريان تتمان من خلال النقر على زر 'استيراد المدعوين'.<br>الطريقة الثانية عبر استخدام حساب جوجل الخاص بك إذا كانت جهات الاتصال مخزنة فيه، انقر على 'استيراد من جوجل'، واختر جميع جهات الاتصال التي ترغب في إضافتها، وقم بتعيين الإعدادات المفضلة لديك. بعد النقر على زر 'تأكيد واستيراد'، انتهيت؛ جميع المدعوين سيظهرون في جدول.<br>لاحظ أنه عند استيراد جهات الاتصال من حساب جوجل الخاص بك، يتم تعيين عدد المدعوين لكل مدعو إلى واحد بشكل افتراضي. لذا، إذا كنت ترغب في تغيير عدد المدعوين، يجب عليك القيام بذلك يدويًا بالنقر على المدعو في جدول المدعوين.<br>الطريقة الثالثة لاستيراد المدعوين هي من إكسل. انقر على 'استيراد من إكسل'، واختر ملف المدعوين الذي تود إضافتهم منه. يجب أن يشتمل الملف على عمود لاسم المدعو، رقم الهاتف، وعدد المدعوين. لكل حقل مثل 'الاسم الكامل'، 'رقم الهاتف'، و'عدد المدعوين'، يجب عليك اختيار العمود المناسب (كما قمت بتسميته في ملفك).",
  question_about_how_customize_invitees_settings: 'كيف يمكن تحديد الإعدادات الشخصية لكل ضيف؟',
  answer_for_how_customize_invitees_settings:
    'في إعدادات الحدث ، يمكنك اختيار اللغات ذات الصلة بالحدث والأسئلة المحددة التي تريد طرحها على المدعوين.<br> ثم في علامة التبويب "قائمة الضيوف" يمكنك اختيار اللغة التي ترغب في الاتصال بها لكل ضيف وما إذا كنت ستسأل عن الأطباق الخاصة و / أو وسائل النقل بشكل فردي.',
  question_about_export_to_excel: 'هل يمكن تصدير جدول الضيف إلى Excel؟',
  answer_for_export_to_excel: 'قطعاً. بسهولة وبسرعة عن طريق النقر فوق الزر "تصدير" في علامة التبويب " قائمة الضيوف ".',
  question_about_bulk_edit_invitees: 'هل من الممكن تعديل عدة ضيوف في وقت واحد؟',
  answer_for_bulk_edit_invitees:
    'نعم. يمكنك تحرير العديد من المدعوين معًا عن طريق تحديدهم في جدول المدعوين والنقر فوق الزر "تحرير".',
  question_about_edit_invitee_status_manually:
    'أبلغني المدعو بحالة وصوله بشكل خاص ، كيف يمكنني تحديث ذلك في جدول المدعوين؟',
  answer_for_edit_invitee_status_manually:
    'يمكنك التحديث يدويًا بالنقر فوق المدعو في علامة التبويب "قائمة المدعوين". بعد ذلك ، يجب تحديث إجابة المدعو في علامة التبويب "إجابات الضيف". هناك خيار آخر وهو تحديد المدعو في علامة التبويب "قائمة الدعوة" والنقر فوق الزر "تحرير".',
  question_about_rsvp_status: 'هل من الممكن معرفة المرحلة التي وصلت إليها عملية الموافقة على الوصول؟',
  answer_for_rsvp_status:
    'قطعاً. يمكن رؤية ذلك في علامة التبويب " قائمة الضيوف " في عمود "التقدم" في الجدول. حيث يرمز الرمز إلى نوع الإجراء ، ويرمز الرقم الموجود أعلى الرمز إلى مقدار الرسائل / المكالمات المرسلة إلى المدعو.',
  question_about_edit_start_rsvp: 'هل يمكن تحديد تاريخ بدء تأكيد وصول الحدث؟',
  answer_for_edit_start_rsvp:
    'نعم، يمكنك اختيار تاريخ بدء تأكيد الحضور (RSVP) في صفحة الدفع.<br> ومع ذلك، يرجى العلم بأن عملية تأكيد الحضور عادةً ما تستغرق عدة أيام، لذا نوصي ببدئها قبل الحدث بأسبوعين على الأقل.',

  // payment
  question_about_update_package: 'هل من الممكن ترقية حزمة موجودة؟',
  answer_for_update_package:
    'نعم. نحن نسمح بترقية حزمة موجودة في علامة التبويب "ملفي الشخصي" ، طالما أن العملية لا تنتهي.<br> يمكنك ترقية حزمة موجودة وزيادة عدد الضيوف حسب الحاجة.',
  question_about_coupon_use: 'لدي كود قسيمة ، كيف يمكنني استردادها؟',
  answer_for_coupon_use:
    'يمكن استرداد القسيمة في وقت الدفع ، يجب إدخال رمز القسيمة في المنطقة المخصصة والضغط على مفتاح "تطبيق" ، وبعد ذلك سيتم تحديث السعر.',
}
