import Vue from 'vue'
import VueI18n from 'vue-i18n'
import arFAQ from './faqTranslate/arFAQ'
import enFAQ from './faqTranslate/enFAQ'
import heFAQ from './faqTranslate/heFAQ'
import ruFAQ from './faqTranslate/ruFAQ'
import ar from './locales/ar'
import en from './locales/en'
import he from './locales/he'
import ru from './locales/ru'
import privacyAr from './termsTranslation/privacyAr'
import privacyEn from './termsTranslation/privacyEn'
import privacyHe from './termsTranslation/privacyHe'
import privacyRu from './termsTranslation/privacyRu'
import termsAr from './termsTranslation/termsAr'
import termsEn from './termsTranslation/termsEn'
import termsHe from './termsTranslation/termsHe'
import termsRu from './termsTranslation/termsRu'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'he', // set locale
  fallbackLocale: 'he',
  messages: {
    he: Object.assign(he, {
      faq: heFAQ,
      terms: termsHe,
      privacy: privacyHe,
    }),
    ru: Object.assign(ru, {
      faq: ruFAQ,
      terms: termsRu,
      privacy: privacyRu,
    }),
    en: Object.assign(en, {
      faq: enFAQ,
      terms: termsEn,
      privacy: privacyEn,
    }),
    ar: Object.assign(ar, {
      faq: arFAQ,
      terms: termsAr,
      privacy: privacyAr,
    }),
  }, // set locale messages
})

const loadedLanguages = ['he'] // our default language that is preloaded

function setI18nLanguage(lang) {
  i18n.locale = lang
  localStorage.setItem('lang', i18n.locale)

  return lang
}

export function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  /* eslint-disable prefer-template */
  return import(/* webpackChunkName: "lang-[request]" */ '@/plugins/i18n/locales/' + lang + '.js').then(msgs => {
    i18n.setLocaleMessage(lang, msgs.default)
    loadedLanguages.push(lang)

    return setI18nLanguage(lang)
  })

  /* eslint-enable */
}
