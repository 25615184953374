<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <!-- Avatar in the app bar title -->
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-2"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          color="primary"
          class="v-avatar-light-bg primary--text"
          v-on="on"
        >
          <!-- The first char of the email address -->
          <span
            class="font-weight-medium text-h5"
          >{{ avatarText(userData.email) }}</span>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <!-- list title: user name, email and email name avatar -->
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <span
              class="font-weight-medium text-h5"
            >{{ avatarText(userData.email) }}</span>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ userData.fullName }}
          </span>
          <small class="text--disabled">{{ userData.email }}</small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- order history -->
      <v-list-item :to="{ name: 'user-settings' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> {{ $t('MyProfile') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Manage events -->
      <v-list-item :to="{ name: 'manage-events' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCalendarOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('MyEvents') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>

      <!-- contact-us -->
      <v-list-item :to="{ name: 'faq-and-contact-us' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiEmailOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> {{ $t('ContactUS') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item @click="logoutUser">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('Logout') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import useLogoutUser from '@/composables/useLogoutUser'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiCalendarOutline, mdiEmailOutline, mdiHelpCircleOutline, mdiLogoutVariant } from '@mdi/js'

export default {
  setup() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const eventImage = store.state.eventData.image
      ? `${process.env.VUE_APP_LOCAL_HOST}event/images/${store.state.eventData.image}`
      : require('@/defaultImage/image.jpg')

    const { logoutUser } = useLogoutUser()

    return {
      logoutUser,
      userData,
      eventImage,
      avatarText,

      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiCalendarOutline,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
