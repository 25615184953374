import { mdiHomeOutline } from '@mdi/js'

export default [
  {
    title: 'Dashboard',
    name: 'Dashboard',
    icon: mdiHomeOutline,
    badgeColor: 'warning',
    to: 'home-page',
  },
]
