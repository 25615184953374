export default {
  terms_title: 'Политика конфиденциальности',
  introduction: [
    {
      content: `
              <p>
              Добро пожаловать на веб-сайт Anita-Lee (далее <strong>"Сайт"</strong>), который предлагает услуги (далее <strong>"Услуги"</strong>) для проведения мероприятия. Включает систему управления гостями, подтверждение прибытия и управление затратами на мероприятие. Любое использование веб-сайта означает согласие с политикой и правилами конфиденциальности, включая положения и условия, подробно изложенные в них. 
              </p>
            `,
    },
    {
      content: `
            <p>
            Терминология в этой Политике конфиденциальности написана в мужском роде только для удобства и предназначена для обращения ко всем гендерам одинаково.
            </p>`,
    },
  ],
  sections: [
    {
      title: `
            <p>
                <strong>
                    1.<u> Общее</u>
                </strong>
            </p>`,

      content: `
            <p class="content-item">
                <span class="number">
                    1.1. 
                </span>
                <span>
                    Администрация Веб-сайта уважает конфиденциальность всех пользователей.
                </span>
            </p>
            <p class="content-item">
                <span class="number">
                      1.2. 
                </span>
                <span>
                    Цель этой Политики конфиденциальности - предоставить подробную информацию о сборе информации о пользователях в результате использования Услуг, а также обозначить, как администрация Веб-сайта использует предоставленную и собранную информацию в результате использования ее Услуг.
                </span>
            </p>
            <p class="content-item">
                <span class="number">
                      1.3. 
                </span>
                <span>
                    Условия этой Политики конфиденциальности, как подробно описано здесь, являются неотъемлемой частью Условий Веб-сайта. Администрация Веб-сайта имеет право в любое время изменять эту Политику конфиденциальности и Условия, вместе или по отдельности. Поэтому пользователи должны следить за этим документом в его последней версии на Веб-сайте.
                </span>
            </p>
            <p class="content-item">
                <span class="number">
                      1.4. 
                </span>
                <span>
                    Услуги Веб-сайта предназначены для пользователей в возрасте 18 лет и старше.
                </span>
            `,
    },
    {
      title: `
            <p>
                <strong>
                    2.<u>Сбор информации</u>
                </strong>
            </p>`,
      content: `
              <p class="content-item">
                  <span class="number">
                          2.1. 
                  </span>
                  <span>
                    Услуги, предлагаемые Веб-сайтом, требуют регистрации, включающей предоставление личной информации, такой как полное имя и адрес электронной почты. Предоставление информации во время регистрации также может быть завершено через третьи стороны, например, через вход в Google.
                  </span>
              </p>
              <p class="content-item">
                  <span class="number">
                          2.2. 
                  </span>
                  <span>
                    Дополнительная информация о пользователях может быть собрана и сохранена во время использования Услуг Веб-сайта.
                  </span>
              </p>
              <p class="content-item">
                  <span class="number">
                      2.2.1
                  </span>
                  <span>
                    Телефонные номера ваших приглашенных.
                  </span>
              </p>
              <p class="content-item">
                  <span class="number">
                      2.2.2
                  </span>
                  <span>
                    Детали вашего мероприятия, такие как дата, время, место и т.д.
                  </span>
              </p>
              <p class="content-item">
                  <span class="number">
                      2.2.3
                  </span>
                  <span>
                    Запросы к администрацией Веб-сайта.
                  </span>
              </p>
          <p class="content-item">
              <span class="number">
                  2.2.4
              </span>
              <span>
                Вся информация, связанная с использованием Услуг Веб-сайта, требуется для их работы и по вашему запросу, включая использование внешних услуг.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  2.2.5
              </span>
              <span>
                Информация о действиях, которые не были завершены в рамках Услуги (например, нажатие на кнопку создания мероприятия или прерывание процесса завершения до его окончания, нажатие на различные кнопки и т.д.).
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  2.2.6
              </span>
              <span>
                Информация о вашем конечном устройстве, такая как IP-адрес, тип операционной системы, часовой пояс и местоположение устройства.
              </span>
          </p>
  
          <p class="content-item">
              <span class="number">
                  2.3. 
              </span>
              <span>
                Уточняется, что вы не обязаны законом предоставлять все указанные выше детали информации, и их предоставление зависит только от вашей воли и свободного согласия. Без предоставления определенных деталей (например, без ввода идентификационных данных для регистрации и т.д.) мы не сможем работать и предоставлять некоторые или все предлагаемые Услуги.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  2.4. 
              </span>
              <span>
                Вся предоставленная или собранная о вас информация для предоставления Услуг, полностью или частично, может быть сохранена в зарегистрированных базах данных администрации Веб-сайта и будет поддерживаться ею или от ее имени. Использование информации будет осуществляться в соответствии с этой Политикой.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  2.5. 
              </span>
              <span>
                Следует отметить, что администрация Веб-сайта не распространяет эту информацию третьим сторонам, но использует ее для предоставления услуг, включая через поставщиков третьих сторон.
              </span>
          </p>
          <p class="content-item">
              <span class="number">
                  2.6. 
              </span>
              <span>
                Должна предоставляться точная, достоверная и точная информация.
              </span>
          </p>
         `,
    },
    {
      title: `
                <p>
                <strong>
                    "Cookies"
                </strong>
            </p>`,

      content: `
          <p class="content-item">
              <span class="number">
                      2.7. 
              </span>
              <span>
              "Cookies" - это цифровые инструменты, позволяющие собирать и анализировать информацию при использовании браузера. Администрация веб-сайта или третьи стороны, работающие с cookies, могут периодически получать доступ к накопленной информации в cookies, и она будет передана на их серверы. Удаление cookies из браузера не удалит информацию, которая уже была передана на веб-сайт или третьим сторонам через cookies.
              </span>
          </p>
              <p class="content-item">
              <span class="number">
                      2.8. 
              </span>
              <span>
                Cookies содержат и собирают различную информацию, такую как продолжительность вашего пребывания на сайте, откуда вы пришли на сайт или на страницу, ваш IP-адрес, характеристики вашего устройства, информацию, которую вы запрашиваете при входе на сайт, и т.д.
              </span>
          </p>
          <p class="content-item">
          <span class="number">
                  2.9. 
          </span>
          <span>
            Мы используем cookies для бесперебойной и правильной работы услуг, для улучшения опыта просмотра путем адаптации услуг к вашим личным предпочтениям, а также для статистических и исследовательских целей.
          </span>
          </p>
          <p class="content-item">
          <span class="number">
              2.10. 
          </span>
          <span>
            Администрация сайта может также использовать сторонние cookies, такие как cookies Google при использовании Google Analytics, и cookies Microsoft при использовании Clarity, которые помогают нам настроить ваш опыт просмотра и/или правильное использование сайта, а также извлекают статистические данные об общем использовании услуг. Кроме того, мы используем различные инструменты аналитики Facebook в качестве инструмента измерения характеристик использования сайта, инструмента измерения эффективности рекламных кампаний.
          </span>
          </p>
            `,
    },
    {
      title: `
              <p>
                  <strong>
                      3.<u> Использование Google APIs </u> 
                  </strong>
              </p>`,
      content: `
              <p class="content-item">
              <span class="number">
              3.1. 
          </span>
                  <span>  
                  Администрация сайта использует и передает поставщикам и/или третьим лицам информацию, полученную от API Googles, в соответствии с ограниченными условиями использования, изложенными в 
                  <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"> Google API Services User Data Policy.</a>             
                  </span>
              </p>
                    `,
    },
    {
      title: `
            <p>
                <strong>
                    4.<u>Доступ к информации</u>
                </strong>
            </p>`,
      content: `
            <p class="content-item">
                <span class="number">
                    4.1. 
                </span>
                <span>
                    Некоторые из услуг, которые мы предоставляем, включают доступ к определенным данным с целью выполнения определенных действий в рамках услуг. Эти разрешения включают, но не ограничиваются:
                </span>
            </p>
            <p class="content-item">
            <span class="number">
                4.1.1. 
            </span>
            <span>
                Доступ к списку контактов Google - для импорта приглашенных в систему. Сохраняемая для этой цели информация - это имя контакта и номер телефона.
            </span>
            </p>
            <p class="content-item">
            <span class="number">
                4.1.2. 
            </span>
            <span>
                Регистрация и/или вход через аккаунт Google - для быстрой регистрации и/или входа в систему.
            </span>
            </p>
            <p class="content-item">
            <span class="number">
                4.1.3. 
            </span>
            <span>
                Доступ к фотогалерее - для загрузки фотографии приглашения на мероприятие по вашему запросу.
            </span>
            </p>
            <p class="content-item">
                <span class="number">
                    4.2. 
                </span>
                <span>
                    Следует отметить, что администрация сайта не распространяет эту информацию третьим сторонам, но использует ее для предоставления услуг.
                </span>
            </p>
            
            `,
    },
    {
      title: `
            <p>
                <strong>
                    5.<u>Использование полученной или собранной от пользователей информации</u>
                </strong>
            </p>`,
      content: `
            <p class="content-item">
                <span class="number">
                    5.1. 
                </span>
                <span>
                    Мы используем полученную от вас информацию для предоставления вам услуг, включая RSVPs, отображение информации о ваших приглашенных, отображение информации о расходах на ваше мероприятие, отображение статистических данных о вашем мероприятии и т.д.
                </span>
            </p>
            <p class="content-item">
                <span class="number">
                    5.2. 
                </span>
                <span>
                    Кроме того, информация будет использоваться для улучшения, исправления или изменения услуг и контента, предлагаемых в рамках наших услуг, для оптимизации услуг, предлагаемых администрацией сайта.
                </span>
            </p>
            <p class="content-item">
            <span class="number">
                5.3. 
            </span>
            <span>
                Администрация сайта может использовать, обрабатывать, маркировать и обогащать указанную информацию, в том числе через внешние сервисы и информационных провайдеров, в целях управления, улучшения, адаптации рекламного и маркетингового контента услуг сайта
            </span>
            </p>
            <p class="content-item">
              <span class="number">
                  5.4. 
              </span>
              <span>
                В рамках услуг на сайте могут появляться различные ссылки на внешние веб-сайты и/или приложения. Нажатие на ссылку будет на ваше усмотрение и под вашу единственную ответственность, поскольку вы будете подчиняться его условиям, включая политику конфиденциальности. С этого момента администрация сайта не будет контролировать, что делается с вашей информацией, и поэтому не будет нести за это ответственность.
              </span>
            </p>
            <p class="content-item">
              <span class="number">
                  5.5. 
              </span>
              <span>
                Администрация сайта будет по возможности избегать раскрытия личных данных пользователя третьим сторонам, но пользователь настоящим соглашается с тем, что администрация сайта может раскрыть его данные и любую другую информацию, если это будет требоваться по любому закону.
              </span>
            </p>
            <p class="content-item">
              <span class="number">
                  5.6. 
              </span>
              <span>
                Учетная запись пользователя и ее данные, включая сведения о событиях, связанных с учетной записью, будут храниться в базе данных в течение шести месяцев с даты события, если учетная запись не используется, руководство сайта оставляет за собой право сбросить учетную запись и детали событий, которые были в ней, и они будут немедленно удалены.
              </span>
            </p>
              `,
    },
    {
      title: `
            <p>
                <strong>
                    6.<u>Обмен информацией с третьими сторонами</u>
                </strong>
            </p>`,
      content: `
            <p class="content-item">
                <span class="number">
                    6.1. 
                </span>
                <span>
                    При совершении определенных операций в рамках сервисов может потребоваться передача информации третьим лицам, осуществляющим выполнение операции, например расчет кредитных карт и платежных сервисов. Несмотря на то, что руководство веб-сайта не хранит детали платежа, оно может предоставить такую информацию, как сумма платежа за пакет, выбранный пользователем, с целью выполнения платежной операции, создания счета-фактуры или с целью соблюдая положения закона.
                </span>
            </p>
            <p class="content-item">
                <span class="number">
                    6.2. 
                </span>
                <span>
                    Провайдерам связи с целью предоставления услуг, таких как отправка SMS/WhatsApp и автоматических голосовых звонков вашим приглашенным на мероприятие.
                </span>
            </p>
            <p class="content-item">
                <span class="number">
                    6.3. 
                </span>
                <span>
                    Чтобы устранить любые сомнения, сайт не продает и не передает данные пользователя внешним организациям. Однако сайт использует информацию, которую пользователь вводит в систему, и передает ее компаниям третьих сторон с целью предоставления пользователю услуг.
                </span>
            </p>
                `,
    },

    {
      title: `
            <p>
                <strong>
                    7.<u>Безопасность информации</u> 
                </strong>
            </p>`,
      content: `
            <p class="content-item">
                <span class="number">
                    7.1. 
                </span>
                <span>
                    Администрация сайта прилагает заметные усилия для защиты конфиденциальности данных, предоставленных пользователями сайта, принимая стандартные меры предосторожности. Однако администрация сайта не может гарантировать, что не будет неправомерного использования или нарушения предоставляемых услуг.
                </span>
            </p>
            <p class="content-item">
                <span class="number">
                    7.2. 
                </span>
                <span>
                    Администрация сайта не будет нести ответственности за потерю информации на сайте в результате форс-мажора, сбоя национальной или глобальной интернет-сети и/или неожиданного сбоя на серверах компании.
                </span>
            </p>
          
                `,
    },
    {
      title: `
            <p>
                <strong>
                    8.<u>Обращение по вопросам конфиденциальности</u> 
                </strong>
            </p>`,
      content: `
            <p class="content-item">
                <span>
                    Если у вас есть вопросы и/или требуются разъяснения относительно политики конфиденциальности, вы можете связаться с администрацией сайта по адресу электронной почты: support@anita-lee.com
                </span>
            </p>
                  `,
    },
  ],
}
