import { mdiEmailOutline } from '@mdi/js'

export default [
  {
    subheader: 'Support',
  },

  {
    title: 'ContactUS',
    icon: mdiEmailOutline,
    to: 'faq-and-contact-us',
  },
]
