<template>
  <v-img v-if="resolvedSrc" v-bind="attrs" v-on="listeners" :src="resolvedSrc" @load="onImageLoad" eager>
    <slot />
  </v-img>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    src: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      resolvedSrc: null,
    }
  },

  computed: {
    attrs() {
      return {
        ...this.$attrs,
        src: this.resolvedSrc,
      }
    },
    listeners() {
      return this.$listeners
    },
  },

  created() {
    this.$getImage(this.src)
      .then(src => {
        this.resolvedSrc = src
      })
      .catch(error => {
        console.error('Failed to preload image:', error)
      })
  },

  methods: {
    onImageLoad() {
      this.$emit('imageLoaded', this.src)
    },
  },
}
</script>
